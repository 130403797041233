<template lang="pug">
  v-app
    v-app-bar(app dark dense flat clipped-left :color="appBarColor")

      v-app-bar-nav-icon(:class="{'blinker' : cashRegister.status === 'TRIAL' && cashRegisterSwitchDialog === false && !drawer }" :ripple="false" @click.stop="setDrawer(!drawer)")
      v-badge(v-if="!drawer && (featureNewPaymentsSeen || featureMoyuchetSeen || featurePositionsSeen)" color="red" overlap offset-x="25" offset-y="1" icon="mdi-hexagram")
      v-badge(color="primary" overlap offset-x="25" offset-y="1" :content="unreadCount" :value="!drawer && unreadCount > 0")

      v-spacer

      v-toolbar-title.cash-register-name.mx-2.align-center(v-if="!$vuetify.breakpoint.lgAndUp")
        span {{ cashRegister.name ? cashRegister.name : organization.businessName }}
      v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)

      v-spacer

      v-btn(id="account_button" icon)
        v-icon mdi-account
        v-menu(bottom left activator="#account_button")
          v-list
            v-list-item-group
              v-list-item(to="/profile" :disabled="!user._links.createCashRegister")
                v-list-item-content
                  v-list-item-title {{ user.phone | phone }}
                  v-list-item-subtitle(v-if="user.name") {{ user.name }}
                v-list-item-icon
                  v-icon mdi-account-box-outline
              v-list-item(@click="signOutConfirm()")
                v-list-item-content
                  v-list-item-title {{ $t("выход") }}
                v-list-item-icon
                  v-icon mdi-exit-to-app

    v-navigation-drawer(app clipped fixed  align-start :value="drawer" @input="setDrawer")

      .fill-height.d-flex.flex-column

        .Zrow

          v-list-item
            v-list-item-content.pb-0
              v-list-item-title.title {{ cashRegister.name }}
              v-list-item-subtitle.text--primary {{ organization.businessName }}
              v-list-item-subtitle {{ organization.businessId }}
              v-row
                v-col
                  v-btn(v-if="user._links.createCashRegister" :class="{'blinker' : cashRegister.status === 'TRIAL' && cashRegisterSwitchDialog === false }" rounded block outlined x-small @click="cashRegisterSwitchDialog = true") {{ $t("кассы") }}
                v-col
                  v-btn.d-none(rounded block outlined x-small disabled) {{ $t("пользователи") }}

          v-list(nav dense)

            v-list-item-group

              v-list-item(to="/" :ripple="false")
                v-list-item-icon
                  v-icon mdi-calculator-variant
                v-list-item-content
                  v-list-item-title {{ $t("касса") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'NEW'") {{ $t("без_активации") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'TRIAL'") {{ $t("демо_режим") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'DEREGISTERED'") {{ $t("архивная") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'OFFLINE' && hoursLeftTillBlocked !== null && hoursLeftTillBlocked >= 0") {{ $t('осталось_ч', { hours: hoursLeftTillBlocked }) }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'BLOCKED'") {{ $t("заблокирована") }}
                v-list-item-action(v-if="cashRegister.status === 'REGISTERED'")
                  v-chip(x-small :color="cashRegisterMode === 'ONLINE' ? 'success' : (cashRegisterMode === 'OFFLINE' ? 'warning' : (cashRegisterMode === 'BLOCKED' ? 'error' : null))") {{ cashRegisterMode }}

              v-list-item(to="/kassa" :ripple="false")
                v-list-item-icon
                  v-icon mdi-tag-text-outline
                  v-badge(v-if="featurePositionsSeen" bordered color="red" icon="mdi-hexagram" overlap)
                v-list-item-content
                  v-list-item-title {{ $t("Касса") }}

              v-list-item(to="/kkm/positions" :ripple="false")
                v-list-item-icon
                  v-icon mdi-tag-text-outline
                  v-badge(v-if="featurePositionsSeen" bordered color="red" icon="mdi-hexagram" overlap)
                v-list-item-content
                  v-list-item-title {{ $t("прайс_лист") }}

              v-list-item(to="/kkm/payments" :ripple="false")
                v-list-item-icon
                  v-icon mdi-credit-card-outline
                  v-badge(v-if="featureNewPaymentsSeen" bordered color="red" icon="mdi-hexagram" overlap)
                v-list-item-content
                  v-list-item-title {{ $t("платежи") }}

              v-list-item(to="/kkm/moyuchet" :ripple="false")
                v-list-item-icon
                  v-icon mdi-brightness-percent
                  v-badge(v-if="featureMoyuchetSeen" bordered color="red" icon="mdi-hexagram" overlap)
                v-list-item-content
                  v-list-item-title {{ $t("налоги") }}

              div.my-1

              v-subheader {{ $t("отчёты") }}

              v-list-item(@click="toJournal()" :inactive="this.shiftNumber === null" :ripple="false" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'TRIAL'")
                v-list-item-icon
                  v-icon mdi-book-open-page-variant
                v-list-item-content
                  v-list-item-title {{ $t("журнал_чеков") }}
                v-list-item-action(v-if="shiftNumber")
                  v-list-item-action-text {{ shiftTicketNumber ? shiftTicketNumber : '0' }} шт.

              v-list-item(:to="`/shifts/${cashRegister.id}`" :ripple="false")
                v-list-item-icon
                  v-icon mdi-finance
                v-list-item-content
                  v-list-item-title {{ $t("итоги_продаж") }}
                  v-list-item-subtitle(v-if="shiftNumber") {{ $t("смена") }} №{{ shiftNumber }}
                v-list-item-action(v-if="revenue")
                  v-list-item-action-text {{ revenue | numeral('0,0.[00]') }} ₸

              v-list-item(to="/reports" :ripple="false")
                v-list-item-icon
                  v-icon mdi-file-chart
                v-list-item-content
                  v-list-item-title {{ $t("отчёты") }}

              div.my-1

              v-subheader ККМ {{ cordovaBuildVersion ? `(${cordovaBuildVersion})` : '' }}

              v-list-item(to="/passport" :ripple="false" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
                v-list-item-icon
                  v-icon mdi-passport
                v-list-item-content
                  v-list-item-title {{ $t("паспорт") }}
                v-list-item-action(v-if="cashRegister")
                  v-list-item-action-text {{ cashRegister.serialNumber }}

              v-list-item(to="/kkm" :ripple="false" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
                v-list-item-icon
                  v-icon mdi-cog-outline
                v-list-item-content
                  v-list-item-title {{ $t("настройки") }}
                  v-list-item-subtitle

              v-list-item(to="/ui" :ripple="false")
                v-list-item-icon
                  v-icon mdi-television-guide
                v-list-item-content
                  v-list-item-title {{ $t("интерфейс") }}

              v-list-item(to="/printer" :ripple="false")
                v-list-item-icon
                  v-icon mdi-printer
                v-list-item-content
                  v-list-item-title {{ $t("принтер") }}
                  v-list-item-subtitle(v-if="printer.connectionType === 'bluetooth' && printer.bluetooth.name") {{ printer.bluetooth.name }}
                  v-list-item-subtitle(v-if="printer.connectionType === 'wifi' && printer.wifi.name") {{ printer.wifi.name }}

              div.my-1

              v-subheader {{ $t("помощь") }}

              v-list-item.disable-active-highlight(:ripple="false" @click="showManual()")
                v-list-item-icon
                  v-icon mdi-book-information-variant
                v-list-item-content
                  v-list-item-title {{ $t("инструкция") }}

              v-list-item(:ripple="false" @click="showChat()" active-class="disable-active-class")
                v-list-item-icon
                  v-icon mdi-chat
                v-list-item-content
                  v-list-item-title {{ $t("поддержка") }}
                v-list-item-action(v-if="unreadCount > 0")
                  v-chip(color="primary" x-small :class="{ 'shake' : drawer }") {{ unreadCount }}

          div.my-1

        v-spacer

        .align-end

          div.my-4(align="center")
            v-btn(text icon @click="$openLink('https://facebook.com/rekassa.kz', '_system')")
              v-icon mdi-facebook
            v-btn(text icon @click="$openLink('https://t.me/reKassakz', '_system')")
              v-icon mdi-telegram
            v-btn(text icon @click="$openLink('https://www.instagram.com/rekassa/', '_system')")
              v-icon mdi-instagram
            v-btn(text icon @click="$openLink('https://www.youtube.com/channel/UCTQAHqUp-LYfofTExQEdDXQ', '_system')")
              v-icon mdi-youtube

          div.my-4(align="center")
            span.caption &copy; COMRUN, LLP
            span.mx-2 &bull;&nbsp;
            a.caption(@click="$openLink('https://rekassa.kz', '_system')") rekassa.kz

    v-main
      v-container.pa-0.my-0.mx-auto(fill-height style="align-items: start")
        router-view

    re-cash-registers(v-model="cashRegisterSwitchDialog" @cashRegisterLoading="cashRegisterLoading")
</template>
<script>
import passwordGenerator from 'generate-password'
import { mapActions, mapState } from 'vuex'
import 'firebase/analytics'
import CashRegisters from './settings/CashRegisters.vue'
import billsAndCoinsMixin from '../mixins/billsAndCoinsMixin'

export default {
  components: {
    're-cash-registers': CashRegisters,
  },

  mixins: [billsAndCoinsMixin],

  data: () => ({
    appBarColor: null,
    loading: false,
    cashRegisterSwitchDialog: false,
    unreadCount: 0,
    faceTouchIdType: null,
    featureNewPaymentsSeen: (localStorage.getItem('rekassa.kz-menu-feature-new-payments-seen') || 'false') === 'false',
    featureMoyuchetSeen: (localStorage.getItem('rekassa.kz-menu-feature-moyuchet-seen') || 'false') === 'false',
    featurePositionsSeen: (localStorage.getItem('rekassa.kz-menu-feature-positions-seen') || 'false') === 'false',
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      printer: state => state.printer.printer,
      drawer: state => state.tools.drawer,
      appInitialized: state => state.tools.appInitialized,
      cordovaBuildVersion: state => state.tools.cordovaBuildVersion,
    }),

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    revenue() {
      if (this.shift && this.shift.open && this.shift.data.revenue) {
        return this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) * (this.shift.data.revenue.isNegative ? -1 : 1)
      }
      return null
    },

    cashRegisterMode() {
      return this.cashRegister ? this.cashRegister.fdoMode : '------'
    },

    hoursLeftTillBlocked() {
      return this.cashRegister && this.cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(this.cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },
  },

  watch: {
    drawer() {
      if (this.$isCordova()) {
        window.cordova.plugins.HelpCrunchPlugin.getUnreadCount((count) => {
          this.unreadCount = count
        }, () => {
        // console.error(error)
        })
      }
    },
  },

  created() {
    this.loading = true
    this.selectCashRegister(this.cashRegister.id).then(() => {
      this.loading = false
      if (this.cashRegister.fdoMode === 'OFFLINE' && this.hoursLeftTillBlocked(this.cashRegister) < 0) {
        this.cashRegister.fdoMode = 'BLOCKED'
      }
    }).catch(() => {
      this.loading = false
    })

    // Last phone number part hint
    try {
      localStorage.setItem('rekassa.kz-auth-last-phone-number-part', this.user.phone.slice(this.user.phone.length - 4))
    } catch (error) {
      // console.error(error)
    }

    // Firebase Analytics setUserId
    this.firebaseAnalyticsSetUserId(this.user.phone)

    // HelpCrunch initialization
    if (!this.appInitialized) {
      if (!this.$isCordova()) {
        window.HelpCrunch('onNewUnreadChat', (data) => {
          this.unreadCount = data.unreadChats
        })

        window.HelpCrunch('onChatClose', () => {
          window.HelpCrunch('hideChatWidget')
        })

        window.HelpCrunch('userAuth', { email: this.user.phone, user_id: this.user.phone })
      } else {
        window.cordova.plugins.HelpCrunchPlugin.initialize({
          organization: process.env.VUE_APP_HELPCRUNCH_ORGANIZATION,
          applicationId: process.env.VUE_APP_HELPCRUNCH_APP_ID,
          applicationSecret: process.env.VUE_APP_HELPCRUNCH_APP_SECRET,
        })

        window.cordova.plugins.HelpCrunchPlugin.updateUser(() => {}, () => {
          // console.error(error)
        }, {
          userId: this.user.phone,
        })

        window.addEventListener('onNewUnreadChat', (event) => {
          this.unreadCount = event.count
        }, false)
      }

      this.setAppInitialized(true)
    } else if (this.$isCordova()) {
      window.cordova.plugins.HelpCrunchPlugin.getUnreadCount((count) => {
        this.unreadCount = count
      }, () => {
        // console.error(error)
      })
    }

    // Cashregister statusbar
    if (!this.$vuetify.breakpoint.smAndUp && this.cashRegister.status === 'REGISTERED') {
      if (this.cashRegisterMode === 'OFFLINE') this.appBarColor = 'warning'
      else if (this.cashRegisterMode === 'BLOCKED') this.appBarColor = 'error'
      else this.appBarColor = null
    }

    // App rate
    if (this.$isCordova()) {
      const appLaunchCount = parseInt(localStorage.getItem('rekassa.kz-tools-appLaunchCount') || 0, 10)
      const appRated = (localStorage.getItem('rekassa.kz-tools-appRated') || 'false') === 'true'
      if (!appRated && appLaunchCount > 30) {
        setTimeout(() => {
          localStorage.setItem('rekassa.kz-tools-appRated', 'true')
          window.cordova.plugins.AppReview.requestReview()
        }, 2000)
      }
    }

    // Check if Google or Face/Touch ID avaliable
    if (this.$isCordova()) {
      if (this.$isAndroid()) {
        setTimeout(() => this.isGoogleAccountLinkAvailable(), 2000)
      } else {
        setTimeout(() => this.isFaceTouchIdAvailable(), 2000)
      }
    }
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchUserCashRegisterList: 'cashRegisters/fetchUserCashRegisterList',
      fetchUserCashRegisterDeregisteredList: 'cashRegisters/fetchUserCashRegisterDeregisteredList',
      createNewCashRegister: 'cashRegisters/createNewCashRegister',
      deleteNewCashRegister: 'cashRegisters/deleteNewCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setDrawer: 'tools/setDrawer',
      setAppInitialized: 'tools/setAppInitialized',
      firebaseAnalyticsSetUserId: 'tools/firebaseAnalyticsSetUserId',
    }),

    cashRegisterLoading(status) {
      this.loading = status
    },

    toJournal() {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}`)
      } else {
        this.showSnackbar({ message: this.$t('смена_не_открыта'), urlCode: 'CASH_REGISTER_SHOULD_HAVE_SHIFT_OPEN' })
      }
    },

    signOutConfirm() {
      if (!this.$isCordova()) {
        window.HelpCrunch('hideChatWidget')
      }

      this.showConfirm({
        title: `${this.$t('вы_уверены_что_хотите_выйти_из_приложения')}?`,
        resolveText: this.$t('выйти'),
        rejectText: this.$t('отменить'),
      }).then(() => {
        if (!this.$isCordova()) {
          window.HelpCrunch('logout', () => {})
        }
        this.signOut()
      }).catch(() => {})
    },

    showManual() {
      this.$openLink('https://help.rekassa.kz/re-kassa-3-0', '_system')
      if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {
          // console.error(error)
        })
      }
      if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    },

    // Google
    isGoogleAccountLinkAvailable() {
      const freshSignin = (localStorage.getItem('rekassa.kz-auth-fresh-signin') || 'false') === 'true'
      localStorage.setItem('rekassa.kz-auth-fresh-signin', 'false')
      const googleEnabled = (localStorage.getItem('rekassa.kz-auth-google-enabled') || 'false') === 'true'

      if (freshSignin && !googleEnabled) {
        this.showConfirm({
          title: 'Google',
          icon: 'mdi-google',
          text: this.$t('использовать_type_для_входа_в_приложение', { type: 'Google' }),
          resolveText: this.$t('использовать_type', { type: 'Google' }),
          fullscreen: true,
          persistent: true,
        }).then(() => {
          this.linkGoogleAccount()
        }).catch(() => {})
      }
    },

    linkGoogleAccount() {
      window.FirebasePlugin.authenticateUserWithGoogle(process.env.VUE_APP_GOOGLE_PROVIDE_ID, (credential) => {
        window.FirebasePlugin.linkUserWithCredential(credential, () => {
          this.googleAccountLinked()
        }, (error) => {
          // Костыль
          if (error === 'User has already been linked to the given provider.') {
            this.googleAccountLinked()
          } else if (error === 'This credential is already associated with a different user account.') {
            this.showSnackbar({ message: `${this.$t('данная_учетная_запись_google_уже_добавлена_к_другому_номеру_телефона')}` })
          } else {
            console.log(error)
            this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: 'Google' }) })
          }
        })
      }, (error) => {
        console.log(error)
        this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: 'Google' }) })
      })
    },

    googleAccountLinked() {
      localStorage.setItem('rekassa.kz-auth-google-enabled', 'true')
      window.FirebasePlugin.reloadCurrentUser()
      this.showConfirm({
        title: 'Google',
        icon: 'mdi-google',
        text: this.$t('успешно_подключен_при_следующем_входе_dotdotdot', { provider: 'Google' }),
        resolveText: this.$t('ok'),
        fullscreen: true,
      }).then(() => {}).catch(() => {})
    },

    // Face/Touch ID
    isFaceTouchIdAvailable() {
      const freshSignin = (localStorage.getItem('rekassa.kz-auth-fresh-signin') || 'false') === 'true'
      localStorage.setItem('rekassa.kz-auth-fresh-signin', 'false')
      const faceTouchIdEnabled = (localStorage.getItem('rekassa.kz-auth-face-touch-id-enabled') || 'false') === 'true'

      if (freshSignin && !faceTouchIdEnabled) {
        window.Fingerprint.isAvailable((type) => {
          this.faceTouchIdType = (type === 'face' ? 'Face ID' : 'Touch ID')
          this.showConfirm({
            title: this.faceTouchIdType,
            icon: type === 'face' ? 'mdi-face-recognition' : 'mdi-fingerprint',
            text: this.$t('использовать_type_для_входа_в_приложение', { type: this.faceTouchIdType }),
            resolveText: this.$t('использовать_type', { type: this.faceTouchIdType }),
            fullscreen: true,
            persistent: true,
          }).then(() => {
            this.registerFaceTouchId()
          }).catch(() => {})
        },
        () => {})
      }
    },

    registerFaceTouchId() {
      const email = `${this.user.phone}@rekassa.kz`
      const password = passwordGenerator.generate({ length: 15, numbers: true })

      window.FirebasePlugin.updateUserEmail(email, () => {
        window.FirebasePlugin.updateUserPassword(password, () => {
          window.Fingerprint.registerBiometricSecret({
            secret: `${email}:${password}`,
            invalidateOnEnrollment: !this.$isAndroid(),
            disableBackup: true,
          }, () => {
            localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'true')
            localStorage.setItem('rekassa.kz-auth-face-touch-id-type', this.faceTouchIdType)
            this.showConfirm({
              title: this.faceTouchIdType,
              icon: this.faceTouchIdType === 'Face ID' ? 'mdi-face-recognition' : 'mdi-fingerprint',
              text: this.$t('успешно_подключен_при_следующем_входе_dotdotdot', { provider: this.faceTouchIdType }),
              resolveText: this.$t('ok'),
              fullscreen: true,
            }).then(() => {}).catch(() => {})
          }, (error) => {
            console.log(error)
            this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
          })
        }, (error) => {
          console.log(error)
          this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
        })
      }, (error) => {
        console.log(error)
        this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
      })
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    next()
  },
}
</script>

<style lang="stylus">
.v-toolbar__title.cash-register-name
  font-size 1.15rem !important
</style>
