import firebase from 'firebase/app'
import 'firebase/auth'
import $http from '../../utils/http'
import router from '../../router'
import i18n from '../../i18n/index'

const auth = {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    async setUser({ commit }, user) {
      if (user) {
        commit('set', { type: 'user', value: (await $http.get('/api/auth/me')).data })
      }
    },

    setName({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        $http.post(`/api/auth/me/set-name?name=${payload.name}`).then((result) => {
          commit('set', { type: 'user', value: result.data })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` }, { root: true })
          reject()
        })
      })
    },

    signInWithCustomToken({ }, payload) {
      // Cordova
      if (window.cordova !== undefined) {
        return new Promise((resolve, reject) => {
          window.FirebasePlugin.signInUserWithCustomToken(payload.token, () => {
            window.FirebasePlugin.getCurrentUser((user) => {
              resolve({ user })
            }, () => {
              reject()
            })
          }, () => {
            reject()
          })
        })
      }
      // Web
      return firebase.auth().signInWithCustomToken(payload.token)
    },

    signOut({ commit, dispatch }) {
      dispatch('positions/clear', null, { root: true })
      // Cordova
      if (window.cordova !== undefined) {
        window.FirebasePlugin.signOutUser()
        commit('set', { type: 'user', value: null })
        router.push('/signin')
      // Web
      } else {
        firebase.auth().signOut().then(() => {
          commit('set', { type: 'user', value: null })
          router.push('/signin')
        }).catch(() => {
          router.push('/signin')
        })
      }
    },
  },
}

export default auth
