<template lang="pug">
  v-snackbar(app v-model="show" :multi-line="multiLine" :timeout="timeout" rounded top right transition="fade-transition")
    template(v-slot:default)
      span {{ message }}
      a.ml-2(v-if="url" @click="showDetails()") {{ $t("подробнее") }}
    template(v-slot:action="{ attrs }")
      v-btn(icon color="primary" v-bind="attrs" @click.native="show = false")
        v-icon {{ queue.length > 0 ? 'mdi-skip-next' : 'mdi-close' }}
</template>

<script>
import { mapActions } from 'vuex'
import helpUrls from '../../utils/helpUrls'

export default {
  name: 'Snackbar',

  data() {
    return {
      show: false,
      multiLine: false,
      message: '',
      url: null,
      queue: [],
      timeout: 4000,
    }
  },

  computed: {
    hasPendingMessages() {
      return this.queue.length > 0
    },
  },

  watch: {
    show() {
      if (!this.show && this.hasPendingMessages) {
        this.processMessage()
        // eslint-disable-next-line no-return-assign
        this.$nextTick(() => {
          this.show = false
          setTimeout(() => {
            this.show = true
          }, 200)
        })
      }
    },
  },

  created() {
    this.$store.watch(state => state.tools.snackbar, () => {
      const msgObj = this.$store.state.tools.snackbar
      if (msgObj.message !== '') {
        this.queue.push(this.$store.state.tools.snackbar)
        this.processMessage()
        this.showSnackbar({ message: '' })
      }
    })
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
    }),

    processMessage() {
      const nextMsgObj = this.queue.shift()
      if (nextMsgObj.message !== undefined) {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 200)

        this.message = nextMsgObj.message
        this.url = nextMsgObj.urlCode ? helpUrls[nextMsgObj.urlCode] : nextMsgObj.url
        this.multiLine = nextMsgObj.message.length >= 130
        this.timeout = nextMsgObj.timeout ? nextMsgObj.timeout : 4000
      }
    },

    showDetails() {
      this.show = false
      this.$openLink(this.url, '_system')
    },
  },
}
</script>

<style lang="stylus">
</style>
