<template lang="pug">
span.ml-1 ver 1.0.8
</template>
<script>

export default {
  name: 'TVersion',
  data: () => ({
  }),
  methods: {
  },
}
</script>
<style lang="stylus">
</style>
