<template lang="pug">
  v-app

    v-app-bar(app dark dense flat clipped-left)
      img(class="mr-1 ml-3" src="static/logo.png" height="40")
      v-toolbar-title(class="mr-5 align-center")
        span.subtitle(class="title") reKassa 3.0

    v-main(flat)
      v-container(fill-height style="max-width: 350px")
        v-container
          v-row
            v-col(align="center")
              v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

          v-row(justify="center" no-gutters)
            v-col.mt-4.mb-6(cols="12" align="center")
              h2.headline {{ $t("вход_в_систему") }}

          v-row(justify="center" no-gutters v-if="step === 'CHOOSE_SIGNIN_TYPE'")
            v-col.mb-6(cols="12" v-if="googleEnabled")
              v-btn.black--text(block color="white" :loading="signInWithGoogleLoading" @click="signInWithGoogle()")
                v-icon(small left color="red") {{ 'mdi-google' }}
                | {{ $t("войти_через_type", { type: 'Google' }) }}

            v-col.mb-6(cols="12" v-if="faceTouchIdEnabled")
              v-btn.white--text(block color="black" :loading="signInWithFaceTouchIdLoading" @click="signInWithFaceTouchId()")
                v-icon(small left) {{ faceTouchIdType === 'Face ID' ? 'mdi-face-recognition' : 'mdi-fingerprint' }}
                | {{ $t("войти_через_type", { type: faceTouchIdType }) }}

            v-col.mb-6(cols="12" v-if="!$isCordova() || $isAndroid()")
              v-btn.white--text(block color="primary" :disabled="signInWithFaceTouchIdLoading || signInWithGoogleLoading" @click="signInWithWhatsApp()")
                v-icon(small left) mdi-whatsapp
                | {{ $t("войти_через_whatsapp") }}

            v-col.mb-6(cols="12" v-if="!$isCordova() || $isAndroid()")
              v-btn.white--text(block color="#00A9E2" :disabled="signInWithFaceTouchIdLoading || signInWithGoogleLoading" @click="signInWithTelegram()")
                v-icon(small left) mdi-telegram
                | {{ $t("войти_через_telegram") }}

            v-col.mb-3(cols="12")
              v-btn(block color="secondary" :disabled="signInWithFaceTouchIdLoading || signInWithGoogleLoading" @click="stepSignInWithPhoneNumber()")
                v-icon(small left) mdi-cellphone-android
                | {{ $t("войти_через_смс") }}

            v-row.mt-5(justify="center")
              span.caption {{ $t("продолжая_вы_соглашаетесь_с") }}
              a.caption.ml-1(@click="$openLink('https://static.rekassa.kz/ru/app/3.0/agreement/agreement.pdf', '_system')") {{ $t("условиями_использования") }}

            v-row.mt-5(justify="center" no-gutters)
              v-col(cols="4")
                v-row(justify="center" no-gutters)
                  a.caption(@click="changeLocale('ru')" :class="{ 'font-weight-bold' : locale === 'ru' }") Русский
              v-col(cols="4" justify="center")
                v-row(justify="center" no-gutters)
                  a.caption(@click="changeLocale('kk')" :class="{ 'font-weight-bold' : locale === 'kk' }") Қазақша

          v-row(justify="center" no-gutters v-if="step === 'ENTER_PHONE_NUMBER'")
            v-col(cols="12" align="center")
              v-form(@submit.prevent="sendCode()")
                v-text-field(
                  ref="phoneNumber"
                  v-model="phoneNumber"
                  v-mask="'+7 (###) ###-##-##'"
                  type="tel"
                  :hint="lastPhoneNumberPart ? $t('последний_вход_был_с', { part: lastPhoneNumberPart }) : null"
                  :label="$t('введите_ваш_номер_телефона')"
                  placeholder="+7 (###) ###-##-##"
                  :readonly="signInWithPhoneNumberLoading"
                  autocomplete="off"
                  @input="phoneNumberError = null"
                  :error-messages="phoneNumberError"
                  outlined)
                v-btn(block color="primary" type="submit" :loading="signInWithPhoneNumberLoading" @click.prevent="sendCode()") {{ $t("продолжить") }}
            v-row.mt-4
              v-col(cols="12")
                v-btn(block text color="primary" :disabled="signInLoading" @click="step = 'CHOOSE_SIGNIN_TYPE'") {{ $t("назад") }}

          v-row(justify="center" no-gutters v-if="step === 'ENTER_CONFIRMATION_CODE'")
            v-col(cols="12")
              v-form(@submit.prevent="verifyCode()")
                v-text-field(
                  ref="confirmationCode"
                  v-model="confirmationCode"
                  v-mask="'######'"
                  type="tel"
                  maxlength="6"
                  :label="$t('введите_код_подтверждения')"
                  placeholder="######"
                  :readonly="signInLoading"
                  autocomplete="off"
                  @input="confirmationCodeError = null"
                  :error-messages="confirmationCodeError"
                  outlined)
                v-btn(block color="primary" type="submit" :loading="signInLoading" @click.prevent="verifyCode()") {{ $t("авторизоваться") }}
            v-row.mt-4
              v-col(cols="12")
                v-btn(block text color="primary" :disabled="signInLoading" @click="backToSigninType()") {{ $t("назад") }}

    button.d-none(id="sign-in-button")

    v-dialog(v-model="signInWithAlternativesDialog" max-width="350px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("не_пришел_смс_попробуйте_войти_другим_способом") }}
        v-card-text.px-0
          v-container
            v-col.mb-3(cols="12")
              v-btn.white--text(block color="primary" @click="signInWithAlternativesDialog = false; signInWithWhatsApp();")
                v-icon(small left) mdi-whatsapp
                | {{ $t("войти_через_whatsapp") }}

            v-col.mb-4(cols="12")
              v-btn.white--text(block color="#00A9E2" @click="signInWithAlternativesDialog = false; signInWithTelegram()")
                v-icon(small left) mdi-telegram
                | {{ $t("войти_через_telegram") }}

    re-connection-warning
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { mapState, mapActions } from 'vuex'
import store from '../../store'
import i18n, { getLocale, changeLocale } from '../../i18n'
import ConnectionWarning from '../utils/ConnectionWarning.vue'

export default {
  components: {
    're-connection-warning': ConnectionWarning,
  },

  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_my_password_d6kg.svg',
      light: '/static/illustrations/rally/light/undraw_my_password_d6kg.svg',
    },

    step: 'CHOOSE_SIGNIN_TYPE',

    phoneNumber: null,
    phoneNumberError: null,

    confirmationCode: null,
    confirmationCodeError: null,

    signInWithPhoneNumberLoading: false,
    signInLoading: false,

    googleEnabled: false,
    signInWithGoogleLoading: false,

    faceTouchIdEnabled: false,
    faceTouchIdType: null,
    signInWithFaceTouchIdLoading: false,

    signInWithAlternativesDialog: false,
    signInWithAlternativesTimer: null,

    credential: null,

    lastPhoneNumberPart: null,

    rules: {
      required: v => !!v || this.$t('требуется_заполнить'),
    },
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.user) {
      this.$router.push('/')
    }

    this.lastPhoneNumberPart = localStorage.getItem('rekassa.kz-auth-last-phone-number-part') || null

    // iOS Face Touch ID
    if (this.$isCordova() && !this.$isAndroid()) {
      this.faceTouchIdEnabled = (localStorage.getItem('rekassa.kz-auth-face-touch-id-enabled') || 'false') === 'true'
      this.faceTouchIdType = localStorage.getItem('rekassa.kz-auth-face-touch-id-type')
    }

    // Google
    if (this.$isCordova() && this.$isAndroid()) {
      this.googleEnabled = (localStorage.getItem('rekassa.kz-auth-google-enabled') || 'false') === 'true'
    }
  },

  beforeRouteEnter(to, from, next) {
    // Set theme by param
    if (to.query.theme) {
      localStorage.setItem('rekassa.kz-ui-darkTheme', to.query.theme === 'light' ? 'false' : 'true')
    }

    // Sigin with custom token as param
    if (to.query.token) {
      store.dispatch('auth/signInWithCustomToken', { token: to.query.token }).then((tokenResult) => {
        store.dispatch('auth/setUser', tokenResult.user).then(() => {
          store.dispatch('cashRegisters/init').then(() => {
            store.dispatch('tools/firebaseAnalyticsLogEvent', { eventName: 'login', eventProperties: { type: 'Token' } })
            next('/')
            localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
            localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
            localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
          })
        })
      }).catch(() => {
        store.dispatch('tools/showSnackbar', { message: `${i18n.t('не_удалось_войти_по_токену')}` })
        next()
      })
    } else {
      next()
    }
  },

  mounted() {
    // recaptchaVerifier need only for Web
    if (!this.$isCordova()) {
      const self = this

      // Testing
      // firebase.auth().settings.appVerificationDisabledForTesting = true

      this.signInWithPhoneNumberLoading = true

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        size: 'invisible',
        callback: () => {
          self.sendConfirmationCode()
        },
      })

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId
        this.signInWithPhoneNumberLoading = false
      }).catch((error) => {
        if (error && error.code && this.$t(error.code)) {
          this.showSnackbar({ message: this.$t(error.code) })
        } else {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
        this.signInWithPhoneNumberLoading = true
      })
    } else {
      window.cordova.plugins.HelpCrunchPlugin.logout(() => {}, () => {})
    }
  },

  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      init: 'cashRegisters/init',
      setConnectionWarning: 'tools/setConnectionWarning',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
    }),

    signInWithGoogle() {
      if (this.signInWithGoogleLoading) return
      this.signInWithGoogleLoading = true
      window.FirebasePlugin.authenticateUserWithGoogle(process.env.VUE_APP_GOOGLE_PROVIDE_ID, (credential) => {
        this.cordovaSignInWithCredential(credential)
      }, (error) => {
        console.log(error)
        this.signInWithGoogleLoading = false
        this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: 'Google' }) })
      })
    },

    signInWithFaceTouchId() {
      this.signInWithFaceTouchIdLoading = true
      setTimeout(() => {
        window.Fingerprint.loadBiometricSecret({
          description: this.$t('вход_в_систему'),
          disableBackup: true,
        }, (result) => {
          this.cordovaSignInUserWithEmailAndPassword(result.split(':')[0], result.split(':')[1])
        }, (error) => {
          console.log(error)
          this.signInWithFaceTouchIdLoading = false
          this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: this.faceTouchIdType }) })
        })
      }, 500)
    },

    signInWithWhatsApp() {
      this.$openLink(`https://wa.me/${process.env.VUE_APP_WHATSAPP_BOT_NUMBER}?text=1`, '_system')
    },

    signInWithTelegram() {
      const alertCount = parseInt(localStorage.getItem('rekassa.kz-telegram-installed-alert-count') || 0, 10)
      if (alertCount < 3) {
        if (this.$isCordova()) {
          this.showConfirm({
            title: this.$t('у_вас_установлен_telegram'),
            resolveText: this.$t('установлен'),
            rejectText: this.$t('нет'),
          }).then(() => {
            localStorage.setItem('rekassa.kz-telegram-installed-alert-count', (alertCount + 1))
            this.$openLink(`tg://resolve?domain=${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
          }).catch(() => {
            if (this.$isAndroid()) {
              this.$openLink('https://play.google.com/store/apps/details?id=org.telegram.messenger', '_system')
            } else {
              this.$openLink('https://apps.apple.com/ru/app/telegram/id686449807', '_system')
            }
          })
        } else {
          this.showConfirm({
            title: this.$t('у_вас_установлен_telegram_desktop'),
            resolveText: this.$t('установлен'),
            rejectText: this.$t('нет'),
          }).then(() => {
            localStorage.setItem('rekassa.kz-telegram-installed-alert-count', (alertCount + 1))
            this.$openLink(`https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
          }).catch(() => {
            this.$openLink('https://desktop.telegram.org', '_system')
          })
        }
      } else if (this.$isCordova()) {
        this.$openLink(`https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
      } else {
        this.$openLink(`tg://resolve?domain=${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
      }
    },

    stepSignInWithPhoneNumber() {
      this.step = 'ENTER_PHONE_NUMBER'
      this.$nextTick(() => {
        if (!this.$isCordova()) {
          this.$refs.phoneNumber.focus()
        }
      })
    },

    sendCode() {
      if (this.$isCordova()) {
        this.sendConfirmationCode()
      } else {
        document.getElementById('sign-in-button').click()
      }
    },

    sendConfirmationCode() {
      if (this.$refs.phoneNumber.value.length === 18) {
        // Cordova
        if (this.$isCordova()) {
          this.signInWithPhoneNumberLoading = true
          window.FirebasePlugin.verifyPhoneNumber((credential) => {
            if (credential.instantVerification) {
              this.signInLoading = true
              this.cordovaSignInWithCredential(credential)
            } else {
              this.credential = credential
              this.step = 'ENTER_CONFIRMATION_CODE'
              this.signInWithPhoneNumberLoading = false
              this.$nextTick(() => {
                this.confirmationCode = null
                this.$refs.confirmationCode.reset()
                this.$refs.confirmationCode.focus()
              })

              this.signInWithAlternativesTimer = setTimeout(() => {
                this.signInWithAlternativesDialog = true
              }, 15000)
            }
          }, (error) => {
            this.signInWithPhoneNumberLoading = false
            if (error) {
              if (error.includes('network')) {
                this.setConnectionWarning(true)
              } else if (error.includes('ERROR_INVALID_PHONE_NUMBER')) {
                this.phoneNumberError = this.$t('введен_некорректный_номер_телефона')
              } else if (error.includes('ERROR_TOO_MANY_REQUESTS')) {
                this.showSnackbar({ message: this.$t('auth/too-many-requests') })
              } else this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            }
          }, this.phoneNumber, 60)
        // Web
        } else {
          this.signInWithPhoneNumberLoading = true
          firebase.auth().languageCode = 'ru'
          firebase.auth().signInWithPhoneNumber(this.phoneNumber, window.recaptchaVerifier).then((confirmationResult) => {
            window.confirmationResult = confirmationResult
            this.step = 'ENTER_CONFIRMATION_CODE'
            this.signInWithPhoneNumberLoading = false
            this.$nextTick(() => {
              this.confirmationCode = null
              this.$refs.confirmationCode.reset()
              this.$refs.confirmationCode.focus()
            })

            this.signInWithAlternativesTimer = setTimeout(() => {
              this.signInWithAlternativesDialog = true
            }, 15000)
          }).catch((error) => {
            this.signInWithPhoneNumberLoading = false
            if (error && (error.code === 'auth/invalid-phone-number')) {
              this.phoneNumberError = this.$t('введен_некорректный_номер_телефона')
            } else if (error && error.code && this.$t(error.code)) {
              this.showSnackbar({ message: this.$t(error.code) })
            } else {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            }
            window.recaptchaVerifier.reset(window.recaptchaWidgetId)
          })
        }
      } else {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        if (!this.$isCordova()) {
          window.recaptchaVerifier.reset(window.recaptchaWidgetId)
        }
      }
    },

    verifyCode() {
      if (this.$refs.confirmationCode.value.length === 6) {
        // Clear signInWithAlternativesTimer
        if (this.signInWithAlternativesTimer) {
          clearTimeout(this.signInWithAlternativesTimer)
        }

        // Cordova
        if (this.$isCordova()) {
          this.signInLoading = true
          this.credential.code = this.confirmationCode
          this.cordovaSignInWithCredential(this.credential)
        // Web
        } else {
          this.signInLoading = true
          window.confirmationResult.confirm(this.confirmationCode).then((userCredential) => {
            this.setUser(userCredential.user).then(() => {
              this.init().then(() => {
                this.firebaseAnalyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Sms' } })
                this.$router.push('/', () => {})
                window.recaptchaVerifier.clear()
              })
            })
          }).catch((error) => {
            this.signInLoading = false
            if (error && error.code === 'auth/invalid-verification-code') {
              this.confirmationCodeError = this.$t('введен_некорректный_код_подтверждения')
            } else if (error && error.code === 'auth/code-expired') {
              this.confirmationCodeError = this.$t('срок_кода_подтверждения_уже_истек')
            } else if (error && error.code && this.$t(error.code)) {
              this.showSnackbar({ message: this.$t(error.code) })
            } else {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            }
          })
        }
      } else {
        this.confirmationCodeError = this.$t('введите_код_подтверждения')
      }
    },

    cordovaSignInWithCredential(credential) {
      window.FirebasePlugin.signInWithCredential(credential, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          // Signing with Google
          if (this.signInWithGoogleLoading) {
            // Allow only users that have phoneNumbers
            if (user.phoneNumber) {
              this.setUser(user).then(() => {
                this.init().then(() => {
                  this.firebaseAnalyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Google' } })
                  this.$router.push('/', () => {})
                })
              })
            } else {
            // Else logout and show warning
              window.FirebasePlugin.signOutUser()
              this.signInWithGoogleLoading = false
              this.step = 'ENTER_PHONE_NUMBER'
              this.showSnackbar({ message: this.$t('данная_учетная_запись_google_не_привязана_к_rekassa_выполните_вход_через_номер_телефона') })
            }
          // SMS auth
          } else {
            this.setUser(user).then(() => {
              this.init().then(() => {
                this.firebaseAnalyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Sms' } })
                this.$router.push('/', () => {})
                localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
                localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
                localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
              })
            })
          }
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithPhoneNumberLoading = false
        this.signInLoading = false
        if (error) {
          window.error = error
          console.log(error)
          if (error.includes('ERROR_INVALID_VERIFICATION_CODE')) {
            this.confirmationCodeError = this.$t('введен_некорректный_код_подтверждения')
          } else if (error.includes('ERROR_SESSION_EXPIRED')) {
            this.confirmationCodeError = this.$t('срок_кода_подтверждения_уже_истек')
          } else this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
      })
    },

    cordovaSignInUserWithEmailAndPassword(email, password) {
      window.FirebasePlugin.signInUserWithEmailAndPassword(email, password, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          this.setUser(user).then(() => {
            this.init().then(() => {
              this.firebaseAnalyticsLogEvent({ eventName: 'login', eventProperties: { type: 'FaceId/TouchId' } })
              this.$router.push('/', () => {})
            })
          })
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithFaceTouchIdLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    backToSigninType() {
      // Clear signInWithAlternativesTimer
      if (this.signInWithAlternativesTimer) {
        clearTimeout(this.signInWithAlternativesTimer)
      }

      this.step = 'CHOOSE_SIGNIN_TYPE'
      this.phoneNumber = null

      if (!this.$isCordova()) {
        this.$nextTick(() => {
          window.recaptchaVerifier.reset(window.recaptchaWidgetId)
        })
      }
    },

    changeLocale(locale) {
      changeLocale(locale)
    },
  },
}
</script>
<style lang="stylus">
.grecaptcha-badge
  opacity 0
</style>
