<template lang="pug">
v-text-field.tright.inputEzcar(
  v-bind='$attrs',
  @blur.stop='isFocused = false',
  @focus='isFocused = true',
  :suffix='suffix',
  @change='$emit("input", $event); !$event ? (amount = null) : null',
  clearable,
  Zmessages='delay ? (autocompleteShow ? (isFocused ? "Show" : null) : hints ? "Show" : null) : null',
  :messages='isFocused ? "Show" : null',
  hide-details='auto',
  Zautofocus,
  inputmode='numeric',
  pattern='[0-9]*',
  outlined
)
  template(v-slot:message='{ message, key }')
    v-slide-group.tinput(center-active, Zmultiple, v-model='amount', @change='$emit("input", hintList[$event])')
      v-slide-item(v-for='t in hintList', :key='t', v-slot='{ active, toggle }')
        v-btn.mr-1(
          :input-value='active',
          color='grey',
          active-class='indigo white--text',
          rounded,
          outlined,
          @mousedown.prevent='toggle'
        ) {{ t }}{{ suffix }}
//- v-text-field(Zsolo clearable hint=" " autofocus inputmode="numeric" @blur="toggle" pattern="[0-9]*" outlined v-bind="$attrs" v-on="$listeners")
</template>
<script>
export default {
  components: {},
  props: {
    suffix: {
      type: String,
      default: '₸',
    },
    autocompleteShow: {
      type: Boolean,
      default: undefined,
    },
    hints: {
      type: Array,
      default: undefined,
    },
  },

  data: () => ({
    isFocused: false,
    amount: null,
    delay: false,
  }),

  computed: {
    hintList() {
      return this.hints || this.cashHintList
    },
    cashHintList() {
      return [100, 200, 500, 1000, 2000, 5000, 10000, 20000]
    },
    percentHintList() {
      return [1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 70, 80]
    },
  },

  watch: {},
  created() {
    this.toggle()
  },

  methods: {
    toggle() {
      setTimeout(() => {
        this.delay = !this.delay
        if (!this.delay) this.$emit('blur')
      }, 50)
    },
    // ...mapActions({
    //   checkout: 'tjtheme/checkout',
    // }),
    // hasSecondButton() {
    //   return !!this.$slots.default
    // },
  },
}
</script>
<style lang="stylus">
.tinput
  .v-slide-group__prev.v-slide-group__prev--disabled
    min-width auto
.inputEzcar
  input
    max-height none !important // bug with ios input
</style>
