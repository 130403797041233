<template lang="pug">
div
  template(v-if="$slots.activator && hide")
    slot(name="activator")
  v-expand-transition
    div(v-show="value"): slot
  template(v-if="$slots.activator && !hide")
    slot(name="activator")

  //- v-expansion-panels(flat)
    v-expansion-panel.expandos

      v-expansion-panel-header(Zdisable-icon-rotate)
        template(v-slot:actions): v-icon.icon $expand
        template(v-slot:default='{ open }')
          v-row(no-gutters)
            v-col(cols='4') Наличные
            v-col.text--secondary(cols='8')
              v-fade-transition(leave-absolute)
                span(v-if='open' key='0') A
                span(v-else key='1') B

        //- span.header Наличные
        //- template(v-slot:actions): v-icon(color='primary') $expand
      v-expansion-panel-content
        | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
</template>
<script>
export default {
  name: 'TCollapse',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {

  },
}
</script>
<style lang="stylus">

.expandos .icon
  order: 0

.expandos .header
  order: 1
</style>
