function ArrayRemoveElement(arr, item) {
  const index = arr.indexOf(item)
  if (index !== -1) {
    arr.splice(index, 1)
  }
}

const tjtheme = {
  namespaced: true,

  state: {
    catalog: [
      {
        name: 'Молоко',
        barcode: '4044996104560',
        unitType: 'PIECE',
        price: 1000,
        total: 100,
        realQuantity: 1,
      },
      {
        name: 'Заявление на получение субсидии ИС',
        realQuantity: 1,
        unitType: 'PIECE',
        price: 100,
        discountValue: 10,
        total: 100,
        barcode: '4044996104561',
      },
      {
        name: 'Тестер напряжения жидкокристаллический дисплей 12-220 В Sparta (130805) 2611',
        barcode: '4044996104562',
        realQuantity: 1,
        unitType: 'PIECE',
        price: 100,
        total: 100,
        discountValue: 0,
      },
      {
        name: '35122-100; STAYER "PROFESSIONAL". Щетка дисковая для УШМ, витая стальная латунированная проволока 0,3мм, 100ммх22мм',
        realQuantity: 1,
        unitType: 'PIECE',
        price: 164,
        discountValue: 0,
        markupValue: 0,
        markupType: 'шт',
        markupPercentage: 1,
        total: 1164,
      },
      {
        name: 'Мар Трехслойный (вес) 4 кг.',
        barcode: '4870004453180',
        realQuantity: 1,
        unitType: 'PIECE',
        price: 20000,
        total: 200,

      },
    ],
    categories: [
      { title: 'Все', icon: 'mdi-folder' },
      { title: 'Товары', icon: 'mdi-folder' },
      { title: 'Услуги', icon: 'mdi-folder' },
      { title: 'Гос', icon: 'mdi-folder' },
      { title: 'Тестеры', icon: 'mdi-folder' },
      { title: 'Напитки', icon: 'mdi-folder' },
      { title: 'Масло', icon: 'mdi-folder' },
      { title: 'Земля', icon: 'mdi-folder' },
      { title: 'Лекарства', icon: 'mdi-folder' },
    ],
    cart: [],
    orders: [
      {
        created: '01.01.2021',
        payed: '02.01.2021',
        booked: '03.01.2021',
        client: {
          name: 'Ивано Иван Иваныч',
          phone: '+771238718273',
        },
        check: {
          items: [{
            name: 'Молоко', price: 1000,
          }],
          total: 5690,
        },
      },
    ],
    contacts: [],
    cashRegister: {
      id: 124,
      name: '',
      model: 'reKassa 3.0',
      serialNumber: 'demo',
      year: 2021,
      status: 'TRIAL',
      createDate: '2021-12-01T12:41:21.361Z',
      registrationDate: null,
      registrationNumber: 'demo',
      fdo: 'TTK',
      fdoId: 'demo',
      fdoMode: 'OFFLINE',
      shiftNumber: 1,
      shiftOpen: true,
      shiftOpenTime: '2021-12-01T19:50:01.272Z',
      shiftCloseTime: null,
      shiftExpireTime: '2021-12-02T19:50:01.272Z',
      shiftExpired: true,
      shiftDocumentNumber: 1,
      shiftMessageNumber: 1,
      data: {
        preferences: {
          buyMode: false, domainType: 'DOMAIN_SERVICES', simpleMode: true, exciseStamp: false, paymentTypes: { card: true, tare: false, credit: false }, defaultTaxType: 'TAX_12', defaultItemName: 'Позиция', defaultItemType: 'PIECE', operationTotalLimit: 500000, businessNameOnReceipt: 'ORGANIZATION',
        },
        configuration: { taxMode: false, timezone: '+06:00', taxationType: 'STS' },
      },
      pos: { title: 'ИП «DEMO»', address: 'ул. Демонстрационная, 1' },
      offlineStartTime: '2021-12-01T19:50:01.272Z',
      offlineExpireTime: null,
      blockStartTime: null,
      shift: {
        cashRegister: 124,
        shiftNumber: 1,
        openTime: '2021-12-02T01:50:01.272',
        lastTime: null,
        closeTime: null,
        requestTime: null,
        shiftTicketNumber: 1,
        shiftDocumentNumber: 1,
        shiftMessageNumber: 1,
        open: true,
        offline: null,
        data: {
          cashSum: { bills: 500, coins: 0 },
          markups: [{ sum: { bills: 0, coins: 0 }, count: 0, operation: 'OPERATION_SELL' }],
          revenue: { sum: { bills: 500, coins: 0 }, isNegative: false },
          dateTime: { date: { day: 17, year: 2021, month: 12 }, time: { hour: 20, minute: 42, second: 44 } },
          discounts: [{ sum: { bills: 0, coins: 0 }, count: 0, operation: 'OPERATION_SELL' }],
          operations: [{ sum: { bills: 500, coins: 0 }, count: 1, operation: 'OPERATION_SELL' }],
          shiftNumber: 1,
          totalResult: [{ sum: { bills: 500, coins: 0 }, count: 1, operation: 'OPERATION_SELL' }],
          openShiftTime: { date: { day: 2, year: 2021, month: 12 }, time: { hour: 1, minute: 50, second: 1 } },
          nonNullableSums: [{ sum: { bills: 0, coins: 0 }, operation: 'OPERATION_BUY_RETURN' }, { sum: { bills: 0, coins: 0 }, operation: 'OPERATION_SELL_RETURN' }, { sum: { bills: 0, coins: 0 }, operation: 'OPERATION_BUY' }, { sum: { bills: 500, coins: 0 }, operation: 'OPERATION_SELL' }],
          ticketOperations: [{
            payments: [{ sum: { bills: 500, coins: 0 }, count: 1, payment: 'PAYMENT_CASH' }], changeSum: { bills: 1500, coins: 0 }, markupSum: { bills: 0, coins: 0 }, operation: 'OPERATION_SELL', ticketsSum: { bills: 500, coins: 0 }, discountSum: { bills: 0, coins: 0 }, offlineCount: 1, ticketsCount: 1, ticketsTotalCount: 1,
          }],
          startShiftNonNullableSums: [{ sum: { bills: 0, coins: 0 }, operation: 'OPERATION_BUY_RETURN' }, { sum: { bills: 0, coins: 0 }, operation: 'OPERATION_SELL_RETURN' }, { sum: { bills: 0, coins: 0 }, operation: 'OPERATION_BUY' }, { sum: { bills: 0, coins: 0 }, operation: 'OPERATION_SELL' }],
        },
        operator: null,
        status: null,
        _links: {
          shifts: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts?includeOpen=false' }, self: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1' }, tickets: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/tickets' }, operations: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/operations' }, xReport: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/reports/x' }, close: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/close' }, operatorsReport: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/reports/operators' }, sectionsReport: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts/1/reports/sections' },
        },
      },
      deregistrationDate: null,
      _links: {
        self: { href: 'https://app-test.rekassa.kz/api/crs/124' }, 'self-with-roles': { href: 'https://app-test.rekassa.kz/api/user/50/crs/124' }, shifts: { href: 'https://app-test.rekassa.kz/api/crs/124/shifts?includeOpen=false' }, 'report-fiscal': { href: 'https://app-test.rekassa.kz/api/crs/124/reports/fiscal?fromDate=2021-12-17&toDate=2021-12-17' }, preferences: { href: 'https://app-test.rekassa.kz/api/crs/124/preferences' }, tickets: { href: 'https://app-test.rekassa.kz/api/crs/124/tickets' }, cash: { href: 'https://app-test.rekassa.kz/api/crs/124/cash' },
      },
    },
    organization: {
      id: null, businessId: '999999999999', businessName: 'ИП «DEMO»', status: 'ACTIVE',
    },
    user: {
      id: 50,
      uid: 'f4NcxcqeWMcYyK7ZzsLP7GDgKH63',
      name: null,
      email: null,
      phone: '+77779005000',
      status: 'ACTIVE',
      roles: { cashRegisterRoles: [{ role: 'OWNER', cashRegister: 124 }] },
      settings: {},
      registrationDate: '2021-12-01T12:41:21.361Z',
      _links: {
        'set-name': { href: 'https://app-test.rekassa.kz/api/auth/me/set-name?name={name}', templated: true }, cashRegisterRoles: { href: 'https://app-test.rekassa.kz/api/user/50/crs' }, cashRegisterRolesArchive: { href: 'https://app-test.rekassa.kz/api/user/50/crs/archive' }, createCashRegister: { href: 'https://app-test.rekassa.kz/api/crs?name={name}', templated: true }, 'restore-cash-register-user-role': { href: 'https://app-test.rekassa.kz/api/user/50/restore-cash-register-role?fdo={fdo}&id={id}&serialNumber={serialNumber}&token={token}&password={password}', templated: true },
      },
    },
    client: null,
  },

  getters: {
    total(state) {
      // state.cart.reduce((acc, i) => acc + i.total, 0),
      if (!state.cart) {
        return 0
      }
      return state.cart.reduce((a, b) => {
        if (b?.ui?.disabled) { return a }
        return a + b.total
      }, 0)
    },
    quantity: (state) => state.cart.reduce((acc, i) => acc + i.realQuantity, 0),
    catalog: (state) => state.catalog,
    contacts: (state) => state.contacts,
    categories: (state) => state.categories,
    orders: (state) => state.orders,
    cart: (state) => state.cart,
    client: (state) => state.client,
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    checkout({ state, commit, dispatch }) {
      dispatch('clearCart')
    },
    clearCart({ state, commit, dispatch }) {
      state.cart = []
      state.client = null
    },
    addClient({ state, commit, dispatch }, param) {
      state.client = param
    },
    deleteItem({ state, commit, dispatch }, item) {
      ArrayRemoveElement(state.cart, item)
    },
    addItem({ state, commit, dispatch }, item) {
      // console.log('addItem', item)

      if (!item) {
        item = {
          realQuantity: 1,
          unitType: 'PIECE',
          price: 100,
          discountValue: 0,
          markupValue: 0,
          markupType: 'шт',
          markupPercentage: 1,
          total: 50,
          taxes: [
            { sum: { value: 0 }, percent: 0 },
          ],
        }
      }

      if (!item.name) {
        item.name = `Позиция ${Math.random().toString(36).slice(8)}`
      }

      const r = state.cart.find(p => p.name === item.name)
      if (!r) {
        state.cart.push(item)
      } else {
        r.realQuantity += 1
        r.total = r.price * r.realQuantity
      }
    },
  },
}

const settings = {
  state: {
    navigation: null,
    ui: {
      mock: false,
      useLabel: false,
      pincode: '1234',
    },
    camera: {
      type: 'back',
      scanAttempt: 2,
    },
    timeouts: {
      pickDialog: 5000,
    },
    dashboard: {
      readyColor: '#1a009f',
    },
    sound: {
      volume: 1,
      disabled: false,
      actions: {
        print: 'bind',
        error: 'error',
      },
    },
  },
  getters: {
    navigation: state => state.navigation,
    ui: state => state.ui,
    camera: state => state.camera,
    timeouts: state => state.timeouts,
    dashboard: state => state.dashboard,
    sound: state => state.sound,
  },
  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },
}

export { tjtheme, settings }
