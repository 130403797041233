/* eslint-disable no-nested-ternary */
import { Decimal } from 'decimal.js'
import Vue from 'vue'
import i18n from '../i18n/index'

export default {
  required: v => !!v || i18n.t('требуется_заполнить'),
  length: (length) => v => (!!v && v.length === length) || i18n.t('требуется_заполнить'),
  email: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || i18n.t('некорректный_адрес_почты'),
  mobileNumber: v => (!!v && /^(([0-9]){11})$/.test(String(v))) || i18n.t('некорректный_сотовый_номер'),
  numberRange: (min, max, v) => (v !== '' && v !== null && new Decimal(max).greaterThanOrEqualTo(v) && new Decimal(min).lessThanOrEqualTo(v))
    || (v !== '' && v !== null && new Decimal(max).lessThan(v) ? i18n.t('больше_чем', { number: Vue.filter('numeral')(max, '0,0.[00]') }) : (v !== '' && v !== null && new Decimal(min).greaterThan(v) ? i18n.t('меньше_чем', { number: Vue.filter('numeral')(min, '0,0.[00]') }) : i18n.t('должно_быть_между', { min: Vue.filter('numeral')(min, '0,0.[00]'), max: Vue.filter('numeral')(max, '0,0.[00]') }))),
  numbersOnly: (v) => (!!v && /^\d+$/.test(v)) || i18n.t('допускаются_только_цифры'),
  ip: (v) => (!!v && /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(v)) || i18n.t('некорректный_ip_адрес'),
}
