<template lang="pug">
  v-main(flat)
    v-container.receipt(style="max-width: 400px")
      v-row
        v-col
          h1.headline.font-weight-bold.text-center {{ preferences.businessNameOnReceipt === 'ORGANIZATION' ? organization.businessName : cashRegister.pos.title }}
          h2.subtitle-1.text-center {{ $t("бин_иин", ticketLocale) }}: {{  organization.businessId }}
          h2.subtitle-2.text-center {{ cashRegister.pos.address }}

          div.d-flex.justify-center.ma-4(v-if="ticketData.operation.includes('OPERATION')")
            div.qrcode(v-if="ticket.qrCode" style="background: #fffffe; background-image: linear-gradient(#fffffe, #fffffe);")
              qrcode(:value="ticket.qrCode" size="120" level="L" renderAs="svg")
            div.qrcode(v-else)
              img(src="static/qrcode.svg" height="120" width="120")
          div.mb-5(v-else)

      v-row.mb-4
        v-col.text-uppercase
          h2.title.text-center(v-if="ticket.status") {{ $t(cashRegister.status === 'TRIAL' ? "нефискальный_чек" : "фискальный_чек", ticketLocale) }}
          h2.title.text-center(v-else) {{ $t("предварительный_чек", ticketLocale) }}

      v-row(v-if="ticket.offline")
        v-col.text-uppercase {{ $t("автономный", ticketLocale) }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t('operationType.' + ticketData.operation, ticketLocale) }}:
            div(v-if="ticket.shiftDocumentNumber") №{{ ticket.shiftDocumentNumber }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("смена", ticketLocale) }}:
            div {{ ticket.shiftNumber }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("дата", ticketLocale) }}:
            div(v-if="ticketData.dateTime") {{ ticketData.dateTime.value | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("время", ticketLocale) }}:
            div(v-if="ticketData.dateTime") {{ ticketData.dateTime.value | moment("HH:mm:ss") }}
      v-row(v-if="commodityItems")
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("фп", ticketLocale) }}:
            div {{ ticket.offline ? ticket.offlineTicketNumber : ticket.ticketNumber }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("кассир", ticketLocale) }}:
            div {{ ticket.operator ? ticket.operator.code : '' }}
      v-row(v-else)
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("кассир", ticketLocale) }}:
            div {{ ticket.operator ? ticket.operator.code : '' }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("рнм", ticketLocale) }}:
            div {{ cashRegister.registrationNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("знм", ticketLocale) }}:
            div {{ cashRegister.serialNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("ккм", ticketLocale) }}:
            div {{ cashRegister.model }}

      v-divider.mt-3.mb-3

      template(v-if="ticket.cancelledBy")
        v-row
          v-col
            h1.headline.text-center.red--text.text-uppercase {{ $t("чек_отменен", ticketLocale) }}
        v-divider.mt-3.mb-3

      template(v-if="ticket.cancelledBy === null && ticket.extra.receipts && ticket.extra.receipts.length > 0")
        v-row
          v-col
            h1.headline.text-center.text-uppercase {{ $t("дубликат", ticketLocale) }}
        v-divider.mt-3.mb-3

      v-list(two-line v-for="(item, $index) in commodityItems" :key="'c_' + $index")
        v-list-item
          v-list-item-content
            v-list-item-title {{ $index + 1 }}. {{ item.commodity.name }}
        v-list-item(v-if="item.commodity.exciseStamp")
          v-list-item-content
            v-list-item-subtitle {{ item.commodity.exciseStamp }}
        v-list-item
          v-list-item-content
            v-list-item-subtitle {{ item.commodity.realQuantity | numeral('0,0.0[00]') }} {{ item.commodity.unitType ? $t('unitType.' + item.commodity.unitType, ticketLocale) : '' }} &times; {{ item.commodity.price.value | numeral('0,0.00') }} ₸
          v-list-item-action
            v-list-item-action-text.body-1 {{ item.commodity.sum.value | numeral('0,0.00') }} ₸
        v-list-item(v-if="item.commodity.discountType")
          v-list-item-content
            v-list-item-subtitle {{ $t("скидка", ticketLocale) }} {{ item.commodity.discountType === 'PERCENTAGE' ? `(${ item.commodity.discountPercentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 - {{ item.commodity.discountValue | numeral('0,0.00') }} ₸
        v-list-item(v-if="item.commodity.markupType")
          v-list-item-content
            v-list-item-subtitle {{ $t("наценка", ticketLocale) }} {{ item.commodity.markupType === 'PERCENTAGE' ? `(${ item.commodity.markupPercentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 + {{ item.commodity.markupValue | numeral('0,0.00') }} ₸
        v-list-item
          v-list-item-content
            v-list-item-subtitle {{ $t("стоимость", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 {{ item.commodity.total | numeral('0,0.00') }} ₸
        v-list-item(v-if="item.commodity.taxes && item.commodity.taxes[0]")
          v-list-item-content
            v-list-item-subtitle {{ $t("в_т_ч_ндс", ticketLocale) }} {{ item.commodity.taxes[0].percent / 1000 }}%
          v-list-item-action
            v-list-item-action-text.body-1 {{ item.commodity.taxes[0].sum.value | numeral('0,0.00') }} ₸

      v-divider.mt-3.mb-3(v-if="commodityItems")

      v-list
        v-list-item
          v-list-item-content
            v-list-item-title.headline.text-uppercase {{ $t("итого", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.display-2 {{ this.ticketData.amounts.total.value | numeral('0,0.00') }} ₸

      v-list.totals
        v-list-item(v-if="ticketData.amounts && ticketData.amounts.discount")
          v-list-item-content
            v-list-item-title {{ $t("скидка", ticketLocale) }} {{ ticketData.amounts.discount.type === 'PERCENTAGE' ? `(${ ticketData.amounts.discount.percentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 - {{ ticketData.amounts.discount.value | numeral('0,0.00') }} ₸

        v-list-item(v-if="ticketData.amounts && ticketData.amounts.markup")
          v-list-item-content
            v-list-item-title {{ $t("наценка", ticketLocale) }} {{ ticketData.amounts.markup.type === 'PERCENTAGE' ? `(${ ticketData.amounts.markup.percentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 + {{ ticketData.amounts.markup.value | numeral('0,0.00') }} ₸

        span(v-for="(payment, $index) in otherPayments" :key="'p_' + $index")
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("paymentType." + payment.type, ticketLocale) }}
            v-list-item-action
              v-list-item-action-text.body-1 {{ payment.sum.value | numeral('0,0.00') }} ₸
          template(v-if="payment.type === 'PAYMENT_CARD' && ticket.extra && ticket.extra.attributes && ticket.extra.attributes.paymentType")
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("оплачено_через_name", ticketLocale, { name: $t(ticket.extra.attributes.paymentType, ticketLocale) }) }}
          template(v-if="payment.type === 'PAYMENT_CARD' && ticket.extra && ticket.extra.attributes && (ticket.extra.attributes.paymentType === 'ALFA_PAY' || ticket.extra.attributes.paymentType === 'HALYK_POS')")
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("номер_операции", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankOperationDay }}/{{ ticket.extra.attributes.m4bankTransactionNumber }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("дата_оплаты", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankDateTime | moment("DD-MM-YYYY HH:mm:ss") }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("реквизиты_карты", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankMaskedPan }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("организация", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankMerchantName }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("терминал", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankTransactionNumber }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("код_ответа", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end 0
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("код_авторизации", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankAuthorizationCode }}
            v-list-item
              v-list-item-content
                v-list-item-subtitle {{ $t("rrn", ticketLocale) }}
              v-list-item-action
                v-list-item-subtitle.text-align-end {{ ticket.extra.attributes.m4bankRRN }}

        v-list-item(v-if="cashPayment > 0")
          v-list-item-content
            v-list-item-title {{ $t("наличные", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 {{cashPayment | numeral('0,0.00') }} ₸

        v-list-item(v-if="ticketData.amounts && ticketData.amounts.change && ticketData.amounts.change.value > 0")
          v-list-item-content
            v-list-item-title {{ $t("сдача", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 - {{ ticketData.amounts.change.value | numeral('0,0.00') }} ₸

        v-list-item(v-for="(tax, $index) in taxes" :key="'t_' + $index")
          v-list-item-content
            v-list-item-title {{ $t("в_т_ч_ндс", ticketLocale) }} {{ tax.percent }}%
          v-list-item-action
            v-list-item-action-text.body-1 {{ tax.value | numeral('0,0.00') }} ₸

      template(v-if="ticket.offline ? ticket.offlineTicketNumber : ticket.ticketNumber")
        v-divider.mt-3.mb-3

        v-row
          v-col.text-center
            div {{ $t(`fdo.${cashRegister.fdo}.title`, ticketLocale) }}
            div {{ $t("фиск_признак", ticketLocale) }}: {{ ticket.offline ? ticket.offlineTicketNumber : ticket.ticketNumber }}
            div {{ $t("код_ккм_кгд", ticketLocale) }}: {{ cashRegister.registrationNumber }}
            div {{ $t("для_проверки_чека_зайдите_на_сайт", ticketLocale) }}:&nbsp;
              span {{ $t(`fdo.${cashRegister.fdo}.url`, ticketLocale) }}

        template(v-if="preferences.advertisingText")
          v-divider.mt-3.mb-3

          v-row
            v-col.text-center
              div {{ preferences.advertisingText }}

</template>
<script>
import { mapState } from 'vuex'
import qrcode from 'qrcode.vue'
import ticketMixin from '../../../mixins/ticketMixin'

export default {
  components: {
    qrcode,
  },

  mixins: [ticketMixin],

  props: {
    ticket: {
      type: Object,
      default: null,
      required: true,
    },
    cashRegister: {
      type: Object,
      default: null,
      required: true,
    },
    organization: {
      type: Object,
      default: null,
      required: true,
    },
    ticketLocale: {
      type: String,
      default: null,
      required: true,
    },
  },

  computed: {
    ...mapState({
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    ticketData() {
      return this.ticket.data.ticket ? this.ticket.data.ticket : this.ticket.data.moneyPlacement
    },
  },

  mounted() {
    if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
      setTimeout(() => {
        this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
      }, 500)
    }
  },
}
</script>

<style lang="stylus">
.qrcode
  display inline-block
  padding 8px 8px 1px 8px
.receipt
  .col
    padding-top 1px
    padding-bottom 1px
  .v-divider
    &.theme--dark
      border-color rgba(255, 255, 255, 0.5)
    &.theme--light
      border-color rgba(0, 0, 0, 0.5)
  .v-list-item
    min-height 0
    padding 0
  .v-list-item__content
    padding-top 3px
    padding-bottom 3px
  .v-list-item__action-text
    &.body-1
      font-size 1rem !important
    &.display-2
      font-size 1.9rem !important
  .v-list-item__title
    white-space normal
    &.headline
      font-size 1.9rem !important
  .totals
    .v-list-item__action-text
      &.body-1
        font-size 1rem !important
  .v-list-item__action
    margin 0px
  .theme--dark
    &.v-list-item
      .v-list-item__action-text
        color rgb(255, 255, 255)
  .theme--light
    &.v-list-item
      .v-list-item__action-text
        color rgba(0, 0, 0, 0.87)

.receipt .v-list, .receipt .v-sheet
  background-color transparent
  padding-top 0
  padding-bottom 0
</style>
