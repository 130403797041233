<template lang="pug">
v-menu( v-bind="$attrs" v-on="$listeners" :close-on-content-click="false" :nudge-width="200" offset-x)
  template(v-slot:activator="{ on, attrs }")
    v-btn(color="indigo" dark v-bind="attrs" v-on="on") Menu as Popover
  v-card
    v-list
      v-list-item
        v-list-item-avatar
          img(src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John")
        v-list-item-content
          v-list-item-title John Leider
          v-list-item-subtitle Founder of Vuetify
        v-list-item-action
          v-btn(:class="fav ? 'red--text' : ''" icon @click="fav = !fav")
            v-icon mdi-heart
    v-divider
    v-list
      v-list-item
        v-list-item-action
          v-switch(v-model="message" color="purple")
        v-list-item-title Enable messages
      v-list-item
        v-list-item-action
          v-switch(v-model="hints" color="purple")
        v-list-item-title Enable hints
    v-card-actions
      v-spacer
      v-btn(text @click="menu = false") Cancel
      v-btn(color="primary" text @click="menu = false") Save
</template>
<script>

export default {
  components: {
  },
  // props: {
  //   value: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   icon: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   outlinedDefault: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   fab: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   hero: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   block: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   outlined: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   disabled: {
  //     type: Boolean,
  //     default: undefined,
  //   },
  //   color: {
  //     type: String,
  //     default: undefined,
  //   },
  //   iconColor: {
  //     type: String,
  //     default: undefined,
  //   },
  // },

  data: () => ({
  }),

  computed: {
    // isEmpty() {
    //   return this.empty && !this.totalSum
    // },
  },

  watch: {},

  methods: {
    // ...mapActions({
    //   checkout: 'tjtheme/checkout',
    // }),
    // hasSecondButton() {
    //   return !!this.$slots.default
    // },
  },
}
</script>
<style lang="stylus">

.introjs-relativePosition
  position relative

.introjs-showElement
  z-index 10!important

</style>
