<template lang="pug">
transition(name="splash" :duration="{enter:1800,leave:500}" :appear="appear" @enter="playLogo" @after-leave="logo=false" @after-enter="end")
  .transition-overlay(v-if="$root.splash || appear")
    .transition__first: object(v-if="logo" type="image/svg+xml" data="/logo/rekassa_anim.svg")
    .transition__second
</template>
<script>
const cur = new Date().getTime()

export default {
  components: {},
  props: {},
  data: () => ({
    logo: false,
    appear: false,
  }),
  created() {
    if (localStorage.getItem('splashShown') == null || cur - Number(localStorage.getItem('splashShown')) > 60000) {
      this.appear = true
      localStorage.setItem('splashShown', cur)
    }
    setTimeout(() => {
      localStorage.removeItem('splashShown')
    }, 60000)
  },
  methods: {
    end() {
      this.$root.splash = false
      this.appear = false
      this.logo = false
    },
    playLogo() {
      this.logo = true
      // setTimeout(() => {
      //   this.logo = true
      //   // this.$refs.img.style.display = ""
      //   // this.$refs.img.src = `/logo/rekassa_anim.svg#${Math.random()}`
      //   // console.log(this.$refs.img.src)
      //   // el.style.animation = 'none'
      //   // el.offsetHeight /* trigger reflow */
      //   // el.style.animation = null
      // }, 300)
    },
  },
}
</script>
<style lang="stylus">
.splash-enter-active
  .transition__first, .transition__second
    transform translateY(100%)
    animation slidein 400ms cubic-bezier(0.86, 0, 0.07, 1) forwards
  .transition__first
    animation-delay 100ms !important
.splash-leave-active
  .transition__first, .transition__second
    animation slideout 300ms cubic-bezier(0.86, 0, 0.07, 1) forwards
  .transition__second
    animation-delay 100ms !important
.transition-overlay
  width 100vw
  height 100vh
  position fixed
  top 0
  left 0
  z-index 99900
  pointer-events none
  text-align center
  box-sizing border-box
  .transition__first, .transition__second
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    will-change top
    // transition transform 500ms cubic-bezier(0.86, 0, 0.07, 1)
  .transition__second
    background #1eb980
    z-index -1
  .transition__first
    background #33333d
    &.img
      background-image url('/logo/rekassa.svg')
      background-position center
      background-repeat no-repeat
      background-size 9em auto
    img, object
      max-width 9em
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
@keyframes slidein
  0%
    transform translateY(100%)
  100%
    transform translateY(0%)
@keyframes slideout
  0%
    transform translateY(0%)
  100%
    transform translateY(-100%)
</style>
