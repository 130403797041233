<template lang="pug">
  v-app
    v-app-bar(app dark dense flat :class="{ 'd-none': showClose}")
      v-btn(icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-spacer
      v-toolbar-title {{ $t("сканировать_qr_код") }}
      v-spacer
      div(style="display: inline-block; width: 48px; height: 48px;")

    v-main(flat :class="{ 'd-none': showClose}")
      v-container(style="max-width: 400px")
        v-row.mt-10
          v-col
            .text-h3.text-center(style="font-family: 'Eczar', sans-serif !important") {{ total | numeral('0,0.[00]') }} ₸

        v-row.mt-4
          v-col
            v-row(justify="center")
              img.kaspi-qrcode(src="/static/kaspi-pay-small.png" height="36" width="36")
              div.kaspi-corners(:class="{ 'kaspi-corners-show': showCorners}")
                div.qrcode(style="background: #fffffe; background-image: linear-gradient(#fffffe, #fffffe);")
                  qrcode(:value="qrToken" size="140" level="H" renderAs="svg")

        v-row.mt-6
          v-col(cols="10" offset="1")
            v-list.gray-background-list
              v-list-item
                v-list-item-icon
                  img(src="/static/kaspi-pay-qr.png" height="30" width="30")
                v-list-item-content
                  v-list-item-title.font-weight-bold Kaspi QR
                  v-list-item-title {{ $t("сканируйте_и_платите") }}

        v-row.mt-3
          v-col
            .text-center {{ $t("способы_оплаты") }}

        v-row
          v-col.py-0(align="center")
            img.mx-3(v-if="this.data.data.paymentMethods.includes('Red')" src="/static/kaspi-red.png" height="36")
            img.mx-3(v-if="this.data.data.paymentMethods.includes('Gold')" src="/static/kaspi-gold.png" height="36")
            img.mx-3(v-if="this.data.data.paymentMethods.includes('Loan')" src="/static/kaspi-kredit.png" height="36")

    v-dialog(v-model="paymentProcessedDialog" scrollable fullscreen persistent width="500")
      v-card
        v-container
          v-row.mt-10
            v-col(align="center")
              v-img(:src="$vuetify.theme.dark ? paymentProcessedImageUrl.dark : paymentProcessedImageUrl.light" max-width="250" eager)

          v-row.mt-10
            v-col
              .headline.text-center(style="font-size: 2rem !important") {{ $t("оплата_принята") }}

          v-row.mt-10
            v-col
              .text-h3.text-center(style="font-family: 'Eczar', sans-serif !important") {{ total | numeral('0,0.[00]') }} ₸

          v-row.mt-10
            v-col(align="center")
              v-btn(:loading="!showClose" large color="primary" width="250" @click="closePaymentProcessedDialog()") {{ $t("закрыть") }}

    v-dialog(v-model="paymentErrorDialog" scrollable fullscreen persistent width="500")
      v-card
        v-container
          v-row.mt-10
            v-col(align="center")
              v-img(:src="$vuetify.theme.dark ? paymentErrorImageUrl.dark : paymentErrorImageUrl.light" max-width="250" eager)

          v-row.mt-10
            v-col
              .headline.text-center(style="font-size: 2rem !important") {{ $t("ошибка_оплаты_попробуйте_еще_раз") }}

          v-row.mt-10
            v-col(align="center")
              v-btn(large color="error" width="250" @click="closePaymentErrorDialog()") {{ $t("закрыть") }}

    v-dialog(v-model="ticketErrorDialog" scrollable fullscreen persistent width="500")
      v-card
        v-container
          v-row.mt-10
            v-col(align="center")
              v-img(:src="$vuetify.theme.dark ? ticketErrorImageUrl.dark : ticketErrorImageUrl.light" max-width="250" eager)

          v-row.mt-10
            v-col
              .headline.text-center(style="font-size: 2rem !important") {{ $t("оплата_kaspi_pay_прошла_но_не_удалось_оформить_чек_автоматически") }}

          v-row.mt-10
            v-col(align="center")
              v-btn(large color="warning" width="250" @click="closeTicketErrorDialog()") {{ $t("попробовать_вручную") }}

</template>
<script>
import qrcode from 'qrcode.vue'
import { mapState, mapActions } from 'vuex'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import store from '../../store/index'

export default {
  components: {
    qrcode,
  },

  mixins: [billsAndCoinsMixin],

  data: () => ({
    paymentProcessedImageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_confirmed_81ex.svg',
      light: '/static/illustrations/rally/light/undraw_confirmed_81ex.svg',
    },

    paymentErrorImageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_access_denied_re_awnf.svg',
      light: '/static/illustrations/rally/light/undraw_access_denied_re_awnf.svg',
    },

    ticketErrorImageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_access_denied_6w73.svg',
      light: '/static/illustrations/rally/light/undraw_access_denied_6w73.svg',
    },

    showCorners: false,
    paymentProcessedDialog: false,
    paymentErrorDialog: false,
    ticketErrorDialog: false,
    status: null,
    paymentResult: null,
    timeout: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      data: state => state.kaspiPay.data,
    }),

    total() {
      return this.getNumberFromBillsAndCoins(this.data.ticket.amounts.total)
    },

    qrToken() {
      return this.data.qrToken
    },

    showClose() {
      return this.status === 'TICKET_REGISTERED' || this.status === 'TICKET_ERROR'
    },
  },

  mounted() {
    this.startPaymentStatus()
  },

  methods: {
    ...mapActions({
      paymentStatus: 'kaspiPay/paymentStatus',
      closePaymentStatus: 'kaspiPay/closePaymentStatus',
      clearData: 'kaspiPay/clearData',
      showSnackbar: 'tools/showSnackbar',
    }),

    startPaymentStatus() {
      this.paymentStatus({
        paymentId: this.data.id,
        messageCallback: (event) => this.processPaymentStatus(event),
        errorCallback: (event) => this.processPaymentStatusError(event),
      })
    },

    processPaymentStatus(event) {
      if (event && event.data) {
        const data = JSON.parse(event.data)
        this.status = data.status
        if (data.status === 'PAYMENT_PROCESSING') {
          this.showCorners = true
        } else if (data.status === 'PAYMENT_PROCESSED') {
          this.paymentResult = data
          this.paymentProcessedDialog = true
        } else if (data.status === 'TICKET_REGISTERED') {
          this.closePaymentStatus()
          this.paymentResult = data
          this.paymentProcessedDialog = true
        } else if (data.status === 'TICKET_ERROR') {
          this.closePaymentStatus()
          this.paymentResult = data
          this.paymentProcessedDialog = true
        } else if (data.status === 'PAYMENT_EXPIRED') {
          this.showSnackbar({ message: this.$t('срок_платежа_истек') })
          this.$router.push('/draft')
        } else if (data.status === 'PAYMENT_ERROR' || data.status === 'PAYMENT_CANCELLED') {
          this.paymentErrorDialog = true
        }
      }
    },

    processPaymentStatusError() {
      this.closePaymentStatus()
      this.showCorners = false
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.startPaymentStatus()
      }, 3000)
    },

    closePaymentProcessedDialog() {
      if (this.status === 'TICKET_REGISTERED') {
        if (!this.$isCordova()) {
          this.showSnackbar({ message: this.$t('чек_успешно_фискализирован') })
        }
        this.$router.push(`/print/${this.cashRegister.id}/${this.paymentResult.receipt.id}`)
      } else if (this.status === 'TICKET_ERROR') {
        this.paymentProcessedDialog = false
        this.ticketErrorDialog = true
      }
    },

    closePaymentErrorDialog() {
      this.$router.push('/draft')
    },

    closeTicketErrorDialog() {
      this.$router.push('/draft')
    },
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.kaspiPay.data) {
      next()
    } else {
      next('/')
    }
  },

  beforeRouteLeave(to, from, next) {
    this.closePaymentStatus()
    this.clearData()
    next()
  },
}
</script>
<style lang="stylus">
.kaspi-corners
  display inline-table
  padding 10px
.kaspi-corners-show
  background:
    linear-gradient(to right, #F14834 4px, transparent 4px) 0 0,
    linear-gradient(to right, #F14834 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #F14834 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #F14834 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #F14834 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #F14834 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #F14834 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #F14834 4px, transparent 4px) 100% 100%
  background-repeat no-repeat
  background-size 30px 30px
.kaspi-qrcode
  position absolute
  margin-top 70px
.qrcode
  display inline-block
  padding 8px 8px 1px 8px
</style>
