import i18n from '../i18n/index'

export default {

  data: () => ({
    dictionary: {

      unitType: {
        PIECE: {
          _deprecated: 'шт',
        },
        KILOGRAM: {
          _deprecated: 'кг',
        },
        LITER: {
          _deprecated: 'литр',
        },
        GRAM: {
          _deprecated: 'г',
        },
        TON: {
          _deprecated: 'тонн.',
        },
        METER: {
          _deprecated: 'м',
        },
        SQUARE_METER: {
          _deprecated: 'м2',
        },
        CUBIC_METER: {
          _deprecated: 'м3',
        },
        HECTARE: {
          _deprecated: 'га',
        },
        RUNNING_METER: {
          _deprecated: 'м.пог.',
        },
        PACKAGE: {
          _deprecated: 'упак.',
        },
        PAIR: {
          _deprecated: 'пара',
        },
        ONE_SERVICE: {
          _deprecated: 'одн.усл.',
        },
        HOUR: {
          _deprecated: 'час',
        },
        DAY: {
          _deprecated: 'сутки',
        },
        MONTH: {
          _deprecated: 'месяц',
        },
      },

      operationType: {
        OPERATION_SELL: {
          _deprecated: 'Продажа',
          icon: 'mdi-account-cash',
          negative: false,
          order: 1,
        },
        OPERATION_SELL_RETURN: {
          _deprecated: 'Возврат',
          icon: 'mdi-cash-refund',
          negative: true,
          order: 2,
        },
        OPERATION_BUY: {
          _deprecated: 'Покупка',
          icon: 'mdi-cash-multiple',
          negative: true,
          order: 3,
        },
        OPERATION_BUY_RETURN: {
          _deprecated: 'Возврат покупки',
          icon: 'mdi-cash-multiple',
          negative: false,
          order: 4,
        },
        MONEY_PLACEMENT_DEPOSIT: {
          _deprecated: 'Внесение денег',
          icon: 'mdi-cash-plus',
          negative: false,
          order: 5,
        },
        MONEY_PLACEMENT_WITHDRAWAL: {
          _deprecated: 'Изъятие денег',
          icon: 'mdi-cash-minus',
          negative: true,
          order: 6,
        },
      },

      paymentType: {
        PAYMENT_CASH: {
          _deprecated: 'Наличные',
          _deprecated_short: 'нал.',
          order: 1,
        },
        PAYMENT_CARD: {
          _deprecated: 'Карта',
          _deprecated_short: 'карт.',
          order: 2,
        },
        PAYMENT_CREDIT: {
          _deprecated: 'Кредит',
          _deprecated_short: 'кред.',
          order: 3,
        },
        PAYMENT_TARE: {
          _deprecated: 'Тара',
          _deprecated_short: 'тар.',
          order: 4,
        },
      },

      discountMarkupType: {
        DISCOUNT_PERCENTAGE: {
          _deprecated: 'Скидка в процентах',
          _deprecated_short: 'Скидка',
          suffix: '%',
        },
        DISCOUNT_CURRENCY: {
          _deprecated: 'Скидка в тенге',
          _deprecated_short: 'Скидка',
          suffix: '₸',
        },
        MARKUP_PERCENTAGE: {
          _deprecated: 'Наценка в процентах',
          _deprecated_short: 'Наценка',
          suffix: '%',
        },
        MARKUP_CURRENCY: {
          _deprecated: 'Наценка в тенге',
          _deprecated_short: 'Наценка',
          suffix: '₸',
        },
      },

      domainType: {
        DOMAIN_TRADING: {
          _deprecated: 'Торговая сфера',
          code: 0,
        },
        DOMAIN_SERVICES: {
          _deprecated: 'Сфера услуг',
          code: 1,
        },
        DOMAIN_GASOIL: {
          _deprecated: 'Сфера обеспечения нефтепродуктами',
          code: 2,
        },
        DOMAIN_HOTELS: {
          _deprecated: 'Отели и рестораны',
          code: 3,
        },
        DOMAIN_TAXI: {
          _deprecated: 'Такси',
          code: 4,
        },
        DOMAIN_PARKING: {
          _deprecated: 'Стоянки',
          code: 5,
        },
      },

      taxType: {
        TAX_12: {
          rate: 12,
        },
        TAX_8: {
          rate: 8,
        },
        TAX_0: {
          rate: 0,
        },
        TAX_NONE: {
          rate: -1,
        },
      },

      taxationType: {
        STS: {
          _deprecated: 'Специальный налоговый режим на основе упрощенной декларации',
          code: 100,
        },
        RTS: {
          _deprecated: 'Общеустановленный режим налогообложения',
          code: 101,
        },
        TRFF: {
          _deprecated: 'Специальный налоговый режим для крестьянских или фермерских хозяйств',
          code: 102,
        },
        TRBP: {
          _deprecated: 'Специальный налоговый режим на основе патента',
          code: 103,
        },
      },

      businessNameType: {
        ORGANIZATION: {
          _deprecated: 'Организация',
        },
        SALES_POINT: {
          _deprecated: 'Торговая точка',
        },
      },

      timezoneType: {
        '+05:00': {
          _deprecated: 'Атырау (+05:00)',
        },
        '+06:00': {
          _deprecated: 'Нур-Султан (+06:00)',
        },
      },

      localeType: {
        ru: {
          title: 'Русский',
        },
        kk: {
          title: 'Қазақша',
        },
      },
    },
  }),

  computed: {
    unitTypes() {
      return Object.entries(this.dictionary.unitType).map(item => ({ title: i18n.t(`unitType.${item[0]}`), value: item[0] }))
    },

    operationTypes() {
      return Object.entries(this.dictionary.operationType).map(item => ({
        title: i18n.t(`operationType.${item[0]}`), icon: item[1].icon, negative: item[1].negative, value: item[0],
      }))
    },

    discountMarkupTypes() {
      return Object.entries(this.dictionary.discountMarkupType).map(item => ({
        title: i18n.t(`discountMarkupType.${item[0]}`), suffix: item[1].suffix, value: item[0],
      }))
    },

    domainTypes() {
      return Object.entries(this.dictionary.domainType).map(item => ({
        title: i18n.t(`domainType.${item[0]}`), value: item[0],
      }))
    },

    taxationTypes() {
      return Object.entries(this.dictionary.taxationType).map(item => ({
        title: i18n.t(`taxationType.${item[0]}`), value: item[0],
      }))
    },

    taxTypes() {
      return Object.entries(this.dictionary.taxType).map(item => ({
        title: i18n.t(`taxType.${item[0]}`), rate: item[1].rate, value: item[0],
      }))
    },

    businessNameTypes() {
      return Object.entries(this.dictionary.businessNameType).map(item => ({
        title: i18n.t(`businessNameType.${item[0]}`), value: item[0],
      }))
    },

    timezoneTypes() {
      return Object.entries(this.dictionary.timezoneType).map(item => ({
        title: i18n.t(`timezoneType.${item[0]}`), value: item[0],
      }))
    },

    localeTypes() {
      return Object.entries(this.dictionary.localeType).map(item => ({
        title: item[1].title, value: item[0],
      }))
    },
  },
}
