<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("платежные_системы") }}

    v-main(flat)
      v-container(style="max-width: 450px")
        v-card(class="mb-6")
          v-img.cursor-pointer(src="/static/kaspi-pay-banner.png" @click="$router.push('/kkm/pay/kaspi')")

          v-card-title {{ $t("KASPI_PAY") }}
            v-row(align="center" justify="end")
              v-chip(v-if="kaspiPayShowNew" small class="mr-2" color="red" text-color="white") NEW
              v-chip(v-else small class="mr-2" :color="kaspiPayConnected ? 'success' : 'gray'" text-color="white") {{ kaspiPayConnected ? $t("подключено").toUpperCase() : $t("не_подключено").toUpperCase() }}
          v-card-subtitle {{ $t("прием_qr_платежей") }}
          v-card-text {{ $t("вместе_с_kaspi_pay_rekassa_30_позволяет_dotdotdot") }}

          v-divider.mx-4

          v-card-actions
            v-btn(color="primary" text to="/kkm/pay/kaspi") {{ kaspiPayConnected ? $t("настройки") : $t("подробнее") }}

        //- v-card(class="mb-6")
        //-   v-img.cursor-pointer(src="/static/halyk-pos-banner.png" @click="$router.push('/kkm/pos/halyk')")

        //-   v-card-title {{ $t("HALYK_POS") }}
        //-     v-row(align="center" justify="end")
        //-       v-chip(v-if="halykPosShowNew" small class="mr-2" color="red" text-color="white") NEW
        //-       v-chip(v-else small class="mr-2" :color="halykPosConnected ? 'success' : 'gray'" text-color="white") {{ halykPosConnected ? $t("подключено").toUpperCase() : $t("не_подключено").toUpperCase() }}
        //-   v-card-subtitle {{ $t("прием_бесконтактных_платежей") }}
        //-   v-card-text {{ $t("принимайте_бесконтактные_платежи_по_любым_картам_с_приложением_halyk_pos_dotdotdot") }}

        //-   v-divider.mx-4

        //-   v-card-actions
        //-     v-btn(color="primary" text to="/kkm/pos/halyk") {{ halykPosConnected ? $t("настройки") : $t("подробнее") }}

        v-card(class="mb-6")
          v-img.cursor-pointer(src="/static/alfa-pay-banner.png" @click="$router.push('/kkm/pay/alfa')")

          v-card-title {{ $t("ALFA_PAY") }}
            v-row(align="center" justify="end")
              v-chip(v-if="alfaPayShowNew" small class="mr-2" color="red" text-color="white") NEW
              v-chip(v-else small class="mr-2" :color="alfaPayConnected ? 'success' : 'gray'" text-color="white") {{ alfaPayConnected ? $t("подключено").toUpperCase() : $t("не_подключено").toUpperCase() }}
          v-card-subtitle {{ $t("прием_бесконтактных_платежей") }}
          v-card-text {{ $t("принимайте_бесконтактные_платежи_по_любым_картам_с_приложением_alfa_pay_dotdotdot") }}

          v-divider.mx-4

          v-card-actions
            v-btn(color="primary" text to="/kkm/pay/alfa") {{ alfaPayConnected ? $t("настройки") : $t("подробнее") }}

</template>
<script>
import { mapState } from 'vuex'

export default {

  data: () => ({
    kaspiPayShowNew: true,
    kaspiPayConnected: null,
    halykPosShowNew: true,
    halykPosConnected: null,
    alfaPayShowNew: true,
    alfaPayConnected: null,
  }),

  computed: {
    ...mapState({
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),
  },

  created() {
    this.kaspiPayShowNew = this.configuration.kaspi === undefined
    this.kaspiPayConnected = this.configuration.kaspi && this.configuration.kaspi.kaspiPayConnected
    this.halykPosShowNew = this.configuration.halyk === undefined
    this.halykPosConnected = this.configuration.halyk && this.configuration.halyk.halykPosConnected
    this.alfaPayShowNew = this.configuration.alfa === undefined
    this.alfaPayConnected = this.configuration.alfa && this.configuration.alfa.alfaPayConnected

    localStorage.setItem('rekassa.kz-menu-feature-new-payments-seen', 'true')
  },

}
</script>
