<template lang="pug">
v-bottom-sheet.pageLayout(:value="value" @input="$emit('input', $event)"
  Zinset scrollable content-class="pageLayout cartBottomDialog"
  :fullscreen="maximize" Zoverlay-color="primary" eager)
    slot(name="header")
    //- v-app-bar.pr-0(app dark Zdense flat Zhide-on-scroll clipped-left clipped-right)
      slot(name="header")
        v-card-text.pb-0.headline.d-flex.justify-space-between(v-if="!hideHeader") {{title}}
          v-btn(color="primary" icon @click="$emit('input',false)")
            v-icon mdi-close
    v-card(color="dark")
      v-card-text.pa-2
        slot
      //- v-card-actions.px-2.pt-0(v-if="$slots.default")
        slot(name="footer")
    v-footer.pa-2(app Zabsolute inset bottom Zpadless Zoutlined fixed color="transparent")
      slot(name="footer")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    maximize: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data: () => ({
  }),

  methods: {

  },
}
</script>
<style lang="stylus">

.theme--dark
  .pageLayout.cartBottomDialog.v-dialog > .theme--dark.v-card.v-sheet
      background: transparent
      bottom 65px
      heigh auto

.pageLayout.v-dialog:not(.v-dialog--fullscreen)
  max-height 83%

</style>
