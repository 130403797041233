<template lang="pug">
t-list-item(v-if="isNotFocused && !editable" :title="label" @click="isNotFocused = false" ezcar v-bind="$attrs")
  template(v-if="$attrs['value']") {{$attrs['value']}} {{$attrs['suffix']}}
  template(v-else): v-icon(color="grey") mdi-plus-circle-outline
v-list-item(v-else)
  t-input(v-bind="$attrs" v-on="$listeners" :prefix="label" autofocus @blur="isNotFocused = true")

  //- v-list.pt-0
    v-list-group(Zprepend-icon='mdi-cash' ZappendIcon="mdi-cash" Zno-action)
      template(v-slot:activator)
        v-list-item-avatar: v-icon mdi-cash
        v-list-item-content
          v-list-item-title Наличные
        v-list-item-action.pr-2: v-list-item-title.ezcar.headline 2 800 ₸

      v-list-item
        v-text-field.text-right.ezcar.headline(hide-details filled value="2800" suffix="₸")
      v-list-item
        v-tabs.mb-2(Zfixed-tabs dense grow hide-slider :show-arrows="false" mobile-breakpoint="1000")
          v-tab.pr-0: v-btn(block color="primary" Zoutlined) 2800 ₸
          //- v-tab.pr-0: v-btn(block color="primary" Zoutlined) 3000 ₸
          v-tab.pr-0: v-btn(block color="primary" outlined) 4000 ₸
          v-tab.Zpr-0: v-btn(block color="primary" outlined) 5000 ₸
</template>
<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: undefined,
    },
  },

  data: () => ({
    isNotFocused: true,
  }),

  computed: {
    // isEmpty() {
    //   return this.empty && !this.totalSum
    // },
  },

  watch: {},

  methods: {
    toggle() {
      this.isNotFocused = !this.isNotFocused
    },
    // ...mapActions({
    //   checkout: 'tjtheme/checkout',
    // }),
    // hasSecondButton() {
    //   return !!this.$slots.default
    // },
  },
}
</script>
<style lang="stylus"></style>
