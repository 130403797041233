<template lang="pug">
div SLIDES
  v-tabs(v-model='tab'
    centered
    dense
    grow
    dark)
    v-tabs-slider
    v-tab(href='#tab-1'): v-icon mdi-view-dashboard
    v-tab(href='#tab-2'): v-icon mdi-card-text-outline
    v-tab(href='#tab-3'): v-icon mdi-calculator
    v-tab(href='#tab-4'): v-icon mdi-basket
    v-tab(href='#tab-5'): v-icon mdi-history
  slot
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    preNewItem: {
      type: Object,
      default: null,
      required: false,
    },
  },

  data: () => ({
    tab: '1',
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),

  computed: {
    ...mapState({
      positionsLoading: (state) => state.positions.loading,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
