<template lang="pug">
div.flex
  v-container(style="max-width: 450px")
    v-row.d-print-none
      v-col.pa-4(align="center")
        v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

    v-row
      v-col(align="center")
        a.title(@click.prevent="openPartnerLink()") {{ $t("купить_принтер_со_скидкой") }}

    v-row.d-print-none
      v-col
        v-list(flat).gray-background-list
          v-subheader(v-if="$isCordova()") {{ $t("выбор_принтера_для_печати") }}
          v-list-item-group(v-if="$isCordova()")
            v-list-item(@click="openSelectBluetoothPrinterDialog()")
              v-list-item-icon
                v-icon(:color="printer.connectionType === 'bluetooth' ? 'success' : null") mdi-bluetooth
              v-list-item-content
                v-list-item-title Bluetooth
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ printer.bluetooth.name }}
            v-list-item(v-if="$isAndroid()" @click="openSelectWifiPrinterDialog()")
              v-list-item-icon
                v-icon(:color="printer.connectionType === 'wifi' ? 'success' : null")  mdi-wifi
              v-list-item-content
                v-list-item-title {{ $t("wi-fi_сетевой") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ printer.wifi.name }}

          v-subheader {{ $t("настройки_принтера") }}
          v-list-item(@click="openSelectRollWidthDialog()")
            v-list-item-content
              v-list-item-title {{ $t("ширина_ленты") }}
            v-list-item-action
              v-list-item-action-text.subtitle-1 {{ dictionary.rollWidth[settings.rollWidth].title }}
          v-list-item(v-if="$isCordova()" @click="openScrollingLinesDialog()")
            v-list-item-content
              v-list-item-title {{ $t("кол-во_строк_промотки") }}
            v-list-item-action
              v-list-item-action-text.subtitle-1 {{ settings.scrollingLines }} {{ $t("стр") }}
          v-list-item(v-if="$isCordova()" @click="openSelectEncodingDialog()")
            v-list-item-content
              v-list-item-title {{ $t("кодировка") }}
            v-list-item-action
              v-list-item-action-text.subtitle-1 {{ dictionary.encoding[settings.encoding].title }}
          v-list-item(v-if="$isCordova() && $isAndroid()" @click="openSelectQrTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("печать_qr_кода") }}
            v-list-item-action
              v-list-item-action-text.subtitle-1 {{ dictionary.qrType[settings.qrType].title }}
          v-list-item(v-if="$isCordova()")
            v-list-item-content
              v-list-item-title {{ $t("поддержка_спецсимволов") }}
            v-list-item-action
              v-switch(color="teal" v-model="settings.specialSymbols" @change="specialSymbolsChanged()")

    v-row.d-print-none
      v-col(align="center")
        v-btn(color="primary" block :loading="testPagePrinting" @click="printTestReceipt()") {{ $t("пробная_печать_чека") }}

    v-dialog(v-model="selectBluetoothPrinterDialog" scrollable max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_bluetooth_принтер") }}
        v-card-text.pt-0
          v-container.py-0
            template(v-if="$isAndroid()")
              v-row(v-if="bluetoothPrinterList && bluetoothPrinterList.length > 0")
                v-col.py-0
                  v-radio-group.mb-4(column v-model="selectedValue" :error-messages="bluetoothPrinterRadioError")
                    v-radio(:label="`${p.name} (${p.address})`" :value="p.address" v-for="(p, $index) in bluetoothPrinterList" :key="'p_' + $index")

                v-btn(block text color="primary" @click="openBluetoothSettings()") {{ $t("bluetooth_настройки") }}

              v-row(v-if="bluetoothPrinterList && bluetoothPrinterList.length <= 0")
                v-col.pt-0
                  h2.subtitle-1.mb-4 {{ $t("нет_подключенного_принтера") }}
                  h3.subtitle-2(v-html="$t('перейдите_в_bluetooth_dotdotdot')")

                  v-btn.mt-6(block color="primary" @click="openBluetoothSettings()") {{ $t("bluetooth_настройки") }}

            template(v-else)
              v-row
                v-col.py-0
                  v-radio-group(v-if="bluetoothPrinterList && bluetoothPrinterList.length > 0" column v-model="selectedValue" :disabled="bluetoothPrinterListLoading" :error-messages="bluetoothPrinterRadioError")
                    v-radio(:label="`${p.name} (${p.address.split('-')[4]})`" :value="p.address" v-for="(p, $index) in bluetoothPrinterList" :key="'p_' + $index")
                  h2.subtitle-1.mb-4.mt-3(v-if="!bluetoothPrinterListLoading && bluetoothPrinterList && bluetoothPrinterList.length <= 0") {{ $t("принтеры_не_найдены") }}

                v-btn.mt-6(block text :loading="bluetoothPrinterListLoading" color="primary" @click="refreshBluetoothPrinterList()") {{ $t("обновить_список") }}
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectBluetoothPrinterDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectBluetoothPrinter()" :disabled="selectBluetoothPrinterButtonDisabled") {{ $t("сохранить") }}

    v-dialog(v-model="selectWifiPrinterDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("укажите_адрес_ip_wi-fi_принтера") }}
        v-card-text
          v-container
            v-form(ref="printerForm" @submit.prevent="saveWifiPrinter()")
              v-row
                v-col(cols="12" sm="8")
                  v-text-field(v-model="selectedValue" :rules="[rules.ip]" :label="$t('адрес_ip')" validate-on-blur autocomplete="off" placeholder="192.168.1.X" clearable)
                v-col(cols="12" sm="4")
                  v-text-field(v-model="selectedValueAddon" type="number" :rules="[rules.required]" :label="$t('порт')" validate-on-blur autocomplete="off" placeholder="9100" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectWifiPrinterDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveWifiPrinter()") {{ $t("сохранить") }}

    v-dialog(v-model="selectRollWidthDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_ширину_ленты") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:label="dictionary.rollWidth[r].title" :value="r" v-for="(r, $index) in rollWidthList" :key="'r_' + $index")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectRollWidthDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectRollWidth()") {{ $t("выбрать") }}

    v-dialog(v-model="selectScrollingLinesDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_кол-во_строк_промотки") }}
        v-card-text
          v-container
            v-form(ref="scrollingLinesForm" @submit.prevent="selectScrollingLines()")
              v-text-field(v-model="selectedValue" type="number" :rules="[rules.required]" label="Кол-во строк" validate-on-blur autocomplete="off" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectScrollingLinesDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectScrollingLines()") {{ $t("сохранить") }}

    v-dialog(v-model="selectEncodingDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_кодировку") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:label="dictionary.encoding[e].title" :value="e" v-for="(e, $index) in encodingList" :key="'e_' + $index")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectEncodingDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectEncoding()") {{ $t("выбрать") }}

    v-dialog(v-model="selectQrTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_способ_печати_qr_кода") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:label="dictionary.qrType[qr].title" :value="qr" v-for="(qr, $index) in qrSupportList" :key="'qr_' + $index")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="selectQrTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectQrType()") {{ $t("выбрать") }}

  re-receipt.d-none.d-print-block(:ticket="demoTicket" :cashRegister="demoCashRegister" :organization="demoOrganization" :class="rollWidthClass" :ticketLocale="ticketLocale")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import i18n from '../../i18n/index'
import Receipt from '../ticket/parts/Receipt.vue'

export default {
  components: {
    're-receipt': Receipt,
  },

  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_printing_invoices_5r4r.svg',
      light: '/static/illustrations/rally/light/undraw_printing_invoices_5r4r.svg',
    },

    demoCashRegister: {
      model: 'reKassa 3.0',
      serialNumber: '1234567890',
      registrationNumber: '12345',
      status: 'TRIAL',
      fdo: 'TTK',
      fdoId: '12345',
      pos: {
        title: 'ТОО «reKassa 3.0»',
        address: 'пр. Мәңгілік Ел, С4.6',
      },
    },
    demoOrganization: {
      businessId: '123456789012',
      businessName: 'ТОО «reKassa 3.0»',
    },
    demoTicket: {
      id: 1,
      command: 'COMMAND_TICKET',
      ticketNumber: '12345',
      qrCode: 'https://rekassa.kz',
      offlineTicketNumber: null,
      shiftNumber: 123,
      shiftDocumentNumber: 123,
      demo: true,
      operator: {
        code: 123,
      },
      data: {
        ticket: {
          items: [
            {
              type: 'ITEM_TYPE_COMMODITY',
              commodity: {
                sum: {
                  bills: '500',
                  coins: 0,
                },
                name: 'Позиция',
                price: {
                  bills: '500',
                  coins: 0,
                },
                quantity: 1000,
                auxiliary: [
                  {
                    key: 'UNIT_TYPE',
                    value: 'PIECE',
                  },
                ],
              },
            },
          ],
          amounts: {
            taken: {
              bills: '500',
              coins: 0,
            },
            total: {
              bills: '500',
              coins: 0,
            },
            change: {
              bills: '0',
              coins: 0,
            },
          },
          dateTime: {
            date: {
              day: parseInt(moment().format('D'), 10),
              month: parseInt(moment().format('M'), 10),
              year: parseInt(moment().format('YYYY'), 10),
            },
            time: {
              hour: parseInt(moment().format('HH'), 10),
              minute: parseInt(moment().format('mm'), 10),
              second: parseInt(moment().format('ss'), 10),
            },
          },
          operator: {
            code: 123,
          },
          payments: [
            {
              sum: {
                bills: '500',
                coins: 0,
              },
              type: 'PAYMENT_CASH',
            },
          ],
          operation: 'OPERATION_SELL',
        },
      },
      cancelledBy: null,
      offline: false,
      extra: {
        receipts: [],
      },
    },

    dictionary: {
      rollWidth: {
        58: {
          title: '58mm',
        },
        80: {
          title: (window.cordova !== undefined ? '80mm' : '80mm / A4'),
        },
      },
      encoding: {
        cp866: {
          title: 'CP866',
        },
        'Windows-1251': {
          title: 'Windows-1251',
        },
        'Chuck-Norris': {
          title: 'Chuck Norris',
        },
      },
      qrType: {
        escpos: {
          title: i18n.t('средствами_принтера'),
        },
        image: {
          title: i18n.t('изображением'),
        },
      },
    },

    printer: null,
    settings: null,

    selectedValue: null,
    selectedValueAddon: null,

    selectBluetoothPrinterDialog: false,
    bluetoothPrinterList: [],
    bluetoothPrinterRadioError: [],

    selectWifiPrinterDialog: false,

    selectScrollingLinesDialog: false,

    selectRollWidthDialog: false,
    rollWidthList: [
      58, 80,
    ],

    selectEncodingDialog: false,
    encodingList:
      window.cordova !== undefined && window.device.platform === 'Android'
        ? ['cp866', 'Windows-1251', 'Chuck-Norris']
        : ['cp866', 'Windows-1251'],
    selectQrTypeDialog: false,
    qrSupportList: [
      'escpos', 'image',
    ],

    testPagePrinting: false,
    selectBluetoothPrinterButtonDisabled: false,

    ticketLocale: i18n.locale,

    bluetoothPrinterListLoading: false,
  }),

  computed: {
    ...mapState({
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    rollWidthClass() {
      return this.settings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },
  },

  created() {
    this.printer = this.$store.state.printer.printer
    this.settings = this.$store.state.printer.settings

    if (this.$isAndroid()) {
      document.addEventListener('resume', () => {
        this.initBluetoothPrinterList()
      }, false)
    }
  },

  beforeDestroy() {
    if (this.$isAndroid()) {
      window.removeEventListener('resume', () => {
        this.initBluetoothPrinterList()
      }, false)
    }
  },

  methods: {
    ...mapActions({
      setPrinter: 'printer/setPrinter',
      setSettings: 'printer/setSettings',
      getBluetoothPrinterList: 'printer/getBluetoothPrinterList',
      openBluetoothSettings: 'printer/openBluetoothSettings',
      printReceipt: 'printer/printReceipt',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    openPartnerLink() {
      this.$openLink('https://link.rekassa.kz/buy-printer', '_system')
      this.firebaseAnalyticsLogEvent({ eventName: 're_partner_atb_clicked' })
    },

    initBluetoothPrinterList() {
      this.getBluetoothPrinterList().then((printerList) => {
        if (printerList.length === 0) {
          this.printer.bluetooth = {
            name: null,
            address: null,
          }
          this.selectBluetoothPrinterButtonDisabled = true
        } else {
          this.bluetoothPrinterList = printerList
          this.selectBluetoothPrinterButtonDisabled = false
        }
      }, () => {
        // this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },
    openSelectBluetoothPrinterDialog() {
      if (this.$isAndroid()) {
        this.initBluetoothPrinterList()
      } else {
        this.refreshBluetoothPrinterList()
      }
      this.selectedValue = this.printer.bluetooth.address
      this.bluetoothPrinterRadioError = []
      this.selectBluetoothPrinterDialog = true
    },

    selectBluetoothPrinter() {
      const selectedPrinter = this.bluetoothPrinterList.find((p) => p.address === this.selectedValue)
      if (selectedPrinter) {
        this.printer.connectionType = 'bluetooth'
        this.printer.bluetooth = selectedPrinter
        this.setPrinter(this.printer)
        this.selectBluetoothPrinterDialog = false
      } else {
        this.bluetoothPrinterRadioError = this.$t('выберите_принтер')
      }
    },

    openSelectWifiPrinterDialog() {
      this.selectedValue = this.printer.wifi.address
      this.selectedValueAddon = this.printer.wifi.port
      this.selectWifiPrinterDialog = true
    },
    saveWifiPrinter() {
      if (this.$refs.printerForm.validate()) {
        this.printer.connectionType = 'wifi'
        this.printer.wifi.address = this.selectedValue
        this.printer.wifi.port = this.selectedValueAddon
        this.printer.wifi.name = `${this.selectedValue}:${this.selectedValueAddon}`
        this.setPrinter(this.printer)
        this.selectWifiPrinterDialog = false
      }
    },

    openSelectRollWidthDialog() {
      this.selectedValue = this.settings.rollWidth
      this.selectRollWidthDialog = true
    },
    selectRollWidth() {
      this.settings.rollWidth = this.selectedValue
      this.setSettings(this.settings)
      this.selectRollWidthDialog = false
    },

    openScrollingLinesDialog() {
      this.selectedValue = this.settings.scrollingLines
      this.selectScrollingLinesDialog = true
    },
    selectScrollingLines() {
      if (this.$refs.scrollingLinesForm.validate()) {
        this.settings.scrollingLines = this.selectedValue
        this.setSettings(this.settings)
        this.selectScrollingLinesDialog = false
      }
    },

    openSelectEncodingDialog() {
      this.selectedValue = this.settings.encoding
      this.selectEncodingDialog = true
    },
    selectEncoding() {
      this.settings.encoding = this.selectedValue
      this.setSettings(this.settings)
      this.selectEncodingDialog = false
    },

    openSelectQrTypeDialog() {
      this.selectedValue = this.settings.qrType
      this.selectQrTypeDialog = true
    },
    selectQrType() {
      this.settings.qrType = this.selectedValue
      this.setSettings(this.settings)
      this.selectQrTypeDialog = false
    },

    specialSymbolsChanged() {
      this.setSettings(this.settings)
    },

    refreshBluetoothPrinterList() {
      this.bluetoothPrinterListLoading = true
      this.selectBluetoothPrinterButtonDisabled = true
      this.getBluetoothPrinterList(this.printer && this.printer.bluetooth ? this.printer.bluetooth : null).then((printerList) => {
        if (printerList.length === 0) {
          this.printer.bluetooth = {
            name: null,
            address: null,
          }
          this.selectBluetoothPrinterButtonDisabled = true
          this.bluetoothPrinterListLoading = false
        } else {
          this.bluetoothPrinterList = printerList
          this.selectBluetoothPrinterButtonDisabled = false
          this.bluetoothPrinterListLoading = false
        }
      }, (error) => {
        this.bluetoothPrinterListLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    printTestReceipt() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
    },

    printCordova() {
      this.testPagePrinting = true

      const printCmds = []
      printCmds.push('#align_center#')
      printCmds.push('ТОО «reKassa 3.0»')
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин', this.ticketLocale)} ${this.demoOrganization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push('пр. Мәңгілік Ел, С4.6')
      printCmds.push('#new_line#')
      printCmds.push('#new_line#')

      printCmds.push('#qr_code#')
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(`${this.$t('operationType.OPERATION_SELL', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push('123')
      printCmds.push(`${this.$t('смена', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push('123')

      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('дата', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(new Date()).format('DD-MM-YYYY'))
      printCmds.push(`${this.$t('время', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(new Date()).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('фп', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push('12345')
      printCmds.push(`${this.$t('кассир', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push('123')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('рнм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('12345')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('1234567890')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('ккм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('reKassa 3.0')
      printCmds.push('#new_line#')

      printCmds.push('#line#')
      printCmds.push('#align_center#')
      printCmds.push(this.$t('нефискальный_чек', this.ticketLocale).toUpperCase())
      printCmds.push('#new_line#')
      printCmds.push('#line#')

      printCmds.push('#align_left#')
      printCmds.push('1. Позиция')
      printCmds.push('#new_line#')

      printCmds.push(`1.0${this.$t('unitType.PIECE', this.ticketLocale)} x 500.00₸`)
      printCmds.push('#full_width#')
      printCmds.push('500.00₸')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('стоимость', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('500.00₸')
      printCmds.push('#new_line#')
      printCmds.push('#line#')

      printCmds.push(this.$t('итого', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('500.00₸')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('paymentType.PAYMENT_CASH', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('500.00₸')
      printCmds.push('#new_line#')

      printCmds.push('#line#')
      printCmds.push('#align_center#')
      printCmds.push(this.$t('fdo.TTK.title', this.ticketLocale))
      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('фиск_признак', this.ticketLocale)}: 12345`)
      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('код_ккм_кгд', this.ticketLocale)}: 12345`)
      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('для_проверки_чека_зайдите_на_сайт', this.ticketLocale)}: `)
      printCmds.push(this.$t('fdo.TTK.url', this.ticketLocale))
      printCmds.push('#new_line#')

      if (this.preferences.advertisingText) {
        printCmds.push('#line#')
        printCmds.push('#align_left#')
        printCmds.push(this.preferences.advertisingText)
        printCmds.push('#new_line#')
      }

      const payload = {
        cmds: printCmds,
        qrCode: 'https://rekassa.kz',
      }

      this.printReceipt(payload).then(() => {
        setTimeout(() => {
          this.testPagePrinting = false
        }, 500)
      }, (error) => {
        if (error && error.message === 'printerNotSelected') {
          this.showSnackbar({ message: this.$t('прежде_чем_напечатать_чек_необходимо_выбрать_принтер') })
        }
        this.testPagePrinting = false
      })
    },
  },
}
</script>
