<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("профиль") }}

    v-main(flat)
      v-container(style="max-width: 450px")
        v-row.my-3
          v-col(align="center")
            v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="50%" eager)

        v-row
          v-col
            v-list.gray-background-list
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("имя") }}
                v-list-item-action
                  v-list-item-action-text.title
                    v-btn.mr-1(icon @click="editName()" :loading="saveNameLoading")
                      v-icon mdi-pencil
                    | {{ user.name }}

              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("номер_телефона") }}
                v-list-item-action
                  v-list-item-action-text.title {{ user.phone | phone }}

              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("дата_регистрации") }}
                v-list-item-action
                  v-list-item-action-text.title {{ user.registrationDate | moment("DD-MM-YYYY") }}

    v-dialog(v-model="nameDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_имя") }}
        v-card-text
          v-container
            v-form.mt-3(ref="nameForm" @submit.prevent="saveName()")
              v-text-field(:label="$t('имя')" v-model="nameInput" type="text" :rules="[rules.required]" autocomplete="off" maxlength="30" counter="30")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="nameDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveName()") {{ $t("сохранить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_develop_app_re_bi4i.svg',
      light: '/static/illustrations/rally/light/undraw_develop_app_re_bi4i.svg',
    },

    nameDialog: false,
    nameInput: null,
    saveNameLoading: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  methods: {
    ...mapActions({
      setName: 'auth/setName',
      showSnackbar: 'tools/showSnackbar',
    }),

    editName() {
      this.nameInput = this.user.name
      this.nameDialog = true
    },

    saveName() {
      if (this.$refs.nameForm.validate()) {
        this.saveNameLoading = true
        this.nameDialog = false
        this.setName({ name: this.nameInput }).then(() => {
          this.saveNameLoading = false
        }).catch(() => {
          this.saveNameLoading = false
        })
      }
    },
  },
}
</script>
