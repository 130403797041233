export default [
  {
    path: '/kassa',
    alias: '/',
    name: 'Kassa',
    component: () => import('T@/pages/KassaPage.vue'),
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('T@/pages/OrdersPage.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('T@/pages/ContactsPage.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('T@/pages/SettingsPage.vue'),
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('T@/pages/PricePage.vue'),
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import('T@/pages/AppsPage.vue'),
  },
  {
    path: '/apps/callbackauth',
    name: 'CallBackAuth',
    component: () => import('T@/pages/apps/authcallback/Page.vue'),
  },
  {
    path: '/apps/taxpassport',
    name: 'TaxPassport',
    component: () => import('T@/pages/apps/taxpassport/Page.vue'),
  },
  {
    path: '/taplink',
    name: 'Taplink',
    component: () => import('T@/pages/taplink/Page.vue'),
  },
  {
    path: '/apps/analytics',
    name: 'Analytics',
    component: () => import('T@/pages/AnalyticsPage.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('T@/pages/AboutPage.vue'),
  },
  // {
  //   path: '/kassa/payment',
  //   name: 'Payment',
  //   component: () => import('T@/pages/_tmp/PaymentPage.vue'),
  // },
  // {
  //   path: '/kassa/client',
  //   name: 'Client',
  //   component: () => import('T@/pages/_tmp/ClientPage.vue'),
  // },
  // {
  //   path: '/kassa/cart',
  //   name: 'Cart',
  //   component: () => import('T@/pages/_tmp/CartPage.vue'),
  // },
  {
    path: '/ui',
    name: 'UI',
    component: () => import('T@/pages/UIPage.vue'),
  },
  //   path: '',
  //   component: () => import('T@/pages/uidemo/slots/Router.vue'),
  {
    path: '/ui/slots/page',
    props: { app: true },
    component: () => import('T@/pages/uidemo/slots/Hello.vue'),
  },
  {
    path: '/ui/check',
    name: 'Check',
    props: { app: true },
    component: () => import('T@/components/cart/check/Check.vue'),
  },
  {
    path: '/ui/cart',
    name: 'Cart',
    props: { app: true },
    component: () => import('T@/components/cart/Cart.vue'),
  },
  {
    path: '/2gis',
    name: '2gis',
    props: { app: true },
    component: () => import('T@/pages/2gis/Map.vue'),
  },
  {
    path: '/ui/payment',
    props: { app: true },
    component: () => import('T@/components/cart/payment/Payment.vue'),
  },
  {
    path: '/ui/basket',
    props: { app: true },
    component: () => import('T@/pages/uidemo/BasketDemo.vue'),
  },
  {
    path: '/ui/list',
    props: { app: true },
    component: () => import('T@/pages/uidemo/TList.vue'),
  },
  // {
  //   path: '/payment',
  //   name: 'Payment',
  //   component: () => import('T@/components/cart/payment/Payment.vue'),
  // },
  {
    path: '/ui/slots/dialog',
    component: () => import('T@/pages/uidemo/slots/asDialog.vue'),
  },
  {
    path: '/ui/slots/component',
    name: 'Test2',
    component: () => import('T@/pages/uidemo/slots/asComponent.vue'),
  },
]
