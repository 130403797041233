import { render, staticRenderFns } from "./t-page-layout.vue?vue&type=template&id=102bb05e&lang=pug&"
import script from "./t-page-layout.vue?vue&type=script&lang=js&"
export * from "./t-page-layout.vue?vue&type=script&lang=js&"
import style0 from "./t-page-layout.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VBottomSheet,VCard,VCardText,VFooter})
