<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("прайс_лист") }}
      v-spacer
      v-btn(v-if="!$isCordova()" icon @click="$openLink('https://link.rekassa.kz/pricelist-help', '_system')")
        v-icon mdi-help-circle
      v-progress-linear(:active="positionsLoading || addLoading || updateLoading || deleteLoading" :indeterminate="positionsLoading || addLoading || updateLoading || deleteLoading" absolute bottom)

    v-main(v-if="!$isCordova()" flat)
      v-container
        v-row
          v-col
            v-data-table(:headers="headers" :items="filtredPositionList" :items-per-page="10" sort-by="name" class="elevation-1")
              template(v-slot:top)
                v-container.py-1
                  v-row
                    v-col.pb-0(cols="4")
                      v-text-field.py-0.pl-2(:label="$t('поиск')" v-model="searchText" autocomplete="off" clearable)
                    v-col.pb-0(cols="2" align="left")
                      v-btn(text color="primary" @click="preAdd()") + {{ $t("добавить") }}
                    v-col.pb-0(cols="6" align="right")
                      v-btn(id="menu_button" text color="primary") {{ $t("меню") }}
                        v-menu(bottom left activator="#menu_button")
                          v-list
                            v-list-item-group
                              v-list-item(@click="downloadTemplate()")
                                v-list-item-content
                                  v-list-item-title {{ $t("скачать_шаблон") }}
                                v-list-item-icon
                                  v-icon mdi-download
                              v-list-item(@click="preUpload()")
                                v-list-item-content
                                  v-list-item-title {{ $t("загрузить_прайс_лист") }}
                                v-list-item-icon
                                  v-icon mdi-upload
                              v-list-item(@click="download()")
                                v-list-item-content
                                  v-list-item-title {{ $t("скачать_прайс_лист") }}
                                v-list-item-icon
                                  v-icon mdi-tag-text-outline
              template(v-slot:item.unitType="{ item }") {{ $t('unitType.' + item.unitType) }}
              template(v-slot:item.edit="{ item }")
                v-icon(small @click="edit(item)") mdi-pencil
              template(v-slot:item.delete="{ item }")
                v-icon(small color="error" @click="promtDelete(item)") mdi-delete

    v-dialog(v-model="showEditDialog" persistent width="450")
      v-card
        v-card-text
          v-form(ref="form")
            v-row
              v-col.py-1.py-sm-2(cols="12")
                v-textarea(:label="$t('наименование')" v-model="selectedPosition.name" rows="1" :rules="[rules.required]" counter="500" maxlength="500" clearable autofocus validate-on-blur auto-grow autocomplete="off")
              v-col.py-1.py-sm-2(cols="6")
                v-select(:label="$t('единица_измерения')" v-model="selectedPosition.unitType" :items="unitTypes" item-value="value" item-text="title")
              v-col.py-1.py-sm-2(cols="6")
                v-text-field(:label="$t('стоимость')" v-model.number="selectedPosition.price" type="number" @keypress="limitDecimal($event, selectedPosition.price, 2)" :rules="[rules.numberRange(0, 100000000, selectedPosition.price)]" suffix="₸" validate-on-blur autocomplete="off")
              v-col.py-1.py-sm-2(cols="12")
                v-text-field(:label="$t('штрих_код')" v-model="selectedPosition.barcode" type="number" counter="20" maxlength="20" clearable autocomplete="off" placeholder=" ")
                  template(v-if="$isCordova()" v-slot:append-outer)
                    v-btn(icon @click="scanBarcode('selectedPosition')")
                      v-icon mdi-barcode-scan
        v-divider.item-divider
        v-card-actions
          v-btn(v-if="!newPosition" :disabled="updateLoading" :loading="deleteLoading" text color="red" @click="del()") {{ $t('удалить') }}
          v-spacer
          v-btn(text :disabled="addLoading || updateLoading || deleteLoading" @click="cancel()") {{ $t('отменить') }}
          v-btn(v-if="newPosition" text :loading="addLoading" :disabled="deleteLoading" color="primary" @click="add()") {{ $t('добавить') }}
          v-btn(v-if="!newPosition" :loading="updateLoading" :disabled="deleteLoading" text color="primary" @click="update()") {{ $t('сохранить') }}

    v-dialog(v-model="showUploadDialog" persistent width="450")
      v-card
        v-card-text.pb-2
          v-form(ref="form")
            v-row
              v-col.pb-0
                v-file-input(v-model="file" ref="upload" :label="$t('выберите_файл_для_загрузки')" accept=".xlsx" truncate-length="30" outlined dense :disabled="uploadLoading" :loading="uploadLoading" :messages="uploadMessage" :error-messages="uploadErrorMessage" @change="fileChanged")
            v-row.py-0
              v-col.py-0.ml-7
                v-checkbox(v-model="deleteAllPositionList" :label="$t('очистить_прайс_лист')" color="error" :disabled="uploadLoading")
            v-row.py-0(v-if="deleteAllPositionList")
              v-col.py-0.ml-7
                v-checkbox(v-model="deleteAllPositionListConfirm" :label="$t('вы_уверенны_что_хотите_очистить_прайс_лист')" color="error" :disabled="uploadLoading")
        v-divider.item-divider
        v-card-actions
          v-spacer
          v-btn(text :disabled="uploadLoading" @click="showUploadDialog = false") {{ $t('отменить') }}
          v-btn(text color="primary" :loading="uploadLoading" @click="upload()") {{ $t('загрузить') }}

    re-positions(:value="showPositionsDialog" :preferencesMode="true")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import XLSX from 'xlsx'
import i18n from '../../i18n/index'
import decimalMixin from '../../mixins/decimalMixin'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import Positions from '../ticket/parts/Positions.vue'

export default {
  components: {
    're-positions': Positions,
  },

  mixins: [dictionaryMixin, decimalMixin],

  data: () => ({
    showPositionsDialog: false,

    templateList: [
      {
        barcode: '00015453345',
        name: 'Молоко Моё отборное 1л.',
        unitType: 'PIECE',
        price: 550,
      },
      {
        barcode: '4870205223735',
        name: 'Мастер жидкий порошок 4300 мл',
        unitType: 'PIECE',
        price: 2850,
      },
      {
        barcode: '',
        name: 'Яблоки Превосход',
        unitType: 'KILOGRAM',
        price: 600,
      },
      {
        barcode: '',
        name: 'Компот фруктовый',
        unitType: 'LITER',
        price: 550,
      },
      {
        barcode: '',
        name: 'Прокат Sony Playstation 5',
        unitType: 'HOUR',
        price: 500,
      },
      {
        barcode: '',
        name: 'Аренда Hyundai Accent',
        unitType: 'DAY',
        price: 12600,
      },
      {
        barcode: '',
        name: 'Аренда 2х комнатной квартиры',
        unitType: 'MONTH',
        price: 250000,
      },
      {
        barcode: '',
        name: 'Маникюр с покрытием',
        unitType: 'ONE_SERVICE',
        price: 3500,
      },
    ],

    headers: [
      {
        text: i18n.t('штрих_код'),
        sortable: true,
        value: 'barcode',
      },
      {
        text: i18n.t('наименование'),
        sortable: true,
        value: 'name',
      },
      {
        text: i18n.t('единица_измерения'),
        sortable: true,
        value: 'unitType',
      },
      {
        text: i18n.t('стоимость'),
        align: 'start',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: false,
        value: 'edit',
      },
      {
        text: '',
        sortable: false,
        value: 'delete',
      },
    ],

    searchText: null,

    newPosition: false,
    addLoading: false,
    updateLoading: false,
    deleteLoading: false,
    showEditDialog: false,

    selectedPosition: {
      id: null,
      name: null,
      unitType: null,
      price: null,
      barcode: null,
    },

    showUploadDialog: false,
    deleteAllPositionList: false,
    deleteAllPositionListConfirm: false,
    uploadLoading: false,
    uploadMessage: null,
    uploadErrorMessage: null,
    file: null,
  }),

  computed: {
    ...mapState({
      positionList: state => state.positions.list,
      positionsLoading: state => state.positions.loading,
    }),

    filtredPositionList() {
      if (this.searchText === null || this.searchText === '') {
        return this.positionList
      }
      return this.positionList.filter((item) => !this.searchText || item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || (item.barcode === this.searchText))
    },
  },

  watch: {
    deleteAllPositionList(val) {
      if (!val) this.deleteAllPositionListConfirm = false
    },
  },

  created() {
    localStorage.setItem('rekassa.kz-menu-feature-positions-seen', 'true')
    this.fetchPositions().then(() => {
      if (this.$isCordova()) {
        this.$nextTick(() => {
          this.showPositionsDialog = true
        })
      }
    }).catch(() => {})
  },

  methods: {
    ...mapActions({
      fetchPositions: 'positions/fetch',
      addPosition: 'positions/add',
      addAllPosition: 'positions/addAll',
      updatePosition: 'positions/update',
      deletePosition: 'positions/delete',
      deleteAllPosition: 'positions/deleteAll',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
    }),

    cancel() {
      this.$refs.form.resetValidation()
      this.showEditDialog = false
    },

    edit(position) {
      this.selectedPosition.id = position.id
      this.selectedPosition.name = position.name
      this.selectedPosition.unitType = position.unitType
      this.selectedPosition.price = position.price
      this.selectedPosition.barcode = position.barcode
      this.newPosition = false
      this.showEditDialog = true
    },

    preAdd() {
      if (this.positionList.length >= 10000) {
        this.showSnackbar({ message: this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе') })
        return
      }
      this.selectedPosition = {
        id: null,
        name: null,
        unitType: 'PIECE',
        price: null,
        barcode: null,
      }
      this.newPosition = true
      this.showEditDialog = true
    },

    add() {
      if (this.$refs.form.validate()) {
        this.addLoading = true
        this.addPosition({
          data: {
            name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
          },
        }).then(() => {
          this.fetchPositions().then(() => {
            this.addLoading = false
            this.showEditDialog = false
            this.$refs.form.resetValidation()
          }).catch((error) => {
            this.addLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.addLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    update() {
      if (this.$refs.form.validate()) {
        this.updateLoading = true
        this.updatePosition({
          id: this.selectedPosition.id,
          data: {
            name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
          },
        }).then(() => {
          this.fetchPositions().then(() => {
            this.updateLoading = false
            this.showEditDialog = false
          }).catch((error) => {
            this.updateLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.updateLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    del() {
      this.deleteLoading = true
      this.deletePosition({ id: this.selectedPosition.id }).then(() => {
        this.fetchPositions().then(() => {
          this.deleteLoading = false
          this.showEditDialog = false
        }).catch((error) => {
          this.deleteLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }).catch((error) => {
        this.deleteLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    promtDelete(item) {
      if (this.deleteLoading) return
      this.selectedPosition.id = item.id
      this.showConfirm({
        title: this.$t('удалить_позицию', { name: item.name }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        resolveButtonColor: 'error',
        rejectButtonColor: 'gray',
      }).then(() => {
        this.del()
      }).catch(() => {})
    },

    scanBarcode(which) {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-datamatrix-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((result) => {
        if (which === 'searchText') {
          this.searchText = `${result}`
        } else {
          this.selectedPosition.barcode = `${result}`
        }
      },
      () => {}, { aspectRatio })
    },

    downloadTemplate() {
      try {
        const data = this.templateList.map((item) => {
          const obj = {}
          obj[this.$t('штрих_код')] = item.barcode
          obj[this.$t('наименование')] = item.name
          obj[this.$t('единица_измерения')] = this.$t(`unitType.${item.unitType}`)
          obj[this.$t('стоимость')] = item.price
          return obj
        })

        const wsPrice = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, wsPrice, 'Прайс-лист')

        const hint = []
        this.unitTypes.forEach((item) => {
          const obj = {}
          obj[this.$t('единица_измерения')] = item.title
          hint.push(obj)
        })

        const wsHint = XLSX.utils.json_to_sheet(hint)
        XLSX.utils.book_append_sheet(wb, wsHint, this.$t('справочник'))

        XLSX.writeFile(wb, `${this.$t('шаблон')}.xlsx`)
      } catch (error) {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      }
    },

    download() {
      try {
        const data = this.positionList.map((item) => {
          const obj = {}
          obj[this.$t('штрих_код')] = item.barcode
          obj[this.$t('наименование')] = item.name
          obj[this.$t('единица_измерения')] = this.$t(`unitType.${item.unitType}`)
          obj[this.$t('стоимость')] = item.price
          return obj
        })

        const wsPrice = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, wsPrice, 'Прайс-лист')

        const hint = []
        this.unitTypes.forEach((item) => {
          const obj = {}
          obj[this.$t('единица_измерения')] = item.title
          hint.push(obj)
        })

        const wsHint = XLSX.utils.json_to_sheet(hint)
        XLSX.utils.book_append_sheet(wb, wsHint, this.$t('справочник'))

        XLSX.writeFile(wb, `${this.$t('прайс_лист')}.xlsx`)
      } catch (error) {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      }
    },

    preUpload() {
      this.file = null
      this.uploadMessage = null
      this.uploadErrorMessage = null
      this.deleteAllPositionList = false
      this.deleteAllPositionListConfirm = false
      this.showUploadDialog = true
    },

    fileChanged() {
      this.uploadMessage = null
      this.uploadErrorMessage = null
    },

    upload() {
      const reader = new FileReader()

      if (!this.file) {
        this.uploadErrorMessage = this.$t('требуется_файл')
        return
      }

      reader.onerror = () => {
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
      }

      reader.onload = (event) => {
        this.uploadLoading = true

        let binary = ''
        const bytes = new Uint8Array(event.target.result)
        const length = bytes.byteLength

        for (let i = 0; i < length; i += 1) {
          binary += String.fromCharCode(bytes[i])
        }

        const wb = XLSX.read(binary, { type: 'binary' })

        const dataJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          header: ['barcode', 'name', 'unitType', 'price'], range: 1, defval: null, raw: false,
        })

        this.validateData(dataJson)
      }

      reader.readAsArrayBuffer(this.file)
    },

    validateData(dataJson) {
      let valid = true
      try {
        const types = ['шт.', 'дн.', 'кг', 'м2', 'м3', 'сутки', 'тәулік', 'г', 'га', 'час', 'сағ.', 'литр', 'м', 'месяц', 'ай', 'одн.усл.', 'бір қызм.', 'упак.', 'қапт.', 'пара', 'жұп', 'м.пог.', 'м.бойы', 'тонн.']
        if (dataJson && dataJson.length > 0) {
          if (this.deleteAllPositionList && this.deleteAllPositionListConfirm) {
            if (dataJson.length > 10000) throw Error(this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе'))
          } else if (dataJson.length + this.positionList.length > 10000) throw Error(this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе'))

          let index = 2
          dataJson.forEach((item) => {
            item.price = this.fuzzynum(item.price)
            if (item.barcode !== null && item.barcode.length > 20) throw Error(this.$t('длина_штрих_кода_превышает_символов_строка_в_файле', { barcode: item.barcode, row: index }))
            if (item.name === null) throw Error(this.$t('отсутствует_наименование_строка_в_файле', { row: index }))
            if (item.name && item.name.length > 500) throw Error(this.$t('длина_наименования_превышает_символов_строка_в_файле', { name: item.name, row: index }))
            if (item.unitType === null) throw Error(this.$t('не_указана_единица_измерения_строка_в_файле', { row: index }))
            if (item.unitType !== null && types.indexOf(item.unitType) === -1) throw Error(this.$t('неизвестная_единица_измерения_строка_в_файле', { type: item.unitType, row: index }))
            if (item.price === null || item.price < 0) throw Error(this.$t('не_указана_стоимость_строка_в_файле', { row: index }))
            item.unitType = this.unitTypes.filter(type => type.title === item.unitType)[0].value
            index += 1
          })
        } else {
          this.uploadLoading = false
          this.showSnackbar({ message: this.$t('в_файле_отсутствуют_данные_для_загрузки') })
        }
      } catch (error) {
        valid = false
        this.uploadLoading = false
        this.uploadErrorMessage = `${this.$t('ошибка')}: ${error.message ? error.message : error}`
      }

      if (valid) {
        this.uploadErrorMessage = null
        if (this.deleteAllPositionList && this.deleteAllPositionListConfirm) {
          this.deleteAllPosition().then(() => {
            this.uploadDataAll(dataJson)
          }).catch((error) => {
            this.uploadLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        } else {
          this.uploadDataOneByOne(dataJson, 0)
        }
      }
    },

    uploadDataAll(dataJson) {
      this.uploadMessage = this.$t('идет_загрузка_пожалуйста_подождите')
      this.$nextTick(() => {
        const initialValue = {}
        const positionsObject = dataJson.reduce((obj, item) => ({
          ...obj,
          [this.generateId()]: item,
        }), initialValue)

        this.addAllPosition({
          data: {
            positions: positionsObject,
          },
        }).then(() => {
          this.fetchPositions().then(() => {
            this.showUploadDialog = false
            this.uploadLoading = false
            this.firebaseAnalyticsLogEvent({ eventName: 're_positions_uploaded' })
            this.showSnackbar({ message: this.$t('прайс_лист_успешно_загружен') })
          }).catch(() => {})
        }).catch((error) => {
          this.uploadLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      })
    },

    uploadDataOneByOne(dataJson, index) {
      if (index === dataJson.length) {
        this.fetchPositions().then(() => {
          this.showUploadDialog = false
          this.uploadLoading = false
          this.firebaseAnalyticsLogEvent({ eventName: 're_positions_uploaded' })
          this.showSnackbar({ message: this.$t('прайс_лист_успешно_загружен') })
        }).catch(() => {})
        return
      }
      const item = dataJson[index]
      this.uploadMessage = `${this.$t('загрузка_строки_пожалуйста_подождите', { row: index + 2 })}`
      this.addPosition({
        data: item,
        silent: true,
      }).then(() => {
        this.uploadDataOneByOne(dataJson, index + 1)
      }).catch((error) => {
        this.uploadLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    fuzzynum(s) {
      let v = Number(s)
      if (Number.isFinite(v)) return v
      if (!Number.isNaN(v)) return NaN
      if (!/\d/.test(s)) return v
      let wt = 1
      let ss = s.replace(/([\d]),([\d])/g, '$1$2').replace(/[$]/g, '').replace(/[%]/g, () => { wt *= 100; return '' })
      // eslint-disable-next-line no-cond-assign
      if (!Number.isNaN(v = Number(ss))) return v / wt
      ss = ss.replace(/[(](.*)[)]/, ($$, $1) => { wt = -wt; return $1 })
      // eslint-disable-next-line no-cond-assign
      if (!Number.isNaN(v = Number(ss))) return v / wt
      return v
    },

    generateId() {
      const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'
      let lastPushTime = 0
      const lastRandChars = []

      let now = new Date().getTime()
      const duplicateTime = (now === lastPushTime)
      lastPushTime = now

      const timeStampChars = new Array(8)
      for (let i = 7; i >= 0; i -= 1) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64)
        now = Math.floor(now / 64)
      }
      if (now !== 0) throw new Error('We should have converted the entire timestamp.')

      let id = timeStampChars.join('')

      if (!duplicateTime) {
        for (let i = 0; i < 12; i += 1) {
          lastRandChars[i] = Math.floor(Math.random() * 64)
        }
      } else {
        let i = 11
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i -= 1) {
          lastRandChars[i] = 0
        }
        lastRandChars[i] += 1
      }
      for (let i = 0; i < 12; i += 1) {
        id += PUSH_CHARS.charAt(lastRandChars[i])
      }
      if (id.length !== 20) throw new Error('Length should be 20.')

      return id
    },
  },
}
</script>
