<template lang="pug">
  v-container(style="max-width: 450px")
    v-row
      v-col.pa-6(align="center")
        v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

    v-row
      v-col
        v-list.v-list--fulltext.gray-background-list
          v-subheader {{ $t("настройки_интерфейса") }}
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("темная_тема") }}
            v-list-item-action
              v-switch(color="teal" :input-value="darkTheme" @change="darkThemeChanged")
          v-list-item(@click="openMainScreenTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("главный_экран") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1  {{ $t("mainScreenType." + mainScreenType) }}
          v-list-item(@click="openLocaleTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("язык") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ dictionary.localeType[locale].title }}
          v-subheader {{ $t("настройки_по_умолчанию_в_момент_оформления_чека") }}
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("автоматическая_прокрутка_чека_на_экране") }}
            v-list-item-action
              v-switch(color="teal" :input-value="ticketAutoScroll" @change="ticketAutoScrollChanged")
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("маркировка") }}
            v-list-item-action
              v-switch(color="teal" :input-value="exciseStamp" @change="exciseStampChanged")
          v-list-item(v-if="exciseStamp && $isAndroid()")
            v-list-item-content
              v-list-item-title {{ $t("режим_маркировки_43") }}
            v-list-item-action
              v-switch(color="teal" :input-value="aspectRatio43" @change="aspectRatio43Changed")
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("режим_покупка") }}
            v-list-item-action
              v-switch(color="teal" :input-value="buyMode" @change="buyModeChanged")
          v-list-item.d-none
            v-list-item-content
              v-list-item-title {{ $t("режим_простой_калькулятор") }}
            v-list-item-action
              v-switch(color="teal" :input-value="simpleMode" @change="simpleModeChanged" disabled)
          v-list-item(@click="openDomainTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("вид_отрасли") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t("domainType." + preferences.domainType) }}
          v-list-item(@click="openBusinessNameOnReceiptDialog()")
            v-list-item-content
              v-list-item-title {{ $t("наименование_на_чеке") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t("businessNameType." + preferences.businessNameOnReceipt) }}
              v-list-item-subtitle ({{ preferences.businessNameOnReceipt === 'ORGANIZATION' ? organization.businessName : cashRegister.pos.title }})
          v-list-item(@click="openDefaultTaxTypeDialog()" v-if="configuration.taxMode")
            v-list-item-content
              v-list-item-title {{ $t("налоговая_ставка") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t('taxType.' + preferences.defaultTaxType) }}
          v-list-item(@click="openDefaultItemNameDialog()")
            v-list-item-content
              v-list-item-title {{ $t("наименование_позиции") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ preferences.defaultItemName }}
          v-list-item(@click="openDefaultItemTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("единица_измерения") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t('unitType.' + preferences.defaultItemType) }}
          v-list-item(@click="openOperationTotalLimitDialog()")
            v-list-item-content
              v-list-item-title {{ $t("максимальная_сумма_чека") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ preferences.operationTotalLimit | numeral('0,0') }} ₸
          v-list-item(v-if="$isCordova()" @click="openShareTicketTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("поделиться_чеком") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t("shareTicketType." + shareTicketType) }}
          v-list-item(@click="openPaymentTypesDialog()")
            v-list-item-content
              v-list-item-title {{ $t("настройка_типов_оплаты") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t("наличные") }}{{ preferences.paymentTypes.card ? `, ${$t('карта')}` : '' }}{{ preferences.paymentTypes.credit ? `, ${$t('кредит')}` : '' }}{{ preferences.paymentTypes.tare ? `, ${$t('тара')}` : '' }}
          v-list-item(@click="openDefaultPaymentTypeDialog()")
            v-list-item-content
              v-list-item-title {{ $t("тип_оплаты_по_умолчанию") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1 {{ $t("paymentType." + defaultPaymentType) }}
          v-list-item(@click="openAdvertisingTextDialog()")
            v-list-item-content
              v-list-item-title {{ $t("рекламный_текст") }}
            v-list-item-content.text-align-end
              v-list-item-action-text.subtitle-1.text-truncate {{ preferences.advertisingText ? preferences.advertisingText : '...' }}

    v-dialog(v-model="mainScreenTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_главный_экран") }}
        v-card-text
          v-container
            v-form.mt-3(ref="mainScreenTypeRadio" @submit.prevent="saveMainScreenType()")
              v-radio-group(v-model="mainScreenTypeRadio")
                v-radio(:label="$t('mainScreenType.CALCULATOR')" value="CALCULATOR")
                v-radio(:label="$t('mainScreenType.POSITIONS')" value="POSITIONS")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="mainScreenTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveMainScreenType()") {{ $t("сохранить") }}

    v-dialog(v-model="localeTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_язык") }}
        v-card-text
          v-container
            v-form.mt-3(ref="localeTypeForm" @submit.prevent="saveLocaleType()")
              v-radio-group(v-model="localeTypeRadio")
                v-radio(v-for="(item, $index) in localeTypes" :key="'l_' + $index" :label="item.title" :value="item.value")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="localeTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveLocaleType()") {{ $t("сохранить") }}

    v-dialog(v-model="businessNameOnReceiptDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_наименование_на_чеке") }}
        v-card-text
          v-container
            v-form.mt-3(ref="businessNameOnReceiptForm" @submit.prevent="saveBusinessNameOnReceipt()")
              v-select(:label="$t('наименование_на_чеке')" v-model="businessNameOnReceipt" :items="businessNameTypes" item-value="value" item-text="title")
                template(slot="item" slot-scope="data") {{ data.item.title }} ({{ data.item.value === 'ORGANIZATION' ? organization.businessName : cashRegister.pos.title }})
                template(slot="selection" slot-scope="data") {{ data.item.title }} ({{ data.item.value === 'ORGANIZATION' ? organization.businessName : cashRegister.pos.title }})
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="businessNameOnReceiptDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveBusinessNameOnReceipt()") {{ $t("сохранить") }}

    v-dialog(v-model="defaultTaxTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_налоговую_ставку") }}
        v-card-text
          v-container
            v-form.mt-3(ref="defaultTaxTypeForm" @submit.prevent="saveDefaultTaxType()")
              v-select(:label="$t('налоговая_ставка')" v-model="defaultTaxType" :items="taxTypes" item-value="value" item-text="title")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="defaultTaxTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveDefaultTaxType()") {{ $t("сохранить") }}

    v-dialog(v-model="domainTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_вид_отрасли") }}
        v-card-text
          v-container
            v-form.mt-3(ref="domainTypeForm" @submit.prevent="saveDomainType()")
              v-select(:label="$t('вид_отрасли')" v-model="domainType" :items="domainTypes" item-value="value" item-text="title")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="domainTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveDomainType()") {{ $t("сохранить") }}

    v-dialog(v-model="defaultItemNameDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_наименование_позиции") }}
        v-card-text
          v-container
            v-form.mt-3(ref="defaultItemNameForm" @submit.prevent="saveDefaultItemName()")
              v-text-field(:label="$t('наименование_позиции')" v-model="defaultItemName" type="text" :rules="[rules.required]" validate-on-blur autocomplete="off" :autofocus="!$isCordova()" maxlength="40" counter="40" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="defaultItemNameDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveDefaultItemName()") {{ $t("сохранить") }}

    v-dialog(v-model="defaultItemTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_единицу_измерения") }}
        v-card-text
          v-container
            v-form.mt-3(ref="defaultItemTypeForm" @submit.prevent="saveDefaultItemType()")
              v-select(:label="$t('единица_измерения')" v-model="defaultItemType" :items="unitTypes" item-value="value" item-text="title")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="defaultItemTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveDefaultItemType()") {{ $t("сохранить") }}

    v-dialog(v-model="operationTotalLimitDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_лимит_на_сумму_чека") }}
        v-card-text
          v-container
            v-form.mt-3(ref="operationTotalLimitForm" @submit.prevent="saveOperationTotalLimit()")
              v-text-field(:label="$t('максимальная_сумма_чека')" v-model.number="operationTotalLimit" type="number" :rules="[rules.numberRange(1, 990000000, operationTotalLimit)]" suffix="₸" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="operationTotalLimitDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveOperationTotalLimit()") {{ $t("сохранить") }}

    v-dialog(v-model="shareTicketTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_как_поделиться_чеком") }}
        v-card-text
          v-container
            v-form.mt-3(ref="shareTicketTypeForm" @submit.prevent="saveShareTicketType()")
              v-radio-group(v-model="shareTicketTypeRadio")
                v-radio(:label="$t('shareTicketType.IMAGE')" value="IMAGE")
                v-radio(:label="$t('shareTicketType.LINK')" value="LINK")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="shareTicketTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveShareTicketType()") {{ $t("сохранить") }}

    v-dialog(v-model="paymentTypesDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_типы_оплат") }}
        v-card-text
          v-container
            v-form.mt-3(ref="paymentTypesForm" @submit.prevent="savePaymentTypes()")
              v-checkbox(input-value="true" :label="$t('наличные_включены_по_умолчанию')" disabled)
              v-checkbox(v-model="paymentTypes.card" :label="$t('карта')")
              v-checkbox(v-model="paymentTypes.credit" :label="$t('кредит')")
              v-checkbox(v-model="paymentTypes.tare" :label="$t('тара')")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="paymentTypesDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="savePaymentTypes()") {{ $t("сохранить") }}

    v-dialog(v-model="defaultPaymentTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_тип_оплаты_по_умолчанию") }}
        v-card-text
          v-container
            v-form.mt-3(ref="defaultPaymentTypeForm" @submit.prevent="saveDefaultPaymentType()")
              v-radio-group(v-model="defaultPaymentTypeRadio")
                v-radio(:label="$t('paymentType.PAYMENT_CASH')" value="PAYMENT_CASH")
                v-radio(:label="$t('paymentType.PAYMENT_CARD')" value="PAYMENT_CARD" :disabled="!this.paymentTypes.card")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="defaultPaymentTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveDefaultPaymentType()") {{ $t("сохранить") }}

    v-dialog(v-model="advertisingTextDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_рекламный_текст") }}
        v-card-text
          v-container
            v-form.mt-3(ref="advertisingTextForm" @submit.prevent="saveAdvertisingText()")
              v-textarea(:label="$t('рекламный_текст')" v-model="advertisingText" type="text" rows="3" auto-grow validate-on-blur autocomplete="off" :autofocus="!$isCordova()" maxlength="200" counter="200" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="advertisingTextDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveAdvertisingText()") {{ $t("сохранить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import { getLocale, changeLocale } from '../../i18n'

export default {
  mixins: [dictionaryMixin],

  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_work_together_h63l.svg',
      light: '/static/illustrations/rally/light/undraw_work_together_h63l.svg',
    },

    mainScreenType: null,
    mainScreenTypeRadio: null,
    mainScreenTypeDialog: false,

    darkTheme: true,
    ticketAutoScroll: false,
    buyMode: false,
    simpleMode: false,
    exciseStamp: false,
    aspectRatio43: false,

    localeTypeRadio: null,
    localeTypeDialog: false,

    businessNameOnReceipt: null,
    businessNameOnReceiptDialog: false,

    defaultTaxType: null,
    defaultTaxTypeDialog: false,

    domainType: null,
    domainTypeDialog: false,

    defaultItemName: null,
    defaultItemNameDialog: false,

    defaultItemType: null,
    defaultItemTypeDialog: false,

    operationTotalLimit: 0,
    operationTotalLimitDialog: false,

    shareTicketType: null,
    shareTicketTypeRadio: null,
    shareTicketTypeDialog: false,

    paymentTypes: {
      card: true,
      credit: false,
      tare: false,
    },
    paymentTypesDialog: false,

    defaultPaymentType: null,
    defaultPaymentTypeRadio: null,
    defaultPaymentTypeDialog: false,

    advertisingText: null,
    advertisingTextDialog: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'cashRegister.id': function () {
      this.updateValues()
    },
  },

  created() {
    this.mainScreenType = localStorage.getItem('rekassa.kz-ui-main-screen') || 'CALCULATOR'
    this.localeTypeRadio = this.locale
    this.darkTheme = localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true'
    this.ticketAutoScroll = localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true'
    this.aspectRatio43 = parseInt(localStorage.getItem('rekassa.kz-ui-datamatrix-aspectRatio') || 1, 10) === 0
    this.defaultPaymentType = localStorage.getItem('rekassa.kz-ui-defaultPaymentType') || 'PAYMENT_CASH'
    this.shareTicketType = localStorage.getItem('rekassa.kz-ui-shareTicketType') || 'IMAGE'
    this.updateValues()
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      savePreferences: 'cashRegisters/savePreferences',
      showSnackbar: 'tools/showSnackbar',
    }),

    updateValues() {
      this.exciseStamp = this.preferences.exciseStamp
      this.buyMode = this.preferences.buyMode
      this.simpleMode = this.preferences.simpleMode
    },

    darkThemeChanged(val) {
      this.$vuetify.theme.dark = val
      localStorage.setItem('rekassa.kz-ui-darkTheme', val)
      this.darkTheme = val
      if (this.darkTheme) {
        document.body.style.backgroundColor = '#33333D'
      } else {
        document.body.style.backgroundColor = '#FFFFFF'
      }
    },

    ticketAutoScrollChanged(val) {
      localStorage.setItem('rekassa.kz-ui-ticketAutoScroll', val)
      this.ticketAutoScroll = val
    },

    exciseStampChanged(val) {
      this.preferences.exciseStamp = val
      this.exciseStamp = val
      this.save()
    },

    aspectRatio43Changed(val) {
      this.aspectRatio43 = val
      localStorage.setItem('rekassa.kz-ui-datamatrix-aspectRatio', val ? 0 : 1)
    },

    buyModeChanged(val) {
      this.preferences.buyMode = val
      this.buyMode = val
      this.save()
    },

    simpleModeChanged(val) {
      this.preferences.simpleMode = val
      this.simpleMode = val
      this.save()
    },

    openMainScreenTypeDialog() {
      this.mainScreenTypeRadio = this.mainScreenType
      this.mainScreenTypeDialog = true
    },
    saveMainScreenType() {
      this.mainScreenType = this.mainScreenTypeRadio
      localStorage.setItem('rekassa.kz-ui-main-screen', this.mainScreenType)
      this.mainScreenTypeDialog = false
      window.location.href = `${window.location.origin}`
    },

    openLocaleTypeDialog() {
      this.localeTypeRadio = this.locale
      this.localeTypeDialog = true
    },
    saveLocaleType() {
      if (this.$refs.localeTypeForm.validate()) {
        this.localeTypeDialog = false
        changeLocale(this.localeTypeRadio)
      }
    },

    openBusinessNameOnReceiptDialog() {
      this.businessNameOnReceipt = this.preferences.businessNameOnReceipt
      this.businessNameOnReceiptDialog = true
    },
    saveBusinessNameOnReceipt() {
      if (this.$refs.businessNameOnReceiptForm.validate()) {
        this.preferences.businessNameOnReceipt = this.businessNameOnReceipt
        this.businessNameOnReceiptDialog = false
        this.save()
      }
    },

    openDefaultTaxTypeDialog() {
      this.defaultTaxType = this.preferences.defaultTaxType
      this.defaultTaxTypeDialog = true
    },
    saveDefaultTaxType() {
      if (this.$refs.defaultTaxTypeForm.validate()) {
        this.preferences.defaultTaxType = this.defaultTaxType
        this.defaultTaxTypeDialog = false
        this.save()
      }
    },

    openDomainTypeDialog() {
      this.domainType = this.preferences.domainType
      this.domainTypeDialog = true
    },
    saveDomainType() {
      if (this.$refs.domainTypeForm.validate()) {
        this.preferences.domainType = this.domainType
        this.domainTypeDialog = false
        this.save()
      }
    },

    openDefaultItemNameDialog() {
      this.defaultItemName = this.preferences.defaultItemName
      this.defaultItemNameDialog = true
    },
    saveDefaultItemName() {
      if (this.$refs.defaultItemNameForm.validate()) {
        this.preferences.defaultItemName = this.defaultItemName
        this.defaultItemNameDialog = false
        this.save()
      }
    },

    openDefaultItemTypeDialog() {
      this.defaultItemType = this.preferences.defaultItemType
      this.defaultItemTypeDialog = true
    },
    saveDefaultItemType() {
      if (this.$refs.defaultItemTypeForm.validate()) {
        this.preferences.defaultItemType = this.defaultItemType
        this.defaultItemTypeDialog = false
        this.save()
      }
    },

    openOperationTotalLimitDialog() {
      this.operationTotalLimit = this.preferences.operationTotalLimit
      this.operationTotalLimitDialog = true
    },
    saveOperationTotalLimit() {
      if (this.$refs.operationTotalLimitForm.validate()) {
        this.preferences.operationTotalLimit = this.operationTotalLimit
        this.operationTotalLimitDialog = false
        this.save()
      }
    },

    openShareTicketTypeDialog() {
      this.shareTicketTypeRadio = this.shareTicketType
      this.shareTicketTypeDialog = true
    },
    saveShareTicketType() {
      this.shareTicketType = this.shareTicketTypeRadio
      localStorage.setItem('rekassa.kz-ui-shareTicketType', this.shareTicketType)
      this.shareTicketTypeDialog = false
    },

    openPaymentTypesDialog() {
      this.paymentTypes = JSON.parse(JSON.stringify(this.preferences.paymentTypes))
      this.paymentTypesDialog = true
    },
    savePaymentTypes() {
      this.preferences.paymentTypes = this.paymentTypes
      this.paymentTypesDialog = false
      this.save()

      if (!this.paymentTypes.card) {
        this.defaultPaymentType = 'PAYMENT_CASH'
        localStorage.setItem('rekassa.kz-ui-defaultPaymentType', this.defaultPaymentType)
      }
    },

    openDefaultPaymentTypeDialog() {
      this.defaultPaymentTypeRadio = this.defaultPaymentType
      this.defaultPaymentTypeDialog = true
    },
    saveDefaultPaymentType() {
      this.defaultPaymentType = this.defaultPaymentTypeRadio
      localStorage.setItem('rekassa.kz-ui-defaultPaymentType', this.defaultPaymentType)
      this.defaultPaymentTypeDialog = false
    },

    openAdvertisingTextDialog() {
      this.advertisingText = this.preferences.advertisingText
      this.advertisingTextDialog = true
    },
    saveAdvertisingText() {
      if (this.$refs.advertisingTextForm.validate()) {
        this.preferences.advertisingText = this.advertisingText
        this.advertisingTextDialog = false
        this.save()
      }
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>
