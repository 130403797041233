/* eslint-disable no-undef */
import i18n from '../../i18n/index'
import bluetoothBle from '../../utils/bluetoothBle'

const printer = {
  namespaced: true,

  state: {
    printer: localStorage.getItem('rekassa.kz-printer-printer') ? JSON.parse(localStorage.getItem('rekassa.kz-printer-printer'))
      : {
        connectionType: null,
        bluetooth: {
          name: null,
          address: null,
        },
        wifi: {
          name: null,
          address: null,
          port: null,
        },
      },
    settings: localStorage.getItem('rekassa.kz-printer-settings') ? JSON.parse(localStorage.getItem('rekassa.kz-printer-settings'))
      : {
        specialSymbols: true,
        rollWidth: 58, // 58, 80
        encoding: 'cp866', // cp866, Windows-1251, Chuck-Norris
        qrType: 'escpos', // escpos, image
        scrollingLines: 1,
      },
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setPrinter({ commit }, payload) {
      commit('set', { type: 'printer', value: payload })
      localStorage.setItem('rekassa.kz-printer-printer', JSON.stringify(payload))
    },

    setSettings({ commit }, payload) {
      commit('set', { type: 'settings', value: payload })
      localStorage.setItem('rekassa.kz-printer-settings', JSON.stringify(payload))
    },

    getBluetoothPrinterList({ }, payload) {
      // Android
      if (window.device.platform === 'Android') {
        return new Promise((resolve, reject) => {
          cordova.plugins.rekassaEscPos.getBluetoothPrinterList((printerList) => {
            resolve(printerList)
          }, (error) => {
            reject(error)
          })
        })
      }
      // iOS
      return new Promise((resolve, reject) => {
        bluetoothBle.getBluetoothPrinterList((printerList) => {
          resolve(printerList)
        }, (error) => {
          reject(error)
        }, payload)
      })
    },

    openBluetoothSettings({ }) {
      cordova.plugins.rekassaEscPos.openBluetoothSettings(() => {}, (error) => {
        dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
      })
    },

    printReceipt({ state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const currentPrinter = state.printer.connectionType === 'bluetooth' ? state.printer.bluetooth : state.printer.wifi
        if (currentPrinter.address) {
          const printPayload = {
            address: currentPrinter.address,
            type: state.printer.connectionType,
            ...payload,
            ...state.settings,
          }

          if (state.printer.connectionType === 'wifi') {
            printPayload.port = currentPrinter.port
          }

          for (let index = 0; index < state.settings.scrollingLines; index += 1) {
            printPayload.cmds.push('#new_line#')
          }

          // Android
          if (window.device.platform === 'Android') {
            cordova.plugins.rekassaEscPos.printTextArray((result) => {
              switch (result) {
                case 1:
                  dispatch('tools/showSnackbar', { message: i18n.t('чек_отправлен_на_печать') }, { root: true })
                  break
                case -1:
                  dispatch('tools/showSnackbar', { message: i18n.t('проверьте_подключение_принтера') }, { root: true })
                  break
                case 0:
                  dispatch('tools/showSnackbar', { message: i18n.t('ошибка_печати') }, { root: true })
                  break
                default:
                  dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${result}` }, { root: true })
                  break
              }
              resolve()
            }, (error) => {
              dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
              reject()
            }, printPayload)
          // iOS
          } else {
            bluetoothBle.printTextArray((result) => {
              switch (result) {
                case 1:
                  dispatch('tools/showSnackbar', { message: i18n.t('чек_отправлен_на_печать') }, { root: true })
                  break
                case -1:
                  dispatch('tools/showSnackbar', { message: i18n.t('проверьте_подключение_принтера') }, { root: true })
                  break
                default:
                  dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${result}` }, { root: true })
                  break
              }
              resolve()
            }, (error) => {
              dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
              reject()
            }, printPayload)
          }
        } else {
          dispatch('tools/showSnackbar', { message: i18n.t('прежде_чем_напечатать_чек_необходимо_выбрать_принтер') }, { root: true })
          reject(new Error('printerNotSelected'))
        }
      })
    },
  },
}

export default printer
