<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("настройки") }}
      v-spacer
      v-btn(v-if="configurationChanged" text :disabled="loading" @click="showSaveChangesPinpad()") {{ $t("сохранить") }}

    v-main(flat)
      v-container(style="max-width: 450px")
        v-row
          v-col
            v-list.v-list--fulltext.gray-background-list
              v-subheader {{ $t("настройки") }}
              template(v-if="!readonly")
                v-list-item(@click="openNameDialog()")
                  v-list-item-content
                    v-list-item-title {{ $t("название") }}
                  v-list-item-content.text-align-end
                    v-list-item-subtitle.subtitle-1 {{ name }}
                v-list-item
                  v-list-item-content
                    v-list-item-title {{ $t("плательщик_ндс") }}
                  v-list-item-action(@click="checkShift()")
                    v-switch(color="teal" :input-value="taxMode" @change="taxModeChanged" :disabled="this.cashRegister.shiftOpen")
                v-list-item(@click="openTaxationTypeDialog()")
                  v-list-item-content
                    v-list-item-title {{ $t("режим_налогообложения") }}
                  v-list-item-content.text-align-end
                    v-list-item-action-text.subtitle-1 {{ $t("taxationType." + taxationType) }}
                v-list-item(@click="openTimezoneDialog()")
                  v-list-item-content
                    v-list-item-title {{ $t("часовой_пояс") }}
                  v-list-item-content.text-align-end
                    v-list-item-action-text.subtitle-1 {{ $t("timezoneType." + timezone) }}
                v-list-item(@click="openСloseShiftScheduleDialog()")
                  v-list-item-content
                    v-list-item-title {{ $t("автоматическое_закрытие_смены") }}
                  v-list-item-content.text-align-end
                    v-list-item-action-text.subtitle-1 {{ closeShiftSchedule ? closeShiftSchedule : '--:--' }}
                v-list-item(to="/kkm/pincode")
                  v-list-item-content
                    v-list-item-title {{ $t("пин-код") }}
                  v-list-item-action
                    v-list-item-action-text.subtitle-1 ****
                v-list-item(to="/kkm/token")
                  v-list-item-content
                    v-list-item-title {{ $t("токен") }}
                  v-list-item-action
                    v-list-item-action-text.subtitle-1 *******
                template(v-if="user._links.createCashRegister")
                  v-subheader {{ $t("сторонние_приложения") }}
                  v-list-item(to="/access-api")
                    v-list-item-content
                      v-list-item-title {{ $t("перейти_к_списку") }}
                    v-list-item-action
                      v-btn(icon)
                        v-icon(color="primary") mdi-arrow-right
              template(v-if="readonly")
                v-list-item
                  v-list-item-content
                    v-list-item-title {{ $t("название") }}
                  v-list-item-content.text-align-end
                    v-list-item-subtitle.subtitle-1 {{ name }}
                v-list-item
                  v-list-item-content
                    v-list-item-title {{ $t("плательщик_ндс") }}
                  v-list-item-action
                    v-switch(color="teal" :input-value="taxMode" disabled)
                v-list-item
                  v-list-item-content
                    v-list-item-title {{ $t("режим_налогообложения") }}
                  v-list-item-content.text-align-end
                    v-list-item-action-text.subtitle-1 {{ $t("taxationType." + taxationType) }}
                v-list-item
                  v-list-item-content
                    v-list-item-title {{ $t("часовой_пояс") }}
                  v-list-item-content.text-align-end
                    v-list-item-action-text.subtitle-1 {{ $t("timezoneType." + timezone) }}

        v-row.mt-3
          v-col
            v-list.v-list--fulltext.gray-background-list
              v-subheader {{ $t("информация") }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("касса") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'ONLINE'") {{ $t("в_сети") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'OFFLINE' && hoursLeftTillBlocked !== null && hoursLeftTillBlocked >= 0") {{ $t('осталось_ч', { hours: hoursLeftTillBlocked }) }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'BLOCKED'") {{ $t("заблокирована") }}
                  v-list-item-subtitle(v-if="cashRegister.status === 'DEREGISTERED'") {{ $t("архивная") }}
                v-list-item-action(v-if="cashRegister.status === 'REGISTERED'")
                  v-chip(x-small :color="cashRegisterMode === 'ONLINE' ? 'success' : (cashRegisterMode === 'OFFLINE' ? 'warning' : (cashRegisterMode === 'BLOCKED' ? 'error' : null))") {{ cashRegisterMode }}
                v-list-item-action(v-if="cashRegister.status === 'DEREGISTERED'")
                  v-chip(x-small color="gray") -------------------
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("знм") }}
                  v-list-item-subtitle {{ $t("заводской_номер") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1
                    v-btn.mr-1(icon @click="copySerialNumber(cashRegister.serialNumber)")
                      v-icon mdi-content-copy
                    | {{ cashRegister.serialNumber }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("инм_id") }}
                  v-list-item-subtitle {{ $t("номер_кассы_в_офд") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ cashRegister.fdoId }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("регистрационный_номер") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ cashRegister.registrationNumber }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("офд") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ $t(`fdo.${cashRegister.fdo}.title`) }}
              v-list-item(v-if="preferences && preferences.ofd && preferences.ofd.accountId && cashRegister.fdo === 'TTK'")
                v-list-item-content
                  v-list-item-title {{ $t("лицевой_счёт") }}
                  v-list-item-subtitle
                    a(@click.prevent="$openLink('https://link.rekassa.kz/ttk-payment', '_system')") {{ $t("как_оплатить") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1
                    v-btn.mr-1(icon @click="copyOfdAccountId(preferences.ofd.accountId)")
                      v-icon mdi-content-copy
                    | {{ preferences.ofd.accountId }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("организация") }}
                v-list-item-content.text-align-end
                  v-list-item-action-text.subtitle-1 {{ organization.businessName }}
                  v-list-item-subtitle {{ $t("бин_иин") }} {{ organization.businessId }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("торговая_точка") }}
                v-list-item-content.text-align-end
                  v-list-item-action-text.subtitle-1 {{ cashRegister.pos.title }}
                  v-list-item-subtitle {{ cashRegister.pos.address }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("дата_активации") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ cashRegister.registrationDate | moment("DD-MM-YYYY") }}
              v-list-item(v-if="cashRegister.deregistrationDate")
                v-list-item-content
                  v-list-item-title {{ $t("дата_деактивации") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ cashRegister.deregistrationDate | moment("DD-MM-YYYY") }}

        v-row(v-if="!readonly && user._links.createCashRegister")
          v-col
            v-btn.my-3(color="error" block :disabled="loading" @click="showDeregistrationWarning()") {{ $t("деактивировать_кассу") }}
              v-icon(right dark) mdi-delete-off-outline

    v-dialog(v-model="nameDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_название_кассы") }}
        v-card-text
          v-container
            v-form.mt-3(ref="nameForm" @submit.prevent="saveName()")
              v-text-field(:label="$t('название')" v-model="nameInput" type="text" :rules="[rules.required]" autocomplete="off" maxlength="40" counter="40")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="nameDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveName()") {{ $t("изменить") }}

    v-dialog(v-model="taxationTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_вид_налогообложения") }}
        v-card-text
          v-container
            v-form.mt-3(ref="taxationTypeForm" @submit.prevent="saveTaxationType()")
              v-select(:label="$t('режим_налогообложения')" v-model="taxationTypeSelect" :items="taxationTypes" item-value="value" item-text="title")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="taxationTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveTaxationType()") {{ $t("изменить") }}

    v-dialog(v-model="timezoneDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_часовой_пояс") }}
        v-card-text
          v-container
            v-form.mt-3(ref="timezoneForm" @submit.prevent="saveTimezone()")
              v-select(:label="$t('часовой_пояс')" v-model="timezoneSelect" :items="timezoneTypes" item-value="value" item-text="title")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="timezoneDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveTimezone()") {{ $t("изменить") }}

    v-dialog(v-model="closeShiftScheduleDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("время_ежедневного_автоматического_закрытия_смены") }}
        v-card-text.pt-0
          v-container
            v-form(ref="closeShiftScheduleForm" @submit.prevent="saveСloseShiftSchedule()")
              v-row
                v-col
                  v-checkbox(v-model="closeShiftScheduleCheckbox" :label="$t('включить')")
                v-col
                  v-text-field(:label="$t('время')" placeholder="22:30" v-model="closeShiftScheduleSelect" v-mask="timeMask" :rules="[closeShiftScheduleCheckbox ? rules.length(5) : false]" validate-on-blur :disabled="!closeShiftScheduleCheckbox" autocomplete="off" :hint="$t('время_фактического_закрытия_смены_может_быть_позднее_на_минут')")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="closeShiftScheduleDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveСloseShiftSchedule()") {{ $t("сохранить") }}

    re-pinpad(v-model="saveChangesDialog" :title="$t('сохранение_настроек')" :subtitle="$t('введите_пин-код')" :text="$t('для_сохранения_настроек')" :loading="loading" :errorText.sync="saveChangesErrorMessage" @action="saveChanges")

    re-pinpad(v-model="deregistrationDialog" :title="$t('деактивация_кассы')" :subtitle="$t('введите_пин-код')" :text="$t('для_деактивация_кассы')" :loading="loading" :errorText.sync="deregistrationErrorMessage" @action="deregistrate")

    v-dialog(v-model="deregistrationDialogWarning" scrollable max-width="400")
      v-card
        v-card-text
          v-alert(type="warning" dark icon="mdi-delete-off-outline" prominent)
            .headline {{ $t("вы_действительно_хотите_деактивировать_кассу", { cashRegisterName: cashRegister.name }) }}
          v-alert(type="error" dark icon="mdi-alert" prominent)
            | {{ $t("внимание_после_деактивации_dotdotdot") }}
        v-card-actions.mb-2
          v-spacer
          v-btn(color="error" @click.native="showDeregistrationDialogPinpad()" ) {{ $t("деактивировать") }}
            v-icon(right dark) mdi-delete-off-outline
          v-btn(text @click.native="deregistrationDialogWarning = false") {{ $t("отменить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'

export function timeMask(value) {
  const hours = [
    /[0-2]/,
    value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
  ]
  const minutes = [/[0-5]/, /[0-9]/]
  return value.length > 2
    ? [...hours, ':', ...minutes]
    : hours
}

export default {
  components: {
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin],

  data: () => ({
    kaspiPayImageUrl: {
      dark: '/static/kaspi-pay-white.svg',
      light: '/static/kaspi-pay-black.svg',
    },

    halykPosImageUrl: {
      dark: '/static/halyk-pos-white.svg',
      light: '/static/halyk-pos-black.svg',
    },

    loading: false,
    configurationChanged: false,

    saveChangesDialog: false,
    saveChangesErrorMessage: null,

    deregistrationDialogWarning: false,
    deregistrationDialog: false,
    deregistrationErrorMessage: null,

    nameDialog: false,
    name: null,
    nameInput: null,

    taxMode: null,

    taxationTypeDialog: false,
    taxationTypeSelect: null,
    taxationType: null,

    timezone: null,
    timezoneSelect: null,
    timezoneDialog: false,

    timeMask,
    closeShiftSchedule: null,
    closeShiftScheduleSelect: null,
    closeShiftScheduleDialog: false,
    closeShiftScheduleCheckbox: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    cashRegisterMode() {
      return this.cashRegister ? this.cashRegister.fdoMode : '------'
    },

    hoursLeftTillBlocked() {
      return this.cashRegister && this.cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(this.cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    readonly() {
      return this.cashRegister.status === 'DEREGISTERED'
    },
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED' || store.state.cashRegisters.cashRegister.cashRegister.status === 'DEREGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions({
      saveConfiguration: 'cashRegisters/saveConfiguration',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      deregistrateCashRegister: 'cashRegisters/deregistrateCashRegister',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      copyToClipboard: 'tools/copyToClipboard',
    }),

    init() {
      this.name = this.configuration.name
      this.taxMode = this.configuration.taxMode
      this.taxationType = this.configuration.taxationType
      this.timezone = this.configuration.timezone
      this.closeShiftSchedule = this.configuration.closeShiftSchedule
      this.closeShiftScheduleCheckbox = !!this.closeShiftSchedule
    },

    copySerialNumber(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

    copyOfdAccountId(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('лицевой_счёт_скопирован', { number: str }) })
    },

    taxModeChanged(val) {
      this.taxMode = val
      this.checkForConfigurationChange()
    },

    openNameDialog() {
      if (this.checkShift()) {
        this.nameInput = this.name
        this.nameDialog = true
      }
    },
    saveName() {
      if (this.$refs.nameForm.validate()) {
        this.name = this.nameInput
        this.nameDialog = false
        this.checkForConfigurationChange()
      }
    },

    openTaxationTypeDialog() {
      if (this.checkShift()) {
        this.taxationTypeSelect = this.taxationType
        this.taxationTypeDialog = true
      }
    },
    saveTaxationType() {
      this.taxationTypeDialog = false
      this.taxationType = this.taxationTypeSelect
      this.checkForConfigurationChange()
    },

    openTimezoneDialog() {
      if (this.checkShift()) {
        this.timezoneSelect = this.timezone
        this.timezoneDialog = true
      }
    },
    saveTimezone() {
      this.timezoneDialog = false
      this.timezone = this.timezoneSelect
      this.checkForConfigurationChange()
    },

    openСloseShiftScheduleDialog() {
      if (this.checkShift()) {
        this.closeShiftScheduleSelect = this.closeShiftSchedule
        this.closeShiftScheduleCheckbox = !!this.closeShiftSchedule
        this.closeShiftScheduleDialog = true
      }
    },
    saveСloseShiftSchedule() {
      if (this.closeShiftScheduleCheckbox && this.$refs.closeShiftScheduleForm.validate()) {
        this.closeShiftScheduleDialog = false
        this.closeShiftSchedule = this.closeShiftScheduleSelect
        this.checkForConfigurationChange()
      } else if (!this.closeShiftScheduleCheckbox) {
        this.closeShiftScheduleDialog = false
        this.closeShiftScheduleSelect = null
        this.closeShiftSchedule = this.closeShiftScheduleSelect
        this.checkForConfigurationChange()
      }
    },

    checkForConfigurationChange() {
      if (this.taxMode !== this.configuration.taxMode
        || this.taxationType !== this.configuration.taxationType
        || this.name !== this.configuration.name
        || this.timezone !== this.configuration.timezone
        || this.closeShiftSchedule !== this.configuration.closeShiftSchedule) {
        this.configurationChanged = true
      } else {
        this.configurationChanged = false
      }
    },

    checkShift() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр') })
        return false
      }
      return true
    },

    showSaveChangesPinpad() {
      this.saveChangesDialog = true
    },

    getModifiedConfiguration() {
      return {
        ...this.configuration,
        name: this.name,
        taxMode: this.taxMode,
        taxationType: this.taxationType,
        timezone: this.timezone,
        closeShiftSchedule: this.closeShiftSchedule,
      }
    },

    saveChanges(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.init()
          this.saveChangesDialog = false
          this.loading = false
          this.configurationChanged = false
          this.showSnackbar({ message: this.$t('изменения_успешно_сохранены') })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.saveChangesErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.init()
            this.saveChangesDialog = false
            this.loading = false
          })
        }
      })
    },

    showDeregistrationWarning() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену') })
        return
      }

      this.deregistrationDialogWarning = true
    },

    showDeregistrationDialogPinpad() {
      this.deregistrationDialogWarning = false
      this.deregistrationDialog = true
    },

    deregistrate(pincode) {
      this.loading = true
      this.deregistrateCashRegister({
        cashRegister: this.cashRegister,
        password: pincode,
      }).then(() => {
        this.deregistrationDialog = false
        this.loading = false
        this.showSnackbar({ message: this.$t('касса_успешно_деактивирована') })
        this.firebaseAnalyticsLogEvent({ eventName: 're_cash_register_deactivated' })
        this.$router.push('/')
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.deregistrationErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_снять_с_учета_кассу', { error }) })
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.init()
            this.deregistrationDialog = false
            this.loading = false
          })
        }
      })
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.configurationChanged) {
      this.showConfirm({
        title: this.$t('внесенные_изменения_не_сохранены_хотите_сохранить_изменения'), resolveText: this.$t('сохранить'), rejectText: this.$t('отменить'),
      }).then(() => {
        this.showSaveChangesPinpad()
      }).catch(() => {
        next()
      })
    } else {
      next()
    }
  },
}
</script>
