<template lang="pug">
v-dialog(v-model="value" v-if="value" scrollable persistent :fullscreen="$vuetify.breakpoint.xsOnly" :hide-overlay="$vuetify.breakpoint.xsOnly" width="500" :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'")
  v-card
    v-card-title.pa-0
      v-toolbar.new-cash-register-toolbar(dense flat dark)
        v-progress-linear(:active="cashRegisterItemListLoading" :indeterminate="cashRegisterItemListLoading" absolute bottom)
        v-btn(icon @click="$emit('input', false)")
          v-icon mdi-close
        v-spacer
        | {{ $t("список_касс") }}
        v-spacer
        v-btn(id="options_button" icon)
          v-icon mdi-dots-vertical
          v-menu(bottom left activator="#options_button")
            v-list
              v-list-item(to="/kkm/restore")
                v-list-item-content
                  v-list-item-subtitle {{ $t("подключить_ранее_активированную_кассу") }}

      v-expansion-panels.new-cash-register-expansion-panels(v-model="newExpansionPanels" multiple flat tile hover )
        v-expansion-panel
          v-expansion-panel-header.pa-0(hide-actions)
            v-btn(:class="{'blinker' : cashRegisterItemListNew.length === 0 && newExpansionPanels.length === 0 && cashRegisterItemListRegistered.length === 0 }" text :ripple="false" height="48")
              v-icon(left) mdi-plus
              | {{ $t("новая_касса") }}
          v-expansion-panel-content
            v-form(ref="newCashRegisterNameForm" @submit.prevent="createCashRegister()")
              v-text-field(:class="{'blinker' : cashRegisterItemListNew.length === 0 && newExpansionPanels.length > 0 && cashRegisterItemListRegistered.length === 0 && newCashRegisterName.length === 0}" v-model="newCashRegisterName" ref="newCashRegisterName" :disabled="newCashRegisterLoading" :rules="[rules.required]" autocomplete="off" counter="40" maxlength="40" clearable :placeholder="$t('название_кассы')")
              v-btn.mt-2(:class="{'blinker' : cashRegisterItemListNew.length === 0 && newExpansionPanels.length > 0 && cashRegisterItemListRegistered.length === 0 && newCashRegisterName.length > 0}" color="primary" small block :loading="newCashRegisterLoading" @click="createCashRegister()") {{ $t("ок") }}

    v-divider

    v-card-text.pa-0.other-cash-register-expansion-panels
      v-expansion-panels(v-model="otherExpansionPanels" multiple flat tile hover)
        v-expansion-panel(:class="{'d-none' : cashRegisterItemListNew.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("новые_ожидают_активацию") }}
          v-expansion-panel-content
            v-list-item(v-for="item in cashRegisterItemListNew" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-avatar(v-if="!$vuetify.breakpoint.xsOnly")
                  v-icon mdi-cash-register
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.serialNumber }}
                  v-list-item-subtitle.text--primary
                    span(v-if="item.cashRegister.name") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.cashRegister.createDate | moment("calendar") }}
                  v-list-item-subtitle
                    v-btn.mt-1(:class="{'blinker' : cashRegisterItemListNew.length === 1 && cashRegisterItemListRegistered.length === 0}" rounded outlined x-small :disabled="cashRegisterItemListLoading" @click="toActivation(item)") {{ $t("активировать") }}
                    v-btn.mt-1.ml-2(icon @click="copy(item.cashRegister.serialNumber)" :disabled="cashRegisterItemListLoading")
                      v-icon(small) mdi-content-copy
                    v-btn.mt-1(icon @click="deleteNewCashRegisterConfirm(item.cashRegister)" :disabled="cashRegisterItemListLoading")
                      v-icon(small) mdi-delete

        v-expansion-panel(:class="{'d-none' : cashRegisterItemListRegistered.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("на_учёте_в_налоговой_активные") }}
          v-expansion-panel-content
            v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListRegistered" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-avatar(v-if="!$vuetify.breakpoint.xsOnly")
                  v-icon(:color="item.cashRegister.id == cashRegister.id ? 'primary' : null") mdi-cash-register
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}
                v-list-item-action
                  v-list-item-subtitle(v-if="item.cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked(item.cashRegister) !== null && hoursLeftTillBlocked(item.cashRegister) >= 0") {{ $t('осталось_ч', { hours: hoursLeftTillBlocked(item.cashRegister) }) }}
                  v-list-item-subtitle(v-if="item.cashRegister.fdoMode === 'BLOCKED'") {{ $t("заблокирована") }}
                  v-chip(x-small :color="item.cashRegister.fdoMode === 'ONLINE' ? 'success' : (item.cashRegister.fdoMode === 'OFFLINE' ? 'warning' : (item.cashRegister.fdoMode === 'BLOCKED' ? 'error' : null))") {{ item.cashRegister.fdoMode }}

        v-expansion-panel(:class="{'d-none' : cashRegisterItemListTrial.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("демонстрационные") }}
          v-expansion-panel-content
            v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListTrial" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-avatar(v-if="!$vuetify.breakpoint.xsOnly")
                  v-icon mdi-cash-register
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}

        v-expansion-panel
          v-expansion-panel-header.v-subheader(@click="fetchCashRegisterDeregisteredList()") {{ $t("архив_деактивированные") }}
            v-progress-linear(:active="cashRegisterDeregisteredLoading" :indeterminate="cashRegisterDeregisteredLoading" absolute bottom)
          v-expansion-panel-content
            v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListDeregistered" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-avatar(v-if="!$vuetify.breakpoint.xsOnly")
                  v-icon(:color="item.cashRegister.id == cashRegister.id ? 'primary' : null") mdi-cash-register
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}
                  v-list-item-subtitle {{ item.cashRegister.registrationDate | moment("ll") }} - {{ item.cashRegister.deregistrationDate | moment("ll") }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    cashRegisterItemListLoading: false,
    cashRegisterDeregisteredLoading: false,

    otherExpansionPanels: [],

    newExpansionPanels: [],
    newCashRegisterName: '',
    newCashRegisterLoading: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      cashRegisterItemList: state => state.cashRegisters.cashRegisterItemList,
      cashRegisterItemListRegistered: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'REGISTERED'),
      cashRegisterItemListNew: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'NEW'),
      cashRegisterItemListTrial: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'TRIAL'),
      cashRegisterItemListDeregistered: state => state.cashRegisters.cashRegisterDeregisteredItemList,
    }),
  },

  watch: {
    value(val) {
      if (val) {
        this.init()
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchUserCashRegisterList: 'cashRegisters/fetchUserCashRegisterList',
      fetchUserCashRegisterDeregisteredList: 'cashRegisters/fetchUserCashRegisterDeregisteredList',
      createNewCashRegister: 'cashRegisters/createNewCashRegister',
      deleteNewCashRegister: 'cashRegisters/deleteNewCashRegister',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      copyToClipboard: 'tools/copyToClipboard',
      setDrawer: 'tools/setDrawer',
      setKeyListenerStatus: 'tools/setKeyListenerStatus',
    }),

    hoursLeftTillBlocked(cashRegister) {
      return cashRegister && cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    init() {
      this.newExpansionPanels = []
      this.otherExpansionPanels = [0, 1]
      if (this.cashRegisterItemListTrial.length > 0) {
        this.otherExpansionPanels = [0, 1, 2]
      }
      this.cashRegisterSwitchDialog = true
      if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
      this.cashRegisterItemListLoading = true
      this.fetchUserCashRegisterList().then(() => {
        this.cashRegisterItemListLoading = false
      }).catch(() => {
        this.cashRegisterItemListLoading = false
      })
    },

    toActivation(item) {
      this.$emit('input', false)
      this.setKeyListenerStatus(true)
      this.$router.push(`/kkm/activation/${item.cashRegister.id}`)
    },

    fetchCashRegisterDeregisteredList() {
      if (!this.otherExpansionPanels.includes(3)) {
        this.otherExpansionPanels.push(3)
        this.cashRegisterDeregisteredLoading = true
        this.fetchUserCashRegisterDeregisteredList().then(() => {
          this.otherExpansionPanels.push(3)
          this.cashRegisterDeregisteredLoading = false
        }).catch(() => {
          this.cashRegisterDeregisteredLoading = false
        })
      }
    },

    switchCashRegister(item) {
      this.$emit('cashRegisterLoading', true)
      this.selectCashRegister(item.cashRegister.id).then(() => {
        this.setCashRegisterPinChecked(false)
        this.$emit('cashRegisterLoading', false)
      }).catch(() => {
        this.$emit('cashRegisterLoading', false)
      })
      this.$emit('input', false)
    },

    createCashRegister() {
      this.newCashRegisterName = this.newCashRegisterName.trim()
      if (this.$refs.newCashRegisterNameForm.validate() && this.newCashRegisterName.length > 0) {
        this.newCashRegisterLoading = true
        const form = new FormData()
        form.append('name', this.newCashRegisterName)
        this.createNewCashRegister({ data: form }).then(() => {
          this.fetchUserCashRegisterList().then(() => {
            this.newCashRegisterLoading = false
            setTimeout(() => {
              this.newExpansionPanels = []
              this.otherExpansionPanels = [0]
              this.newCashRegisterName = ''
              this.$refs.newCashRegisterNameForm.reset()
            }, 200)
          }).catch(() => {
            this.newCashRegisterLoading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.data && error.response.data.code === 'CASH_REGISTER_NOT_REGISTERED_LIMIT') {
            this.showSnackbar({ message: this.$t('backend/CASH_REGISTER_NOT_REGISTERED_LIMIT', { limit: error.response.data.meta.LIMIT }) })
          }
          this.newCashRegisterLoading = false
        })
      }
    },

    deleteNewCashRegisterConfirm(сashRegister) {
      this.showConfirm({
        title: this.$t('вы_действительно_хотите_удалить_кассу', { сashRegisterName: сashRegister.name }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        resolveButtonColor: 'error',
        rejectButtonColor: 'gray',
      }).then(() => {
        this.cashRegisterItemListLoading = true
        // eslint-disable-next-line object-shorthand
        this.deleteNewCashRegister({ сashRegister: сashRegister }).then(() => {
          this.fetchUserCashRegisterList().then(() => {
            this.cashRegisterItemListLoading = false
          }).catch(() => {
            this.cashRegisterItemListLoading = false
          })
        }).catch(() => {
          this.cashRegisterItemListLoading = false
        })
      }).catch(() => {})
    },

    copy(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

  },
}
</script>

<style lang="stylus">
.new-cash-register-expansion-panels
  .v-expansion-panel--active > .v-expansion-panel-header
    min-height auto !important
.other-cash-register-expansion-panels
  .v-expansion-panel-content__wrap
    padding 0 !important
  .v-expansion-panel--active, .v-expansion-panel--active + .v-expansion-panel
    margin-top 0 !important
  .v-expansion-panel--active > .v-expansion-panel-header
    min-height auto !important
.new-cash-register-toolbar
  .v-toolbar__content
    padding-left 4px !important
</style>
