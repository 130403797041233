import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import store from '../store'
import SignIn from '../components/auth/SignIn.vue'
import Main from '../components/Main.vue'
import TicketDetails from '../components/ticket/TicketDetails.vue'
import TicketDraft from '../components/ticket/TicketDraft.vue'
import KaspiPay from '../components/ticket/KaspiPay.vue'
import TicketPrint from '../components/ticket/TicketPrint.vue'
import Shift from '../components/shift/Shift.vue'
import Shifts from '../components/shift/Shifts.vue'
import ZXReport from '../components/shift/ZXReport.vue'
import SectionsReport from '../components/shift/SectionsReport.vue'
import OperatorsReport from '../components/shift/OperatorsReport.vue'
import Reports from '../components/report/Reports.vue'
import FiscalReport from '../components/report/FiscalReport.vue'
import Passport from '../components/settings/Passport.vue'
import CashRegister from '../components/settings/CashRegister.vue'
import Token from '../components/settings/Token.vue'
import Pincode from '../components/settings/Pincode.vue'
import Activation from '../components/settings/Activation.vue'
import Restore from '../components/settings/Restore.vue'
import Ui from '../components/settings/Ui.vue'
import Printer from '../components/settings/Printer.vue'
import PaymentProviders from '../components/settings/PaymentProviders.vue'
import KaspiPaySettings from '../components/settings/KaspiPay.vue'
import HalykPosSettings from '../components/settings/HalykPos.vue'
import AlfaPaySettings from '../components/settings/AlfaPay.vue'
import MoyuchetSettings from '../components/settings/Moyuchet.vue'
import Positions from '../components/settings/Positions.vue'
import AccessApi from '../components/settings/AccessApi.vue'
import Profile from '../components/settings/Profile.vue'

import TJThemeRoutes from '../TJTheme/router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/signin',
    name: 'SignIn',
    meta: { requiresAuth: false },
    component: SignIn,
  },
  {
    path: '/zi',
    component: Main,
    children: [
      {
        path: '',
        name: localStorage.getItem('rekassa.kz-ui-main-screen') === null || localStorage.getItem('rekassa.kz-ui-main-screen') === 'CALCULATOR' ? 'MainCalculator' : 'MainPositions',
        component: () => {
          if (localStorage.getItem('rekassa.kz-ui-main-screen') === null || localStorage.getItem('rekassa.kz-ui-main-screen') === 'CALCULATOR') {
            return import('../components/ticket/Calculator.vue')
          }
          return import('../components/ticket/parts/TicketDetailsMain.vue')
        },
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports,
      },
      {
        path: '/passport',
        name: 'Passport',
        component: Passport,
      },
      {
        path: '/ui',
        name: 'Ui',
        component: Ui,
      },
      {
        path: '/printer',
        name: 'Printer',
        component: Printer,
      },
    ],
  },
  {
    path: '/details',
    name: 'TicketDetails',
    component: TicketDetails,
  },
  {
    path: '/draft',
    name: 'TicketDraft',
    component: TicketDraft,
  },
  {
    path: '/pay/kaspi',
    name: 'KaspiPay',
    component: KaspiPay,
  },
  {
    path: '/access-api',
    name: 'AccessApi',
    component: AccessApi,
  },
  {
    path: '/print/:cashRegisterId/:ticketId',
    name: 'TicketPrint',
    component: TicketPrint,
    props: true,
  },
  {
    path: '/shifts/:cashRegisterId',
    name: 'Shifts',
    component: Shifts,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber',
    name: 'Shift',
    component: Shift,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/zxreport',
    name: 'ZXReport',
    component: ZXReport,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/sections',
    name: 'SectionsReport',
    component: SectionsReport,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/operators',
    name: 'OperatorsReport',
    component: OperatorsReport,
  },
  {
    path: '/kkm',
    name: 'CashRegister',
    component: CashRegister,
  },
  {
    path: '/kkm/token',
    name: 'Token',
    component: Token,
  },
  {
    path: '/kkm/pincode',
    name: 'Pincode',
    component: Pincode,
  },
  {
    path: '/kkm/activation/:newCashRegisterId',
    name: 'Activation',
    component: Activation,
  },
  {
    path: '/kkm/restore',
    name: 'Restore',
    component: Restore,
  },
  {
    path: '/kkm/payments',
    name: 'PaymentProviders',
    component: PaymentProviders,
  },
  {
    path: '/kkm/pay/kaspi',
    name: 'KaspiPaySettings',
    component: KaspiPaySettings,
  },
  {
    path: '/kkm/pos/halyk',
    name: 'HalykPosSettings',
    component: HalykPosSettings,
  },
  {
    path: '/kkm/pay/alfa',
    name: 'AlfaPaySettings',
    component: AlfaPaySettings,
  },
  {
    path: '/kkm/moyuchet',
    name: 'MoyuchetSettings',
    component: MoyuchetSettings,
  },
  {
    path: '/kkm/positions',
    name: 'Positions',
    component: Positions,
  },
  {
    path: '/reports/fiscal',
    name: 'FiscalReport',
    component: FiscalReport,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // ...routes,
    ...TJThemeRoutes,
  ],
})

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.state.auth.user !== null
//   if (to.meta.requiresAuth !== false && !isAuthenticated) {
//     // Cordova
//     if (window.cordova !== undefined) {
//       if (!navigator.onLine) {
//         store.dispatch('tools/setConnectionWarning', true)
//       }
//       window.FirebasePlugin.getCurrentUser((user) => {
//         if (user) {
//           store.dispatch('auth/setUser', user).then(() => {
//             store.dispatch('cashRegisters/init', to).then(() => {
//               window.scrollTo(0, 0)
//               next()
//             })
//           })
//         } else {
//           window.scrollTo(0, 0)
//           next('/signin')
//         }
//       }, () => {
//         window.scrollTo(0, 0)
//         next('/signin')
//       })
//     // Web
//     } else {
//       firebase.auth().onAuthStateChanged((user) => {
//         if (user) {
//           store.dispatch('auth/setUser', user).then(() => {
//             store.dispatch('cashRegisters/init', to).then(() => {
//               window.scrollTo(0, 0)
//               next()
//             })
//           })
//         } else {
//           window.scrollTo(0, 0)
//           next('/signin')
//         }
//       })
//     }
//   } else {
//     window.scrollTo(0, 0)
//     next()
//   }
// })

// // Track screens
// router.afterEach((to) => {
//   store.dispatch('tools/firebaseAnalyticsSetScreenName', to.name)
// })

export default router
