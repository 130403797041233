<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("смена") }} №{{ shiftNumber }}
      v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)
      v-spacer
      template(v-if="shift")
        v-btn(:disabled="loading" :to="`/shifts/${cashRegister.id}/${shiftNumber}/zxreport`" text) {{ shift.open ? 'X' : 'Z' }}-{{ $t("отчёт") }}
        v-btn(id="zxreport_button" icon)
          v-icon mdi-dots-vertical
          v-menu(bottom left activator="#zxreport_button")
            v-list
              v-list-item(:to="`/shifts/${cashRegister.id}/${shiftNumber}/sections`")
                v-list-item-content
                  v-list-item-title {{ $t("отчёт_по_секциям") }}
                v-list-item-icon
                  v-icon mdi-format-list-bulleted
              v-list-item(:to="`/shifts/${cashRegister.id}/${shiftNumber}/operators`")
                v-list-item-content
                  v-list-item-title {{ $t("отчёт_по_кассирам") }}
                v-list-item-icon
                  v-icon mdi-cash-register

    v-main(flat)
      v-container.pa-0

        div.px-4(v-if="shift === null")
          v-skeleton-loader.mt-6(type="text" boilerplate style="width: 100px;")
          v-skeleton-loader.mt-7(type="heading" boilerplate style="width: 300px;")
          v-skeleton-loader.mt-5.mb-3(type="text" boilerplate style="width: 300px;")

        v-card(outlined v-if="shift")
          v-list-item(three-line)
            v-list-item-content
              h1.overline {{ $t("выручка_за_смену") }}
                | &nbsp;({{ shift.openTime | moment("DD.MM.YYYY HH:mm") }}
                template(v-if="shift.closeTime") &nbsp;- {{ shift.closeTime | moment("DD.MM.YYYY HH:mm") }}
                | )
              v-list-item-title.my-2.display-2.font-weight-medium {{ shift.revenue | numeral('0,0.[00]') | beautiful-minus }} ₸
              v-list-item-subtitle
                template(v-if="shift.data.operations")
                  template(v-for="(operation, index) in shift.data.operations" v-if="getNumberFromBillsAndCoins(operation.sum) > 0")
                    | {{ index !== 0 ? '&nbsp;&bull;&nbsp;' : ''}} {{ $t('operationType.' + operation.operation) }}: {{ getNumberFromBillsAndCoins(operation.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸

                //- template(v-if="shift.data.discounts")
                //-   | &nbsp;&bull;&nbsp;
                //-   template(v-for="(discount, index) in shift.data.discounts" v-if="getNumberFromBillsAndCoins(discount.sum) > 0")
                //-     | {{ $t('operationType.' + discount.operation) }} (скидка): {{ getNumberFromBillsAndCoins(discount.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸
                //-     template(v-if="index !== shift.data.discounts.length - 1") &nbsp;&bull;&nbsp;

                //- template(v-if="shift.data.markups")
                //-   | &nbsp;&bull;&nbsp;
                //-   template(v-for="(markup, index) in shift.data.markups" v-if="getNumberFromBillsAndCoins(markup.sum) > 0")
                //-     | {{ $t('operationType.' + markup.operation) }} (наценка): {{ getNumberFromBillsAndCoins(markup.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸
                //-     template(v-if="index !== shift.data.markups.length - 1") &nbsp;&bull;&nbsp;

                div.mt-1(v-if="shift.data.moneyPlacements")
                  template(v-for="(moneyPlacement, index) in shift.data.moneyPlacements" v-if="getNumberFromBillsAndCoins(moneyPlacement.operationsSum) > 0")
                    | {{ index !== 0 ? '&nbsp;&bull;&nbsp;' : ''}} {{ $t('operationType.' + moneyPlacement.operation) }}: {{ getNumberFromBillsAndCoins(moneyPlacement.operationsSum) | numeral('0,0.[00]') | beautiful-minus }} ₸

        v-progress-linear(height="1" color="primary" value="100")

        div.px-4(v-if="ticketList.length === 0")
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
          v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)

        v-list(two-line subheader)
          v-list-item-group
            v-list-item(v-for="(ticket, $index) in ticketList" :key="'r_' + $index" :to="`/print/${cashRegister.id}/${ticket.id}`" :inactive="ticket.command === 'COMMAND_CANCEL_TICKET'")
              template(v-if="ticket.command === 'COMMAND_CANCEL_TICKET'")
                v-list-item-icon
                  v-icon mdi-file-cancel-outline
                v-list-item-content(:class="ticket.command === 'COMMAND_CANCEL_TICKET' ? 'cancelled' : ''")
                  v-list-item-title {{ $t("предыдущая_сумма") }}
                  v-list-item-subtitle {{ $t("отмена_последнего_чека") }}
                v-list-item-action
                  v-list-item-action-text
                    v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                    | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                  v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                  v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                  v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                  v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check
              template(v-if="ticket.command === 'COMMAND_TICKET'")
                v-list-item-icon(v-if="ticket.extra.attributes && ticket.extra.attributes.paymentType === 'KASPI_PAY'")
                  v-icon(color="red") mdi-qrcode-plus
                v-list-item-icon(v-else-if="ticket.extra.attributes && (ticket.extra.attributes.paymentType === 'HALYK_POS' || ticket.extra.attributes.paymentType === 'ALFA_PAY')")
                  v-icon mdi-contactless-payment
                v-list-item-icon(v-else)
                  v-icon {{ dictionary.operationType[ticket.data.ticket.operation].icon }}
                v-list-item-content(:class="ticket.cancelledBy ? 'cancelled' : ''")
                  v-list-item-title {{ ticket.total | numeral('0,0.[00]') | beautiful-minus }} ₸
                  v-list-item-subtitle
                    | №{{ ticket.shiftDocumentNumber }} &bull; {{ dictionary.operationType[ticket.data.ticket.operation].title }} (
                    template(v-for="(payment, paymentIndex) in ticket.data.ticket.payments")
                      | {{ $t("paymentType." + payment.type + "_short") }}: {{ getNumberFromBillsAndCoins(payment.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸
                      template(v-if="paymentIndex !== ticket.data.ticket.payments.length - 1") ,&nbsp;
                    | )
                v-list-item-action
                  v-list-item-action-text
                    v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                    | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                  v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                  v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                  v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                  v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check
              template(v-if="ticket.command === 'COMMAND_MONEY_PLACEMENT'")
                v-list-item-icon
                  v-icon {{ dictionary.operationType[ticket.data.moneyPlacement.operation].icon }}
                v-list-item-content
                  v-list-item-title {{ ticket.total | numeral('0,0.[00]') | beautiful-minus }} ₸
                  v-list-item-subtitle №{{ ticket.shiftDocumentNumber }} &bull; {{ dictionary.operationType[ticket.data.moneyPlacement.operation].title }}
                v-list-item-action
                  v-list-item-action-text
                    v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                    | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                  v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                  v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                  v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                  v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check

        div.my-4.text-center(v-if="totalPages > 1")
          v-pagination(v-model="ticketPage.current" :length="totalPages" :total-visible="5")

</template>
<script>
import { mapActions, mapState } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    shift: null,
    ticketList: [],
    loading: false,
    totalPages: 0,
    shiftNumber: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      ticketPage: state => state.cashRegisters.ticketPage,
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    'ticketPage.current': function () {
      this.fetchTicketList()
    },
  },

  created() {
    this.shiftNumber = this.$route.params.shiftNumber
    this.fetchShift()
  },

  methods: {
    ...mapActions({
      fetchShiftTicketListByShiftNumber: 'cashRegisters/fetchShiftTicketListByShiftNumber',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      showSnackbar: 'tools/showSnackbar',
    }),

    fetchShift() {
      this.loading = true
      this.fetchShiftByNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
        this.fetchTicketList(result.data)
      }).catch((error) => {
        if (error && error.response && error.response.status === 404) {
          this.showSnackbar({ message: this.$t('смена_не_существует') })
          this.$router.push('/')
        }
      })
    },

    fetchTicketList(shift) {
      this.loading = true
      this.ticketList = []
      this.fetchShiftTicketListByShiftNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
        if (shift) {
          this.shift = shift
          this.shift = { ...this.shift, revenue: this.shift.data && this.shift.data.revenue ? (this.shift.data.revenue.isNegative ? -1 : 1) * this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) : 0 }
        }
        this.totalPages = result.data && result.data.page ? result.data.page.totalPages : 0
        this.ticketList = result.data._embedded.tickets
          .map((ticket) => {
            if (ticket.command === 'COMMAND_TICKET' && ticket.data && ticket.data.ticket && ticket.data.ticket.amounts && ticket.data.ticket.amounts.total) {
              return { ...ticket, total: this.getNumberFromBillsAndCoins(ticket.data.ticket.amounts.total) * (this.dictionary.operationType[ticket.data.ticket.operation].negative ? -1 : 1) }
            }
            if (ticket.command === 'COMMAND_MONEY_PLACEMENT' && ticket.data && ticket.data.moneyPlacement && ticket.data.moneyPlacement.sum) {
              return { ...ticket, total: this.getNumberFromBillsAndCoins(ticket.data.moneyPlacement.sum) * (this.dictionary.operationType[ticket.data.moneyPlacement.operation].negative ? -1 : 1) }
            }
            return { ...ticket, total: 0 }
          })
          .sort((a, b) => b.id - a.id)
        this.loading = false
      })
    },

    localStorageGetItem(name) {
      return localStorage.getItem(name)
    },
  },
}
</script>
<style scoped>
.cancelled {
  text-decoration: line-through;
}
</style>
