const helpUrls = {
  BLOCKED: 'https://link.rekassa.kz/cash-register-blocked',
  NO_KKM_ID: 'https://link.rekassa.kz/cash-register-unknown-id',
  UNKNOWN_ID: 'https://link.rekassa.kz/cash-register-unknown-id',
  INVALID_TOKEN: 'https://link.rekassa.kz/invalid-token',
  UNKNOWN_ERROR: 'https://link.rekassa.kz/contact-support',
  PROTOCOL_ERROR: 'https://link.rekassa.kz/contact-support',
  WRONG_PASSWORD: 'https://link.rekassa.kz/wrong-password',
  NOT_ENOUGH_CASH: 'https://link.rekassa.kz/not-enough-cash',
  UNKNOWN_COMMAND: 'https://link.rekassa.kz/contact-support',
  UNSUPPORTED_OFD: 'https://link.rekassa.kz/unsupported-ofd',
  PERMISSION_ERROR: 'https://link.rekassa.kz/contact-support',
  CANT_CANCEL_TICKET: 'https://link.rekassa.kz/cant-cancel-ticket',
  ROLE_ALREADY_EXISTS: 'https://help.rekassa.kz',
  UNSUPPORTED_COMMAND: 'https://help.rekassa.kz',
  DATA_INTEGRITY_ERROR: 'https://link.rekassa.kz/contact-support',
  CASH_REGISTER_BLOCKED: 'https://link.rekassa.kz/cash-register-blocked',
  INVALID_CONFIGURATION: 'https://link.rekassa.kz/contact-support',
  INVALID_RETRY_REQUEST: 'https://link.rekassa.kz/contact-support',
  USER_PERMISSION_ERROR: 'https://link.rekassa.kz/contact-support',
  INCORRECT_REQUEST_DATA: 'https://link.rekassa.kz/contact-support',
  INVALID_REQUEST_NUMBER: 'https://link.rekassa.kz/contact-support',
  CASH_REGISTER_WRONG_OFD_ID: 'https://help.rekassa.kz',
  CASH_REGISTER_WRONG_STATUS: 'https://link.rekassa.kz/contact-support',
  OPEN_SHIFT_TIMEOUT_EXPIRED: 'https://link.rekassa.kz/shift-expired',
  CASH_REGISTER_WRONG_OFD_MODE: 'https://link.rekassa.kz/cash-register-wrong-ofd-mode',
  CASH_REGISTER_ALREADY_ACTIVATED: 'https://link.rekassa.kz/cash-register-already-registered',
  SERVICE_TEMPORARILY_UNAVAILABLE: 'https://link.rekassa.kz/contact-support',
  CASH_REGISTER_ALREADY_REGISTERED: 'https://link.rekassa.kz/cash-register-already-registered',
  CASH_REGISTER_WRONG_SERIAL_NUMBER: 'https://link.rekassa.kz/cash-register-already-registered',
  CASH_REGISTER_NOT_REGISTERED_LIMIT: 'https://link.rekassa.kz/cash-register-trial-count-limit-reached',
  CASH_REGISTER_SHIFT_PERIOD_EXPIRED: 'https://link.rekassa.kz/shift-expired',
  CASH_REGISTER_TRIAL_PERIOD_EXPIRED: 'https://link.rekassa.kz/cash-register-trial-period-expired',
  CASH_REGISTER_OFFLINE_PERIOD_EXPIRED: 'https://link.rekassa.kz/cash-register-offline-period-expired',
  CASH_REGISTER_SHOULD_HAVE_SHIFT_OPEN: 'https://link.rekassa.kz/cash-register-should-have-shift-open',
  CASH_REGISTER_SHOULD_HAVE_SHIFT_CLOSED: 'https://link.rekassa.kz/shift-expired',
  CASH_REGISTER_TRIAL_COUNT_LIMIT_REACHED: 'https://link.rekassa.kz/cash-register-trial-count-limit-reached',
  CASH_REGISTER_TRIAL_OPERATIONS_LIMIT_REACHED: 'https://link.rekassa.kz/cash-register-trial-operations-limit-reached',
}

export default helpUrls
