<template lang="pug">
v-tab.pr-0.pl-1: v-btn(block color="primary" outlined)
  slot
</template>
<script>

export default {
  components: {
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
  }),

  computed: {
    // isEmpty() {
    //   return this.empty && !this.totalSum
    // },
  },

  watch: {},

  methods: {
    toggle() {
    },
    // ...mapActions({
    //   checkout: 'tjtheme/checkout',
    // }),
    // hasSecondButton() {
    //   return !!this.$slots.default
    // },
  },
}
</script>
<style lang="stylus">

</style>
