import './styluses/main.styl'
import './styluses/tjtheme.styl'
import './styluses/fonts.css'

import '@formatjs/intl-datetimeformat/polyfill-force'
import '@formatjs/intl-datetimeformat/locale-data/ru'
import '@formatjs/intl-datetimeformat/locale-data/kk'
import '@formatjs/intl-datetimeformat/add-all-tz'

import Vue from 'vue'
import numeral from 'numeral'
// import 'numeral/locales'
import vueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import 'moment/locale/kk'
import { VueMaskDirective } from 'v-mask'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
// import vuetify from './plugins/reset'
import store from './store'
import i18n from './i18n'
import rules from './utils/rules'

import './TJTheme/filters'
import './TJTheme/directives'
import './TJTheme/utils'
import './TJTheme/ui'
import './TJTheme/mixins'

Vue.config.productionTip = false

function configVue() {
  Vue.use(vueMoment, { moment })

  // numeral.locale('en')
  // numeral.localeData().delimiters.thousands = ' '
  numeral.fn._format = numeral.fn.format
  numeral.fn.format = function format(a, b) { return numeral.fn._format.call(this, a, b || numeral._.defaultRoundingFunction) }
  numeral._.defaultRoundingFunction = Math.floor
  Vue.filter('numeral', (value, format = '0,0') => numeral(value).format(format))
  Vue.filter('beautiful-minus', (value) => value.replace('-', '− '))
  Vue.filter('phone', (value) => (value && value.length === 12 ? value.replace(/[^0-9]/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') : value))

  Vue.directive('mask', VueMaskDirective)

  Vue.prototype.rules = rules

  Vue.prototype.$goBack = () => {
    window.history.length > 1 ? router.go(-1) : router.push('/')
  }

  Vue.prototype.$openLink = (link, target) => {
    if (window.cordova !== undefined) {
      window.cordova.InAppBrowser.open(link, target, 'hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,toolbarcolor=#272727,closebuttoncolor=#ffffff')
    } else {
      window.open(link, target)
    }
  }

  // TJEY Global Error Handler
  // Vue.config.errorHandler = (err) => {
  //   alert(err)
  // }

  Vue.prototype.$isCordova = () => window.cordova !== undefined

  Vue.prototype.$isAndroid = () => window.cordova !== undefined && window.device.platform === 'Android'
}

const vue = new Vue({
  router,
  vuetify,
  store,
  i18n,
  data: () => ({
    dialog: [],
    safeArea: null,
    splash: false,
  }),
  mounted() {
    const style = getComputedStyle(document.documentElement)
    const spt = getComputedStyle(document.documentElement).getPropertyValue('--spt')
    this.safeArea = {
      bottom: style.getPropertyValue('--spb'),
      left: style.getPropertyValue('--spl'),
      right: style.getPropertyValue('--spr'),
      top: spt,
    }
  },
  render: (h) => h(App),

})

configVue()

// initAnalytics()
// initRemoteConfig()

vue.$mount('#app')
