<template lang="pug">
span.animatedNumber(v-if="!hide") {{displayNumber | money}}  {{suffix}}
</template>

<script>
export default {
  name: 'AnimatedTotal',
  props: {
    suffix: { type: String, default: '₸' },
    hide: { type: Boolean, default: true },
    number: { type: Number, default: 0 },
  },
  data() {
    return {
      displayNumber: 0,
      interval: false,
    }
  },
  /* ready(){
    console.log('ready animated number',this.number);
    this.displayNumber = this.number ? this.number : 0;
  }, */
  watch: {
    number(val) {
      // console.log('watch animated number',val);
      clearInterval(this.interval)
      if (val !== null && !Number.isNaN(val) && val !== this.displayNumber) {
        // console.info('animated-number',val);
        // return;

        this.interval = window.setInterval(() => {
          if (this.displayNumber !== val) {
            let change = (val - this.displayNumber) / 2
            change = change >= 0 ? Math.ceil(change) : Math.floor(change)
            this.displayNumber += change
          } else {
            clearInterval(this.interval)
          }
        }, 20)
      }
    },

  },
  mounted() {
    // console.log('mounted animated number',this.number);
    this.displayNumber = this.number ? this.number : 0
  },
}
</script>
