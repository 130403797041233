import Vue from 'vue'
import List from './t-list.vue'
import Card from './t-card.vue'

import ListItem from './t-list-item.vue'

import BtnCard from './t-btncard.vue'
import BtnHero from './t-btn.vue'

import DialogBottom from './t-dialog-bottom.vue'
import Dialog from './t-dialog.vue'

import PageLayout from './t-page-layout.vue'

import NavSlides from './t-navslides.vue'

import Collapse from './t-collapse.vue'
import Input from './t-input.vue'
import InputList from './t-list-item-input.vue'
import InputHint from './t-list-item-input-hint.vue'

import AnimNum from './t-anim-num.vue'
import AnimNumCss from './t-anim-numcss.vue'

import Tile from './t-tile.vue'
import Alert from './t-alert.vue'

import Popover from './t-popover.vue'

import Window from './t-window.vue'
import WindowBody from './t-window-body.vue'

import TransitionOverlay from './t-transition-overlay.vue'

import tversion from './t-version.vue'

Vue.component('t-version', tversion)

Vue.component('t-popover', Popover)
Vue.component('t-card', Card)
Vue.component('t-list', List)
Vue.component('t-input', Input)
Vue.component('t-list-item-input', InputList)
Vue.component('t-list-item-input-hint', InputHint)
Vue.component('t-window', Window)
Vue.component('t-window-body', WindowBody)
Vue.component('t-tile', Tile)
Vue.component('t-alert', Alert)

Vue.component('t-list-item', ListItem)

Vue.component('t-collapse', Collapse)

Vue.component('t-btncard', BtnCard)
Vue.component('t-btn', BtnHero)

Vue.component('t-dialog-bottom', DialogBottom)
Vue.component('t-dialog', Dialog)
Vue.component('PageLayout', PageLayout)

Vue.component('t-navslides', NavSlides)

Vue.component('t-anim-num', AnimNum)
Vue.component('t-anim-numcss', AnimNumCss)

Vue.component('t-transition-overlay', TransitionOverlay)
