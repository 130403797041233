<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/kkm')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("смена_пин-кода") }}

    v-main(flat)
      v-container(style="max-width: 450px")
        v-row
          v-col
            v-list.v-list--fulltext.gray-background-list
              v-subheader.mb-5 {{ $t("для_смены_пин-кода_dotdotdot") }}
              v-form.px-4(ref="pincodeForm" @submit.prevent="showPinpad()")
                v-text-field(:label="$t('токен')" v-model="token" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="tokenError = null" :error-messages="tokenError" maxlength="9" validate-on-blur clearable)
                v-text-field(:label="$t('пин-код')" v-model="pincode" type="tel" :rules="[pincodeRules.length, rules.numbersOnly]" autocomplete="off" maxlength="4" validate-on-blur clearable)
                v-text-field(:label="$t('повторите_пин-код')" v-model="pincodeRepeat" type="tel" :rules="[pincodeRules.length, rules.numbersOnly, pincodeRules.same(pincodeRepeat, pincode)]" autocomplete="off" maxlength="4" validate-on-blur clearable)
                v-btn.my-3(color="primary" block :disabled="loading" @click="changePincode()") {{ $t("изменить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import store from '../../store/index'
import i18n from '../../i18n/index'

export default {
  data: () => ({
    loading: false,

    token: null,
    tokenError: null,

    pincode: null,
    pincodeRepeat: null,

    pincodeRules: {
      length: (v) => (!!v && v.length === 4) || i18n.t('требуется_4-х_значный_пин-код'),
      same: (pin, v) => (!!v && v === pin) || i18n.t('пин-код_не_совпадает'),
    },
  }),

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  methods: {
    ...mapActions({
      setPassword: 'cashRegisters/setPassword',
      showSnackbar: 'tools/showSnackbar',
    }),

    changePincode() {
      if (this.$refs.pincodeForm.validate()) {
        this.loading = true
        const form = new FormData()
        form.append('token', this.token)
        form.append('password', this.pincode)
        this.setPassword({
          cashRegister: this.cashRegister,
          data: form,
        }).then(() => {
          this.loading = false
          this.showSnackbar({ message: this.$t('пин-код_успешно_изменен') })
          this.$router.push('/kkm')
        }).catch((error) => {
          if (error && error.response && error.response.data && error.response.data.code === 'INVALID_TOKEN') {
            this.tokenError = this.$t('backend/INVALID_TOKEN')
          }
          this.loading = false
        })
      }
    },
  },
}
</script>
