<template lang="pug">
v-list.py-0(v-bind="$attrs")
  v-subheader(v-if="title") {{title}}
  slot
</template>
<script>
export default {
  props: ['title'],
}
</script>
<style lang="stylus"></style>
