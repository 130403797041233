/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-unresolved */
import Vue from 'vue'
import store from '@/store/index'

import playSound from '@/plugins/sound'

let soundDisabled = false

store.watch(() => store.getters.sound.disabled, (v) => {
  soundDisabled = v
  if (v) {
    console.debug('--> sound disabled', v)
  } else {
    console.debug('--> sound enabled', v)
  }
}, { immediate: true })

Vue.directive('sound', {
  // isLiteral: true,
  // Add Event Listener on mounted.
  play(sound = 'snap') {
    // console.log('click directuve', sound, this)
    if (!soundDisabled && !this.hasAttribute('disabled')) playSound(sound)
  },
  bind(el, binding, vnode) {
    // console.log('bind', binding)
    let sound
    if (binding.value) {
      sound = binding.value
    } else {
      sound = Object.keys(binding.modifiers)
      sound = sound.length ? sound[0] : undefined
    }
    el.addEventListener('click', binding.def.play.bind(el, sound), false)
  },
  // Remove Event Listener on destroy.
  unbind(el, binding) {
    el.removeEventListener('click', binding.def.play)
  },
  click() {

  },
})
