<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(:disabled="registerLoading" icon @click="goBack()")
        v-icon mdi-arrow-left
      v-spacer

      template(v-if="onlyOne(kaspiPayAllowed, halykPosAllowed, alfaPayAllowed)")
        v-btn.payment(v-if="kaspiPayAllowed" :disabled="registerLoading || requestSent" @click="kaspiPay()" rounded elevation="0" width="90")
          v-img(width="70" src="static/kaspi-pay-white.svg")
        v-btn.payment.ml-1(v-if="halykPosAllowed" :disabled="registerLoading || requestSent" @click="m4bankShowPinpad('HALYK_POS')" rounded elevation="0" width="90")
          v-img(width="50" src="static/halyk-pos-white.svg")
        v-btn.payment.ml-1(v-if="alfaPayAllowed" :disabled="registerLoading || requestSent" @click="m4bankShowPinpad('ALFA_PAY')" rounded elevation="0" width="90")
          v-img(width="50" height="32"  src="static/alfa-pay-white.svg")
      template(v-else)
        v-btn.payment(v-if="kaspiPayAllowed" :disabled="registerLoading || requestSent" @click="kaspiPay()" rounded elevation="0" width="90") Kaspi Pay
        v-btn.payment.ml-1(v-if="halykPosAllowed" :disabled="registerLoading || requestSent" @click="m4bankShowPinpad('HALYK_POS')" rounded elevation="0" width="90") Halyk Pos
        v-btn.payment.ml-1(v-if="alfaPayAllowed" :disabled="registerLoading || requestSent" @click="m4bankShowPinpad('ALFA_PAY')" rounded elevation="0" width="90") Alfa Pay

      v-progress-linear(:active="registerLoading" :indeterminate="registerLoading" absolute bottom)

    re-receipt(:ticket="ticket" :cashRegister="cashRegister" :organization="organization" :ticketLocale="getLocale()")

    v-bottom-navigation(app dark)
      //- v-bottom-sheet()
      //-   template(v-slot:activator="{ on }")
      //-     div(style="display: inline-block; width: 80px;")
      //- v-spacer
      v-btn.action-btn(block :disabled="registerLoading" @click="showPinpad()")
        .title {{ selectedOperationType ? `${$t('operationType.' + selectedOperationType)} ${$options.filters.numeral(totalSum, '0,0.[00]')} ₸` : '-----------------' }}
      //- v-spacer
      v-bottom-sheet(v-model="operationTypeDialog")
        template(v-slot:activator="{ on }")
          v-btn.option-btn(:disabled="registerLoading || requestSent" icon v-on="on" :ripple="false")
            v-icon mdi-chevron-up
        v-list(subheader)
          v-subheader {{ $t("тип_операции") }}
          v-list-item(v-for="(operationType, $index) in filteredOperationTypes" :key="operationType.title" @click="selectOperationType(operationType)")
            v-list-item-avatar
              v-avatar(size="32px" tile)
                v-icon {{ operationType.icon }}
            v-list-item-title {{ $index + 1 }}. {{ operationType.title }}
          v-list-item.mt-3(color="primary" @click="operationTypeDialog = false")
            v-list-item-avatar
              v-avatar(size="32px" tile)
                v-icon mdi-close
            v-list-item-title {{ $t("отмена") }}

    re-pinpad(v-model="cashRegisterPinpadDialog" :title="$t('фискализация_чека')" :subtitle="$t('введите_пин-код')" :text="$t('для_фискализации')" :loading="cashRegisterPinpadLoading" :errorText.sync="cashRegisterPinpadErrorMessage" @action="checkPin")
    re-pinpad(v-model="m4bankCashRegisterPinpadDialog" :title="$t('фискализация_чека')" :subtitle="$t('введите_пин-код')" :text="$t('для_фискализации')" :loading="m4bankCashRegisterPinpadLoading" :errorText.sync="m4bankCashRegisterPinpadErrorMessage" @action="m4bankCheckPin")

    v-dialog(v-model="m4bankErrorDialog" scrollable fullscreen persistent width="500")
      v-card
        v-container
          v-row.mt-10
            v-col(align="center")
              v-img(:src="$vuetify.theme.dark ? m4bankErrorImageUrl.dark : m4bankErrorImageUrl.light" max-width="220" eager)

          v-row.mt-10
            v-col
              .title.text-center {{ m4bankErrorText }}

          v-row.mt-10
            v-col(align="center")
              v-btn(large color="error" width="250" @click="m4bankErrorDialog = false") {{ $t("закрыть") }}

</template>
<script>
import fdo from 'kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { getLocale } from '../../i18n/index'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import store from '../../store/index'
import Receipt from './parts/Receipt.vue'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-receipt': Receipt,
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    m4bankErrorImageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_access_denied_re_awnf.svg',
      light: '/static/illustrations/rally/light/undraw_access_denied_re_awnf.svg',
    },

    uuidString: null,
    selectedOperationType: null,
    ticketRequest: null,
    registerLoading: false,
    operationTypeDialog: false,
    taken: null,

    cashRegisterPinpadDialog: false,
    cashRegisterPinpadLoading: false,
    cashRegisterPinpadErrorMessage: null,

    m4bankCashRegisterPinpadDialog: false,
    m4bankCashRegisterPinpadLoading: false,
    m4bankCashRegisterPinpadErrorMessage: null,

    requestSent: false,
    m4bankType: null,
    m4bankAttributes: null,
    m4bankErrorDialog: false,
    m4bankErrorText: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      cashRegisterPinChecked: state => state.cashRegisters.cashRegisterPinChecked,
      hasNFC: state => state.m4bank.hasNFC,
      hasAndroid8AndAbove: state => state.m4bank.hasAndroid8AndAbove,
    }),

    filteredOperationTypes() {
      return this.operationTypes.filter((operationType) => (this.preferences.buyMode ? true : (!(operationType.value === 'OPERATION_BUY' || operationType.value === 'OPERATION_BUY_RETURN'))))
    },

    ticket() {
      return {
        data: {
          ticket: this.ticketRequest.toJSON(),
        },
      }
    },

    totalSum() {
      return this.getNumberFromBillsAndCoins(this.$store.state.cashRegisters.ticketRequest.amounts.total)
    },

    kaspiPayAllowed() {
      return this.selectedOperationType === 'OPERATION_SELL' && this.configuration.kaspi && this.configuration.kaspi.kaspiPayConnected
    },

    halykPosAllowed() {
      return this.selectedOperationType === 'OPERATION_SELL' && this.configuration.halyk && this.configuration.halyk.halykPosConnected
    },

    alfaPayAllowed() {
      return this.selectedOperationType === 'OPERATION_SELL' && this.configuration.alfa && this.configuration.alfa.alfaPayConnected
    },
  },

  created() {
    this.uuidString = uuidv4()
    this.ticketRequest = this.$store.state.cashRegisters.ticketRequest
    this.selectedOperationType = this.ticketRequest.operation === 2 ? 'OPERATION_SELL' : null
    // Backup taken
    this.taken = this.ticketRequest.amounts.taken
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.ticketRequest) {
      next()
    } else {
      next('/')
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'TicketPrint' && this.registerLoading) {
      this.showSnackbar({ message: this.$t('фискализация_чека_в_процессе') })
      next(false)
    } else {
      next()
    }
  },

  mounted() {
    window.addEventListener('keydown', this.keyListener, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  methods: {
    ...mapActions({
      registerTicket: 'cashRegisters/registerTicket',
      registerCash: 'cashRegisters/registerCash',
      setTicketRequest: 'cashRegisters/setTicketRequest',
      setTicket: 'cashRegisters/setTicket',
      setTicketBulkAttributes: 'cashRegisters/setTicketBulkAttributes',
      checkPassword: 'cashRegisters/checkPassword',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      kaspiPayRequestQrCode: 'kaspiPay/requestQrCode',
      m4bankRequestOperation: 'm4bank/requestOperation',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
    }),

    goBack() {
      if (localStorage.getItem('rekassa.kz-ui-main-screen') === null || localStorage.getItem('rekassa.kz-ui-main-screen') === 'CALCULATOR') {
        this.$router.push('/details')
      } else {
        this.$router.push('/')
      }
    },

    onlyOne(a, b, c) {
      return !((!a + !b + !c) - 2)
    },

    getLocale() {
      return getLocale()
    },

    keyListener(event) {
      if (!this.cashRegisterPinpadDialog && !this.registerLoading) {
        const reg = new RegExp('^[1-7]$')
        if (event.key === 'Backspace') {
          this.$goBack()
        } else if (event.key === ' ') {
          this.operationTypeDialog = true
        } else if (event.key === 'Escape') {
          this.operationTypeDialog = false
        } else if (reg.test(event.key)) {
          const operationType = this.filteredOperationTypes[event.key - 1]
          if (operationType && !this.requestSent) {
            this.selectOperationType(operationType)
          }
        } else if (event.key === 'Enter') {
          this.showPinpad()
        }
      }
    },

    selectOperationType(operationType) {
      this.ticketRequest = this.$store.state.cashRegisters.ticketRequest

      const hasChange = this.ticketRequest.amounts && this.ticketRequest.amounts.change && this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.change) > 0
      let hasItemDiscount = false
      let hasItemMarkup = false
      if (this.ticketRequest.items) {
        this.ticketRequest.items.forEach(item => {
          if (item.type === fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY) {
            hasItemDiscount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT') !== undefined
            hasItemMarkup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP') !== undefined
          }
        })
      }

      if (['OPERATION_SELL_RETURN', 'OPERATION_BUY', 'MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(operationType.value) >= 0 && (hasChange || hasItemDiscount || hasItemMarkup)) {
        this.selectedOperationType = null
        this.showSnackbar({ message: this.$t('скидка_наценка_и_сдача_не_допустимы_в_операции', { operation: operationType.title }) })
        this.operationTypeDialog = false
        return
      }

      if (['OPERATION_SELL', 'OPERATION_SELL_RETURN', 'OPERATION_BUY', 'OPERATION_BUY_RETURN'].indexOf(operationType.value) >= 0) {
        this.ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum[operationType.value]
        this.selectedOperationType = operationType.value
        this.operationTypeDialog = false

        // Exception for OPERATION_SELL_RETURN
        if (operationType.value === 'OPERATION_SELL_RETURN') {
          this.ticketRequest.amounts.taken = this.getBillsAndCoinsMoney(0)
        } else {
          // Restoring from backup
          this.ticketRequest.amounts.taken = this.taken
        }
      } else if (['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(operationType.value) >= 0) {
        const moneyPlacemenTicketRequest = new fdo.kkm.proto.MoneyPlacementRequest()
        moneyPlacemenTicketRequest.operation = fdo.kkm.proto.MoneyPlacementEnum[operationType.value]
        moneyPlacemenTicketRequest.sum = this.getBillsAndCoinsMoney(this.totalSum)
        this.selectedOperationType = operationType.value
        this.ticketRequest = moneyPlacemenTicketRequest
        this.operationTypeDialog = false
      }
    },

    showPinpad() {
      if (this.checkIfShiftExpired()) return

      this.m4bankType = null
      this.m4bankAttributes = null
      if (this.cashRegister.status === 'TRIAL' || this.cashRegisterPinChecked) {
        this.register()
      } else {
        this.cashRegisterPinpadDialog = true
      }
    },

    checkPin(pincode) {
      this.cashRegisterPinpadLoading = true
      const form = new FormData()
      form.append('password', pincode)
      this.checkPassword({
        cashRegister: this.cashRegister,
        data: form,
      }).then((result) => {
        if (result.data.result) {
          this.cashRegisterPinpadDialog = false
          this.setCashRegisterPinChecked(true)
          this.register()
        } else {
          this.cashRegisterPinpadErrorMessage = this.$t('введен_неверный_пин-код')
        }
        this.cashRegisterPinpadLoading = false
      }).catch(() => {
        this.cashRegisterPinpadLoading = false
      })
    },

    register() {
      if (this.selectedOperationType === null) {
        this.showSnackbar({ message: this.$t('выберите_тип_операции') })
        return
      }

      let total = 0

      if (this.selectedOperationType.includes('OPERATION')) {
        total = this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.total)
      } else if (this.selectedOperationType.includes('MONEY')) {
        total = this.getNumberFromBillsAndCoins(this.ticketRequest.sum)
      }

      if (new Decimal(total).greaterThan(this.preferences.operationTotalLimit)) {
        this.showSnackbar({ message: this.$t('превышена_максимальная_сумма_чека_вы_можете_изменить_ее_в_меню_интерфейс', { maxSum: this.$options.filters.numeral(this.preferences.operationTotalLimit, '0,0') }) })
        return
      }

      this.registerLoading = true
      this.requestSent = true

      // Register ticket
      if (this.selectedOperationType.includes('OPERATION')) {
        this.registerTicket({
          cashRegister: this.cashRegister,
          ticket: this.getTicketRequestJSON(this.ticketRequest),
          uuid: this.uuidString,
        }).then((result) => {
          // Set attribute m4bankType if not null
          if (this.m4bankType) {
            this.setTicketBulkAttributes({
              cashRegister: this.cashRegister,
              ticket: result.data,
              cfg: {
                params: {
                  paymentType: this.m4bankType,
                  m4bankMerchantName: this.m4bankAttributes.merchantName,
                  m4bankDateTime: this.m4bankAttributes.dateTime,
                  m4bankOperationDay: this.m4bankAttributes.operationDay,
                  m4bankTransactionNumber: this.m4bankAttributes.transactionNumber,
                  m4bankTerminalId: this.m4bankAttributes.terminalId,
                  m4bankMaskedPan: this.m4bankAttributes.instrumentSpecificData.maskedPan,
                  m4bankAuthorizationCode: this.m4bankAttributes.instrumentSpecificData.authorizationCode,
                  m4bankRRN: this.m4bankAttributes.instrumentSpecificData.rrn,
                },
              },
            }).then((resultWithAttr) => {
              this.setTicketRequest(null)
              this.setTicket(resultWithAttr.data)
              if (!this.$isCordova()) {
                this.showSnackbar({ message: this.$t('чек_№_успешно_фискализирован', { number: resultWithAttr.data.shiftDocumentNumber }), timeout: 1500 })
              }
              this.$router.push(`/print/${this.cashRegister.id}/${resultWithAttr.data.id}`)
              this.registerLoading = false
              this.checkIfOffline(resultWithAttr.data)
            }).catch((error) => {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
              this.registerLoading = false
            })
          } else {
            this.setTicketRequest(null)
            this.setTicket(result.data)
            if (!this.$isCordova()) {
              this.showSnackbar({ message: this.$t('чек_№_успешно_фискализирован', { number: result.data.shiftDocumentNumber }), timeout: 1500 })
            }
            this.$router.push(`/print/${this.cashRegister.id}/${result.data.id}`)
            this.registerLoading = false
            this.checkIfOffline(result.data)
          }
        }).catch((error) => {
          this.processError(error)
          this.registerLoading = false
        })
      }

      // Register cash
      if (this.selectedOperationType.includes('MONEY')) {
        this.registerCash({
          cashRegister: this.cashRegister,
          cash: this.getMoneyPlacementRequestJSON(this.ticketRequest),
          uuid: this.uuidString,
        }).then((result) => {
          this.setTicketRequest(null)
          this.setTicket(result.data)
          if (!this.$isCordova()) {
            this.showSnackbar({ message: this.$t('чек_№_успешно_фискализирован', { number: result.data.shiftDocumentNumber }), timeout: 1500 })
          }
          this.$router.push(`/print/${this.cashRegister.id}/${result.data.id}`)
          this.registerLoading = false
          this.checkIfOffline(result.data)
        }).catch((error) => {
          this.processError(error)
          this.registerLoading = false
        })
      }
    },

    checkIfOffline(ticket) {
      if (this.cashRegister.fdoMode === 'ONLINE' && ticket.offline) {
        this.showSnackbar({ message: this.$t('внимание_касса_перешла_в_автономный_режим') })
      }
    },

    checkIfShiftExpired() {
      if (this.cashRegister.shiftExpired) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return true
      }
      return false
    },

    getTicketRequestJSON(ticketRequest) {
      // Generating Discount, Markup items
      if (ticketRequest.items) {
        const discountMarkupItems = []
        ticketRequest.items.forEach(item => {
          if (item.type === fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY) {
            // Item commodity tax
            const tax = item.commodity.taxes && item.commodity.taxes[0]

            // Item discount
            const discount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT')
            if (discount) {
              const discountType = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
              const discountPercentage = discount.value.replace('%', '')
              const discountValue = discountType === 'PERCENTAGE' ? new Decimal(discountPercentage).times(this.getNumberFromBillsAndCoins(item.commodity.sum)).dividedBy(100).toNumber()
                .toFixed(2) : discount.value
              const discountItem = new fdo.kkm.proto.TicketRequest.Item({
                type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_DISCOUNT,
                discount: new fdo.kkm.proto.TicketRequest.Modifier({
                  sum: this.getBillsAndCoinsMoney(discountValue),
                }),
              })

              const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
              if (tax && discountMarkupTax) {
                discountItem.taxes = [
                  new fdo.kkm.proto.TicketRequest.Tax({
                    taxType: 100,
                    taxationType: tax.taxation_type,
                    percent: tax.percent,
                    sum: this.getBillsAndCoinsMoney(new Decimal(discountMarkupTax.value).abs()),
                    isInTotalSum: true,
                  }),
                ]
              }

              discountMarkupItems.push(discountItem)
            }

            // Item markup
            const markup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP')
            if (markup) {
              const markupType = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
              const markupPercentage = markup.value.replace('%', '')
              const markupValue = markupType === 'PERCENTAGE' ? new Decimal(markupPercentage).times(this.getNumberFromBillsAndCoins(item.commodity.sum)).dividedBy(100).toNumber()
                .toFixed(2) : markup.value
              const markupItem = new fdo.kkm.proto.TicketRequest.Item({
                type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_MARKUP,
                markup: new fdo.kkm.proto.TicketRequest.Modifier({
                  sum: this.getBillsAndCoinsMoney(markupValue),
                }),
              })

              const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
              if (tax && discountMarkupTax) {
                markupItem.taxes = [
                  new fdo.kkm.proto.TicketRequest.Tax({
                    taxType: 100,
                    taxationType: tax.taxation_type,
                    percent: tax.percent,
                    sum: this.getBillsAndCoinsMoney(new Decimal(discountMarkupTax.value).abs()),
                    isInTotalSum: true,
                  }),
                ]
              }

              discountMarkupItems.push(markupItem)
            }
          }
        })
        ticketRequest.items = ticketRequest.items.concat(discountMarkupItems)
      }

      // Be aware of case "DateTime"
      ticketRequest.dateTime = new fdo.kkm.proto.DateTime({
        date: new fdo.kkm.proto.Date({ year: this.$moment().format('YYYY'), month: this.$moment().format('M'), day: this.$moment().format('D') }),
        time: new fdo.kkm.proto.Time({ hour: this.$moment().format('HH'), minute: this.$moment().format('mm'), second: this.$moment().format('ss') }),
      })

      // Operator
      ticketRequest.operator = new fdo.kkm.proto.TicketRequest.Operator({ code: this.user.id, name: this.user.name })

      return ticketRequest.toJSON()
    },

    getMoneyPlacementRequestJSON(moneyPlacementRequest) {
      // Be aware of case "Datetime"
      moneyPlacementRequest.datetime = new fdo.kkm.proto.DateTime({
        date: new fdo.kkm.proto.Date({ year: this.$moment().format('YYYY'), month: this.$moment().format('M'), day: this.$moment().format('D') }),
        time: new fdo.kkm.proto.Time({ hour: this.$moment().format('HH'), minute: this.$moment().format('mm'), second: this.$moment().format('ss') }),
      })

      return moneyPlacementRequest.toJSON()
    },

    processError(error) {
      if (error && error.response && error.response.data && error.response.data.code === 'CASH_REGISTER_SHIFT_PERIOD_EXPIRED') {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену'),
          text: error.response.data.meta && error.response.data.meta.ALLOWED_SHIFT_MINUTES ? this.$t('прошло_больше_часов_с_начала_открытия_смены_dotdotdot', { hours: error.response.data.meta.ALLOWED_SHIFT_MINUTES / 60 }) : null,
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
      }
    },

    // Kaspi PAY
    kaspiPay() {
      const cardPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD)
      if (cardPayment === undefined) {
        this.showSnackbar({ message: this.$t('нет_суммы_для_оплаты_картой') })
        return
      }

      if (this.checkIfShiftExpired()) return

      this.registerLoading = true
      this.kaspiPayRequestQrCode({
        data: {
          cashRegisterId: this.cashRegister.id,
          ticket: this.getTicketRequestJSON(this.ticketRequest),
        },
      }).then(() => {
        this.registerLoading = false
        this.$router.push('/pay/kaspi')
      }).catch((error) => {
        this.registerLoading = false
        this.processError(error)
      })
    },

    m4bankShowPinpad(type) {
      if (!this.hasAndroid8AndAbove || !this.hasNFC) {
        this.showSnackbar({ message: this.$t('для_того_чтобы_начать_принимать_бесконтактные_платежи_требуется_dotdotdot') })
        return
      }

      const cardPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD)
      if (cardPayment === undefined) {
        this.showSnackbar({ message: this.$t('нет_суммы_для_оплаты_картой') })
        return
      }

      if (this.checkIfShiftExpired()) return

      this.m4bankType = type
      if (this.cashRegisterPinChecked) {
        this.m4bankOperation(this.m4bankType)
      } else {
        this.m4bankCashRegisterPinpadDialog = true
      }
    },

    m4bankCheckPin(pincode) {
      this.m4bankCashRegisterPinpadLoading = true
      const form = new FormData()
      form.append('password', pincode)
      this.checkPassword({
        cashRegister: this.cashRegister,
        data: form,
      }).then((result) => {
        if (result.data.result) {
          this.m4bankCashRegisterPinpadDialog = false
          this.setCashRegisterPinChecked(true)
          this.m4bankOperation(this.m4bankType)
        } else {
          this.m4bankCashRegisterPinpadErrorMessage = this.$t('введен_неверный_пин-код')
        }
        this.m4bankCashRegisterPinpadLoading = false
      }).catch(() => {
        this.m4bankCashRegisterPinpadLoading = false
      })
    },

    m4bankOperation(type) {
      this.registerLoading = true
      this.m4bankRequestOperation({
        cashRegisterId: this.cashRegister.id,
        ticket: this.getTicketRequestJSON(this.ticketRequest),
        prefix: type === 'HALYK_POS' ? 'halyk' : 'alfa',
        packageName: type === 'HALYK_POS' ? 'ru.m4bank.softpos.halyk' : 'ru.m4bank.softpos.alfakz',
      }).then((paymentResult) => {
        if (paymentResult && paymentResult.result && paymentResult.result.code === 0 && paymentResult.transaction) {
          this.m4bankType = type
          this.m4bankAttributes = paymentResult.transaction
          this.register()
        } else {
          this.m4bankErrorText = `${this.$t('ошибка_оплаты')}: ${paymentResult.result.errorData.description}`
          this.registerLoading = false
          this.m4bankErrorDialog = true
        }
      }).catch((error) => {
        this.m4bankErrorText = `${this.$t('ошибка_оплаты')}: ${error}`
        this.registerLoading = false
        this.m4bankErrorDialog = true
      })
    },
  },
}
</script>

<style lang="stylus">
.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active).action-btn,
.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active).option-btn
  color #fff !important
.v-application.theme--dark
  .theme--dark.action-btn .v-btn__content
    background-color #21B880
    padding 0 10px
.v-application.theme--light
  .theme--dark.action-btn .v-btn__content
    background-color #1776D2
    padding 0 10px

.payment.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
    background-color #272727 !important
    opacity 30%

    .v-image__image--cover
      background-size contain
</style>
