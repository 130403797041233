<template lang="pug">
//- transition(name="basketmorph" mode="out-in")
.t-btn.d-flex.flex-row.flex-nowrap.Zflex-grow-1.w100(v-if="hero" :disabled="disabled")
  .prepend-outer.d-inline-flex.mr-2(v-if="$slots['left-content'] || $slots['left']")
    slot(name="left-content")
      v-btn(:color="(color?color:'primary')" :disabled="disabled" x-large Zblock :outlined="outlined" depressed @click.stop="$emit('left')")
        slot(name="left")
  .main.d-flex.flex-grow-1.justify-end(Zref="resize" Zv-mutate="resize")
    //- transition(name="btnmorph" mode="out-in" duration="2000")
    v-btn.btnmorph(:key="$slots['left-content']!=null" :color="(color?color:'primary')" :disabled="disabled" :outlined="outlinedDefault===undefined?outlined:outlinedDefault" x-large Zblock depressed @click="$emit('click')")
      .prepend-inner.text-left
        v-btn.ml-2( v-if="$slots['left-inner']" icon :disabled="disabled" @click.stop="$emit('left')"): slot(name="left-inner" v-if="$slots['left-inner']")
      .inner.align-center.text-center.Ztext-truncate: slot
      .append-inner.text-right()
        v-btn.mr-2(icon width="22px" :disabled="disabled" @click.stop="$emit('right')"  v-if="$slots['right-inner']"): slot(name="right-inner")
  .append-outer.d-inline-flex.ml-2(v-if="$slots['right-content'] || $slots['right']")
    slot(name="right-content")
      v-btn(:color="(color?color:'primary')" :disabled="disabled" x-large Zblock :outlined="outlined" depressed @click.stop="$emit('right')")
        slot(name="right")
v-btn(v-else-if="fab || icon",
    :color="(color?color:(value?'primary':'grey darken-3'))",
    :fab="fab",
    depressed,
    :x-small="(icon && fab)",
    :icon="icon && !fab",
    @click="$emit('input',!value)"
    v-bind="$attrs" v-on="$listeners"
  )
    v-icon(:color="iconColor?iconColor:(color?undefined:(value?'white':'primary'))"): slot
v-btn(v-else :class="{'primary--text':true}" :block="block" :disabled="disabled" :color="color?color:'grey darken-3'" x-large depressed v-bind="$attrs" v-on="$listeners"): slot
//- v-row(no-wrap dense)
  //- v-col(cols="10" v-if="$slots.default")
    v-btn(color="primary" x-large block depressed @click="$emit('left')")
      slot
  v-col(Zclass="{'offset-9':$slots.default}")
    v-btn(
      Zclass="{'pl-3 pr-1 justify-space-between':!$slots.default}"
      block
      x-large
      depressed
      :outlined="outlined"
      :disabled="disabled"
      color="primary"
      @click="$emit('click')")
      //- v-avatar.primary--text(color="white" tile rounded size="28") {{cart.length}}
      slot
</template>
<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: undefined,
    },
    outlinedDefault: {
      type: Boolean,
      default: undefined,
    },
    fab: {
      type: Boolean,
      default: undefined,
    },
    hero: {
      type: Boolean,
      default: undefined,
    },
    block: {
      type: Boolean,
      default: undefined,
    },
    outlined: {
      type: Boolean,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({}),

  computed: {
    // isEmpty() {
    //   return this.empty && !this.totalSum
    // },
  },

  watch: {},

  methods: {
    resize() {
      this.$refs.resize.style.width = `${this.$refs.resize.offsetWidth}px`
      console.log('width', this.$refs.resize.style.width)
    },
    // ...mapActions({
    //   checkout: 'tjtheme/checkout',
    // }),
    // hasSecondButton() {
    //   return !!this.$slots.default
    // },
  },
}
</script>
<style lang="stylus" scopped>
.v-btn:not(.v-btn--round).v-size--x-large
  min-width 60px
  padding 0
// .t-btn .v-btn
// width: 150px
// overflow hidden
// text-overflow ellipsis
.t-btn
  // .main
  // transition all 1s ease
  // width auto
  // min-width: 0
.prepend-inner, .append-inner, .inner
  flex 50px

.btnmorph
  min-width 100% !important
</style>
