<template lang="pug">
v-card.d-flex.flex-column.fill-height(v-bind="$attrs" Zv-on="$listeners" color="transparent")
  //- v-card-title
  slot(name="header-container")
    v-card-text.px-4.py-3(@click.stop="$emit('maximize')")
      .headline.d-flex.justify-space-between
        span: slot(name="header")
        div
          slot(name="header-actions")
            span.mr-4
              slot(name="header-actions-prepend")
              v-btn.mr-1(color="grey darken-3" fab depressed x-small Zicon dark @click.stop="$emit('route','client')")
                v-icon(color="primary") mdi-account-plus
              v-btn.mr-1(color="grey darken-3" Ztext fab depressed x-small Zicon dark @click.stop="$emit('close')")
                v-icon(color="primary") mdi-share
              v-btn(color="grey darken-3" Ztext fab depressed x-small Zicon dark @click.stop="$emit('close')")
                v-icon(color="primary") mdi-dots-vertical

          v-btn(color="grey darken-3" fab depressed x-small Zicon dark @click.stop="$emit('close')")
            v-icon(color="primary") mdi-close
      t-list.pt-2(color="transparent" v-if="!enableImplicitClient")
        v-list-item.px-0(v-if="!client")
          v-row(dense)
            v-col(cols="6")
              v-btn(block color="primary" outlined)
                v-icon(left) mdi-plus
                | Клиент
            v-col(cols="6")
              v-btn(block color="grey darken-2" outlined) Опции
                v-icon(right) mdi-dots-vertical
    t-list(v-if="client" color="indigo")
      v-divider
      //- v-subheader(inset) Клиент
      t-list-item(:title="client.name" @click.stop="$emit('route','client')" avatar="mdi-account") (777) 701-55-62
  v-divider
  v-card-text.pa-0.overflow-y-auto.flex-grow-1(id="cartList" v-scroll.self="scroll" :style="{'min-height':bodyHeight}")
    slot
  v-card-actions.px-3.pb-3(v-if="$slots.footer")
    slot(name="footer")
</template>
<script>

import { mapState, mapActions } from 'vuex'

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    bodyHeight: {
      type: String,
      default: '300px',
    },
    enableImplicitClient: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.tjtheme.client,
    }),
  },
  data: () => ({

  }),
  methods: {
    scroll(e) {
      this.$emit('scroll', e)
    },

  },
}
</script>
<style lang="stylus"></style>
