<template lang="pug">
div.flex
  v-container(style="max-width: 450px")
    v-row
      v-col.pa-4(align="center")
        v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

    v-row
      v-col
        v-list.v-list--fulltext.gray-background-list
          v-subheader {{ $t("отчёты") }}
          v-list-item(@click="toFiscalReport()")
            v-list-item-content
              v-list-item-title {{ $t("фискальный_отчёт") }}
            v-list-item-avatar
              v-icon mdi-cash

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_report_mx0a.svg',
      light: '/static/illustrations/rally/light/undraw_report_mx0a.svg',
    },
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
    }),

    toFiscalReport() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену') })
      } else {
        this.$router.push('/reports/fiscal')
      }
    },
  },
}
</script>
