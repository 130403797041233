<template lang="pug">
// - component(:is="child")
  slot v-for="(_, name) in $slots" :name="name" :slot="name" />
AppLayout(v-if="app")
  template(v-for="(_, slot) of $scopedSlots", v-slot:[slot]="scope")
    slot(:name="slot", v-bind="scope")
t-dialog(
  v-else-if="value != undefined",
  :value="value",
  v-bind="$attrs",
  v-on="$listeners"
)
  div
    t-window-body(v-bind="$attrs", v-on="$listeners")
      template(v-for="(_, slot) of $scopedSlots", v-slot:[slot]="scope")
        slot(:name="slot", v-bind="scope")
.fill-height(v-else-if="comp")
  slot
.fill-height(v-else)
  t-window-body(v-bind="$attrs", v-on="$listeners", Zhide-close)
    template(v-for="(_, slotName) of $scopedSlots", v-slot:[slotName]="scope")
      slot(:name="slotName", v-bind="scope")
</template>
<script>
export default {
  components: {
    AppLayout: () => import('../pages/uidemo/slots/Layout.vue'),
  },
  props: {
    app: {
      type: Boolean,
      default: undefined,
    },
    comp: {
      type: Boolean,
      default: undefined,
    },
    value: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({
    zfullscreen: null,
  }),
  // render(h) {
  //   const children = Object.keys(this.$slots)
  //     .map(slot => h('template', { slot }, this.$slots[slot]))
  //   return h('wrapper', [
  //     h('parent-table', {
  //       attrs: this.$attrs,
  //       on: this.$listeners,
  //       scopedSlots: this.$scopedSlots,
  //     }, children)
  //   ])
  // }
}
</script>
<style lang="stylus"></style>
