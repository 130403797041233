import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth'
import cashRegisters from './modules/cashRegisters'
import positions from './modules/positions'
import kaspiPay from './modules/kaspiPay'
import m4bank from './modules/m4bank'
import moyUchet from './modules/moyUchet'
import printer from './modules/printer'
import tools from './modules/tools'

import { tjtheme, settings } from '../TJTheme/store'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['settings'], // only save user module
  // reducer: (state) => ({ navigation: state.navigation }), //only save navigation module
  // filter: (mutation) => mutation.type == 'addNavItem'
})

export default new Vuex.Store({
  modules: {
    auth,
    cashRegisters,
    positions,
    kaspiPay,
    m4bank,
    moyUchet,
    printer,
    tools,
    tjtheme,
    settings,
  },
  plugins: [vuexLocal.plugin],
})
