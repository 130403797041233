<template lang="pug">
v-dialog(
  v-if="dialog && show",
  :value="value",
  :retain-focus="false"
  @input="$emit('input', $event)",
  :fullscreen="fullscreen",
  v-bind="$attrs",
  v-on="$listeners"
)
  template(v-for="(_, slot) of $scopedSlots", v-slot:[slot]="scope")
    slot(:name="slot", v-bind="scope")
v-bottom-sheet(
  v-else-if="show",
  :value="value",
  @input="$emit('input', $event)",
  inset,
  :retain-focus="false"
  scrollable,
  Zcontent-class="cartBottomDialog",
  :fullscreen="fullscreen",
  Zoverlay-color="primary",
  Zeager
)
  template(v-for="(_, slot) of $scopedSlots", v-slot:[slot]="scope")
    slot(:name="slot", v-bind="scope")
</template>
<script>
import { mapState, mapActions } from 'vuex'

// const html = document.getElementsByTagName('html')[0]

export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    fullscreen: {
      type: Boolean,
      default: undefined,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({
    show: false,
  }),
  computed: {},
  watch: {
    value: {
      immediate: false,
      handler(v) {
        // console.log('show', v)
        if (v) {
          this.show = true
          this.$root.dialog.push(1)
          // setTimeout(() => {
          //   html.classList.add('overflow-y-hidden')
          //   html.ontouchstart = (e) => { e.preventDefault() }
          // }, 200)
        } else {
          // console.log('pop')
          this.$root.dialog.pop()
          setTimeout(() => {
            this.show = false
          }, 200)
          // html.classList.remove('overflow-y-hidden')
          // html.ontouchstart = null
        }
      },
    },
  },
  beforeDestroy() {
    if (this.show) {
      // console.log('beforeDestroy')
      this.$root.dialog.pop()
    }
  },
  created() {
    if (this.value) {
      this.show = this.value
      this.$root.dialog.push(1)
    }
  },

  methods: {},
}
</script>
<style lang="stylus">
// .theme--dark
// .cartBottomDialog.v-dialog > .theme--dark.v-card.v-sheet
// background: transparent
.theme--dark .v-dialog > .theme--dark.v-card.v-sheet
  background-color #33333d
.v-dialog:not(.v-dialog--fullscreen)
  max-height 93%
</style>
