import { getLocale } from '../../i18n/index'

const tools = {
  namespaced: true,

  state: {
    drawer: null,
    snackbar: '',
    confirm: {
      show: false,
      title: null,
      icon: null,
      text: null,
      resolveText: null,
      rejectText: null,
      persistent: false,
      fullscreen: false,
      promise: null,
      deferred: null,
      resolveButtonColor: 'primary',
      rejectButtonColor: 'primary',
    },
    keyListenerEnabled: true,
    appInitialized: false,
    connectionWarning: false,
    cordovaBuildVersion: null,
    ticketLocale: getLocale(),
    firebaseRemoteConfig: null,
    firebaseAnalytics: null,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setDrawer({ commit }, payload) {
      commit('set', { type: 'drawer', value: payload })
    },

    showSnackbar({ commit }, payload) {
      commit('set', { type: 'snackbar', value: payload })
    },

    showConfirm({ commit, state }, payload) {
      let deferred = null
      const promise = new Promise(((resolve, reject) => {
        deferred = { resolve, reject }
      }))
      commit('set', {
        type: 'confirm',
        value: {
          show: true,
          title: payload.title,
          icon: payload.icon,
          text: payload.text,
          resolveText: payload.resolveText,
          rejectText: payload.rejectText,
          persistent: payload.persistent,
          fullscreen: payload.fullscreen,
          promise,
          deferred,
          resolveButtonColor: payload.resolveButtonColor ? payload.resolveButtonColor : 'primary',
          rejectButtonColor: payload.rejectButtonColor ? payload.rejectButtonColor : 'primary',
        },
      })
      return state.confirm.promise
    },

    hideConfirm({ state, commit }) {
      commit('set', {
        type: 'confirm',
        value: {
          ...state.confirm,
          show: false,
        },
      })
    },

    clearConfirm({ commit }) {
      commit('set', {
        type: 'confirm',
        value: {
          show: false,
          title: null,
          icon: null,
          text: null,
          resolveText: null,
          rejectText: null,
          persistent: false,
          fullscreen: false,
          promise: null,
          deferred: null,
          resolveButtonColor: 'primary',
          rejectButtonColor: 'primary',
        },
      })
    },

    setKeyListenerStatus({ commit }, payload) {
      commit('set', { type: 'keyListenerEnabled', value: payload })
    },

    setAppInitialized({ commit }, payload) {
      commit('set', { type: 'appInitialized', value: payload })
    },

    setTicketLocale({ commit }, payload) {
      commit('set', { type: 'ticketLocale', value: payload })
    },

    setConnectionWarning({ commit }, payload) {
      commit('set', { type: 'connectionWarning', value: payload })
    },

    setCordovaBuildVersion({ commit }, payload) {
      commit('set', { type: 'cordovaBuildVersion', value: payload })
    },

    setFirebaseRemoteConfig({ commit }, payload) {
      commit('set', { type: 'firebaseRemoteConfig', value: payload })
    },

    firebaseRemoteConfigGetString({ state }, payload) {
      if (window.cordova) {
        return new Promise((resolve) => {
          window.FirebasePlugin.getValue(payload, (value) => {
            resolve(value)
          }, () => {})
        })
      }
      return new Promise((resolve) => {
        const value = state.firebaseRemoteConfig.getString(payload)
        resolve(value)
      })
    },

    setFirebaseAnalytics({ commit }, payload) {
      commit('set', { type: 'firebaseAnalytics', value: payload })
    },

    firebaseAnalyticsSetUserId({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserId(payload)
      } else {
        state.firebaseAnalytics.setUserId(payload)
      }
    },

    firebaseAnalyticsSetUserProperty({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserProperty(payload.key, payload.value)
      } else {
        const jsonVariable = {}
        jsonVariable[payload.key] = payload.value
        state.firebaseAnalytics.setUserProperties(jsonVariable)
      }
    },

    firebaseAnalyticsLogEvent({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.logEvent(payload.eventName, payload.eventProperties || {})
      } else {
        state.firebaseAnalytics.logEvent(payload.eventName, payload.eventProperties)
      }
    },

    firebaseAnalyticsSetScreenName({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setScreenName(payload)
      } else if (state.firebaseAnalytics) {
        state.firebaseAnalytics.setCurrentScreen(payload)
        state.firebaseAnalytics.logEvent('screen_view')
      }
    },

    copyToClipboard({}, payload) {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', e => e.stopPropagation())
      el.value = payload
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
      el.select()
      document.execCommand('copy')
      // document.body.removeChild(el) // Remove the <textarea> element
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
}

export default tools
