<template lang="pug">
v-list-item(outlined v-bind="$attrs" v-on="buttonLocal ? {} :  { click: () => $emit('click') }")
  template(v-slot:default="{ active }")
    slot(name="content")
      template(v-if="buttonLocal")
        t-btn.mt-2.mr-2(block @click="$emit('click')")
          v-avatar.mr-2(v-if="isImg" size="22" :color="iconColor" rounded)
            img(:src="icon" style="width:22px !important")
          v-icon(v-else left) {{icon}}
          | {{title}}
      template(v-else)
        v-list-item-avatar(v-if="icon || avatar || $scopedSlots['icon']" :color="avatar?'primary':undefined" :rounded="avatar!=undefined" size="48")
          slot(name="icon" :active="active")
            //- v-avatar(v-if="icon && isImg('png')" size='32px' tile='')
              img(:src='`https://cdn.vuetifyjs.com/images/bottom-sheets/${icon}`')
            v-avatar(v-if="icon && isImg" size='32px' tile='' :color="iconColor" rounded)
              img(:src='icon')
            v-icon(v-else) {{icon || avatar}}
        v-list-item-content
          v-list-item-title(:class="{'pl-2':!icon}") {{title}}
        v-list-item-action.pr-2(v-if="!noRight" :class="{'headline ezcar':ezcar}")
          slot(v-if="$slots['default']")
          v-icon(v-else) mdi-chevron-right
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    button: {
      type: Boolean,
      default: undefined,
    },
    avatar: {
      type: String,
    },
    noRight: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    indent: {
      type: Boolean,
      default: false,
    },
    ezcar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonLocal: this.button,
    }
  },
  computed: {
    isImg() {
      return this.icon.indexOf('.') >= 0
    },
  },
  watch: {
    '$parent.$attrs.button': function (v) {
      this.buttonLocal = v
    },
  },
  created() {
    if (this.$parent.$attrs.button) this.buttonLocal = true
  },
}
</script>
<style lang="stylus"></style>
