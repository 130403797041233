import { EventSourcePolyfill } from 'event-source-polyfill'
import firebase from 'firebase/app'
import $http from '../../utils/http'
import i18n from '../../i18n/index'
import 'firebase/auth'

const kaspiPay = {
  namespaced: true,

  state: {
    eventSource: null,
    data: null,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    tradepoints({}, payload) {
      return $http.get(`/kaspi/crs/${payload.cashRegisterId}/organization-tradepoints`)
    },

    registerTerminal({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post('/kaspi/terminals', payload.data, cfg)
    },

    deregisterTerminal({ }, payload) {
      return $http.delete('/kaspi/terminals', {
        data: payload.data,
        headers: {
          'cash-register-password': payload.password,
        },
      })
    },

    requestQrCode({ commit, dispatch }, payload) {
      return $http.post('/kaspi/payments', payload.data).then((result) => {
        commit('set', { type: 'data', value: result.data })
        return 'OK'
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code && i18n.t(`kaspi/${error.response.data.code}`) !== `kaspi/${error.response.data.code}`) {
          dispatch('tools/showSnackbar', { message: i18n.t(`kaspi/${error.response.data.code}`) }, { root: true })
        } else {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` }, { root: true })
        }
        throw error
      })
    },

    async paymentStatus({ commit }, payload) {
      let url = `/kaspi/payments/${payload.paymentId}/status`
      let token = null

      if (process.env.VUE_APP_API_ROOT_URL) {
        url = process.env.VUE_APP_API_ROOT_URL + url
      }

      // Cordova
      if (window.cordova !== undefined) {
        const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
        if (currentUser) {
          token = await currentUser.idToken
        }
      // Web
      } else {
        const currentUser = await firebase.auth().currentUser
        if (currentUser) {
          token = await currentUser.getIdToken()
        }
      }

      const es = new EventSourcePolyfill(url, { headers: { Authorization: `Bearer ${token}` } })
      es.onmessage = (event => {
        payload.messageCallback(event)
      })
      es.onerror = (event => {
        // Sometimes onerror called with error property undefined. EventSourcePolyfill BUG???
        if (event && Object.prototype.hasOwnProperty.call(event, 'error') && event.error === undefined) return
        payload.errorCallback(event)
      })
      commit('set', { type: 'eventSource', value: es })
    },

    closePaymentStatus({ state, commit }) {
      if (state.eventSource) {
        state.eventSource.close()
        commit('set', { type: 'eventSource', value: null })
      }
    },

    clearData({ commit }) {
      commit('set', { type: 'data', value: null })
    },
  },
}

export default kaspiPay
