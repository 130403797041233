<template lang="pug">
  v-container(style="max-width: 450px")
    v-row
      v-col(align="center")
        v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

    v-row
      v-col
        v-list.gray-background-list
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("знм") }}
              v-list-item-subtitle {{ $t("заводской_номер") }}
            v-list-item-action(v-if="cashRegister")
              v-list-item-action-text.title
                v-btn.mr-1(icon @click="copy(cashRegister.serialNumber)")
                  v-icon mdi-content-copy
                | {{ cashRegister.serialNumber }}

          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("модель") }}
              v-list-item-subtitle {{ $t("контрольно-кассовой_машины") }}
            v-list-item-action(v-if="cashRegister")
              v-list-item-action-text.title {{ cashRegister.model }}

          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("год_выпуска") }}
            v-list-item-action(v-if="cashRegister")
              v-list-item-action-text.title {{ cashRegister.year }}
    v-row
      v-col(align="center")
        p.caption(v-html="$t('контрольно-кассовая_машина_dotdotdot')")
        p.mb-0.caption
          a(@click.prevent="$openLink('http://kgd.gov.kz/ru/content/primenenie-kontrolno-kassovyh-mashin-1', '_system')") {{ $t("государственный_реестр_контрольно-кассовых_машин") }}

    v-footer(:absolute="!$isCordova()" :fixed="$isCordova()" padless)
      v-col.caption(align="center")
        span &copy; COMRUN, LLP
        span.mx-2 &bull;&nbsp;
        a(@click.prevent="$openLink('https://static.rekassa.kz/ru/app/3.0/agreement/agreement.pdf', '_system')") {{ $t("условия_использования") }}
        span.mx-2 &bull;&nbsp;
        a(@click.prevent="$openLink('https://rekassa.kz', '_system')") rekassa.kz

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_web_devices_ad58.svg',
      light: '/static/illustrations/rally/light/undraw_web_devices_ad58.svg',
    },
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      copyToClipboard: 'tools/copyToClipboard',
    }),

    copy(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },
  },
}
</script>
