<template lang="pug">
v-card.d-flex.flex-column.fill-height(
  v-bind="$attrs",
  Zv-on="$listeners",
  Zcolor="transparent"
)
  //- v-card-title
  slot(name="header-container", @click.stop="$emit('maximize', 1)")
    v-card-text.t-header.px-4.py-3
      .d-flex.justify-space-between
        template(v-if="$scopedSlots['title-sub']"): v-subheader: slot( name="title-sub")
        span.headline: slot(name="header"): slot(name="title")
        div
          slot(name="header-actions")
            span.mr-4
              slot(name="header-actions-prepend")
          slot(name="header-right")
            t-btn(v-if="!hideClose && !closeBottom" fab icon @click="$emit('input', false)") mdi-close
            //- v-menu
            //- v-dialog
            v-bottom-sheet(v-if="$scopedSlots['header-more']" v-model="dialogOptions" :close-on-content-click="false" Zclose-on-click="false" scrollable)
              template(v-slot:activator="{ on, attrs }")
                t-btn.mr-n3(icon v-bind="attrs" v-on="on") mdi-dots-vertical
              v-card
                v-card-title.py-3.d-flex.justify-space-between Опции
                  t-btn(v-if="!hideClose && !closeBottom" fab icon @click="dialogOptions = false") mdi-close
                v-divider
                v-card-text.pa-0(style="height: 400px")
                  slot(name="header-more")
    slot(name="subheader")
    v-divider

  template()
    v-card-title.justify-center.headline.font-weight-bold(v-if="$scopedSlots['prebody-bold']"): slot(name="prebody-bold")
    v-subheader(v-if="$scopedSlots.prebody"): slot(name="prebody")

  v-card-text#bodyList.pa-0.overflow-y-scroll.overflow-x-hidden.flex-grow-1(
    v-scroll.self="scroll",
    :style="{ 'min-height': minHeight, Zheight: '535px' }"
  )
    slot(:events="{ scrollToBottom }")

  v-card-actions.pa-0.ma-0(v-if="!$scopedSlots.footer && closeBottom")
    .flex-grow-1
      v-list.pa-0
        v-divider
        t-list-item.pl-2(icon="mdi-close"  @click="$emit('input',false)" title="Закрыть" noRight)

  v-card-actions.pa-0.ma-0.d-block(v-if="$scopedSlots.footer || $scopedSlots.prefooter || $scopedSlots['footer-container']")
    .flex-grow-1
      slot(name="prefooter")
      slot(name="footer-container"): .t-footer.px-3.pb-3(style="margin-bottom: env(safe-area-inset-bottom)"): slot(name="footer")

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: undefined,
    },
    hideClose: {
      type: Boolean,
      default: undefined,
    },
    closeBottom: {
      type: Boolean,
      default: undefined,
    },
    enableImplicitClient: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.tjtheme.client,
    }),
  },
  data: () => ({
    boldHeader: false,
    dialogOptions: false,
  }),
  methods: {
    scroll(e) {
      this.$emit('scroll', e)
    },
    scrollToBottom(opt) {
      // console.log('scrollToBottom Yeah', 0)
      // if (opt) this.$vuetify.goTo('#addBtn', { container: '.v-dialog__content--active #bodyList', duration: 500, offset: 385 })
      // else
      this.$vuetify.goTo('#addBtn', { container: '#bodyList' })
    },
  },
}
</script>
<style lang="stylus">
.overflow-y-scroll
  overflow-y scroll !important
</style>
