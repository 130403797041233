<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(:disabled="cancelLoading" icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title(v-if="ticket") {{ $t("чек") }} № {{ ticket.shiftDocumentNumber }}
      v-spacer
      v-toolbar-items
        v-btn(v-if="ticket && ticket._links.cancel && ticket._links.cancel.href" text color="red" :disabled="cancelLoading" @click="cancel()") {{ $t("отменить") }}
        v-btn(text @click="changeTicketLocale()") {{ ticketLocale === 'ru' ? 'ҚАЗ' : 'РУС' }}
      v-progress-linear(:active="cancelLoading || ticketLoading" :indeterminate="cancelLoading || ticketLoading" absolute bottom)

    v-main(flat v-if="ticket === null")
      v-container.skeleton(style="max-width: 400px")
        v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 150px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")

        v-skeleton-loader.mt-3.mx-auto(type="image" boilerplate style="width: 120px; height: 120px;")

        v-skeleton-loader.mt-8.mx-auto(type="text" boilerplate style="width: 140px;")

        v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

        v-divider.my-3

        v-skeleton-loader.mt-5(type="text" boilerplate style="width: 100px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

        v-divider.my-3

        v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate)
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

        v-divider.my-3

        v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 200px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 220px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 180px;")
        v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 250px;")

    re-receipt(v-if="ticket" :ticket="ticket" :class="rollWidthClass" :cashRegister="cashRegister" :organization="organization" :ticketLocale="ticketLocale")

    v-bottom-navigation(app dark)
      v-btn(:min-width="minButtonWidth" v-if="$isCordova()" icon @click="shareSystem()" :loading="shareLoading" :disabled="ticketLoading || ticket.cancelledBy !== null")
        v-icon mdi-share-variant

      v-btn(:min-width="minButtonWidth" icon @click="downloadReceipt()" :loading="downloadLoading" :disabled="ticketLoading || ticket.cancelledBy !== null")
        v-icon mdi-download

      v-dialog(v-model="shareWhatsAppDialog" persistent max-width="400px")
        template(v-slot:activator="{ on }")
          v-btn(:min-width="minButtonWidth" :disabled="cancelLoading || ticketLoading || ticket.cancelledBy !== null" icon v-on="on")
            v-icon mdi-whatsapp
        v-card
          v-card-title
            span.headline {{ $t("отправить_чек_на_whatsapp") }}
          v-card-text
            v-container
              v-form(ref="shareWhatsAppForm" @submit.prevent="shareWhatsApp()")
                p {{ $t("можно_отправить_чек_не_сохраняя_номер_получателя_в_контакты") }}
                v-text-field(ref="mobileNumber"
                            v-model="mobileNumber"
                            v-mask="'+7 (###) ###-##-##'"
                            type="tel"
                            :label="$t('номер_телефона')"
                            placeholder="+7 (###) ###-##-##"
                            prepend-inner-icon="mdi-phone"
                            autocomplete="off"
                            @input="mobileNumberError = null"
                            :error-messages="mobileNumberError"
                            clearable
                            :autofocus="!$isCordova()")
          v-divider
          v-card-actions
            v-spacer
            v-btn(text @click="shareWhatsAppDialog = false") {{ $t("отменить") }}
            v-btn(text @click="shareWhatsApp()" color="primary") {{ $t("отправить") }}

      v-dialog(v-model="shareEmailDialog" persistent max-width="400px")
        template(v-slot:activator="{ on }")
          v-btn(:min-width="minButtonWidth" :disabled="cancelLoading || ticketLoading || ticket.cancelledBy !== null" icon v-on="on")
            v-icon mdi-email
        v-card
          v-card-title
            span.headline {{ $t("отправить_чек_на_почту") }}
          v-card-text
            v-container
              v-form(ref="shareEmailForm" @submit.prevent="shareEmail()")
                p {{ $t("мы_отправим_этот_чек_на_указанный_вами_адрес_электронной_почты") }}
                v-text-field(v-model="email" type="email" :rules="[rules.email]" :label="$t('электронная_почта')" placeholder="client@rekassa.kz" prepend-inner-icon="mdi-at" autocomplete="off" clearable :validate-on-blur='shareEmailValidateOnBlur' :autofocus="!$isCordova()")
          v-divider
          v-card-actions
            v-spacer
            v-btn(text @click="shareEmailDialog = false") {{ $t("отменить") }}
            v-btn(text @click="shareEmail()" color="primary") {{ $t("отправить") }}

      v-btn(:min-width="minButtonWidth" :disabled="cancelLoading || ticketLoading || ticket.cancelledBy !== null" icon @click="print()" :loading="printingLoading")
        v-icon mdi-printer

</template>
<script>
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import download from 'downloadjs'
import { getLocale } from '../../i18n/index'
import store from '../../store/index'
import Receipt from './parts/Receipt.vue'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import ticketMixin from '../../mixins/ticketMixin'

export default {
  components: {
    're-receipt': Receipt,
  },

  mixins: [dictionaryMixin, ticketMixin],

  data: () => ({
    minButtonWidth: 80,

    mobileNumber: null,
    email: null,

    shareWhatsAppDialog: false,
    shareEmailDialog: false,

    mobileNumberError: null,
    shareEmailValidateOnBlur: true,

    shareLoading: false,
    downloadLoading: false,
    printingLoading: false,
    cancelLoading: false,
    ticketLoading: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      ticket: state => state.cashRegisters.ticket,
      printerSettings: state => state.printer.settings,
      keyListenerEnabled: state => state.tools.keyListenerEnabled,
      ticketLocale: state => state.tools.ticketLocale,
    }),

    ticketData() {
      if (this.ticket) {
        return this.ticket.data.ticket ? this.ticket.data.ticket : this.ticket.data.moneyPlacement
      }
      return null
    },

    rollWidthClass() {
      return this.printerSettings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },
  },

  // Clear ticket after leave
  beforeRouteLeave(to, from, next) {
    if (this.cancelLoading) {
      this.showSnackbar({ message: this.$t('отмена_чека_в_процессе') })
      next(false)
    } else {
      this.setTicket(null)
      this.setTicketRequest(null)
      next()
    }
  },

  watch: {
    shareWhatsAppDialog(val) {
      if (val) {
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },

    shareEmailDialog(val) {
      if (val) {
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },
  },

  created() {
    this.fetchTicketIfNull()
    this.setTicketLocale(getLocale())
  },

  mounted() {
    window.addEventListener('keydown', this.keyListener, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  // Calculate size of bottom buttons
  beforeMount() {
    const width = document.documentElement.clientWidth / 5
    this.minButtonWidth = width > 79 ? 80 : width
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setKeyListenerStatus: 'tools/setKeyListenerStatus',
      setTicket: 'cashRegisters/setTicket',
      setTicketRequest: 'cashRegisters/setTicketRequest',
      cancelTicket: 'cashRegisters/cancelTicket',
      fetchTicket: 'cashRegisters/fetchTicket',
      shareTicket: 'cashRegisters/shareTicket',
      printReceipt: 'printer/printReceipt',
      setTicketLocale: 'tools/setTicketLocale',
    }),

    keyListener(event) {
      if (this.keyListenerEnabled) {
        if (event.key === 'Backspace') {
          this.$goBack()
        } else if (event.key === 'Enter') {
          this.print()
        }
      }
    },

    fetchTicketIfNull() {
      if (store.state.cashRegisters.ticket === null) {
        this.ticketLoading = true
        store.dispatch('cashRegisters/fetchTicket', { cashRegisterId: this.$route.params.cashRegisterId, ticketId: this.$route.params.ticketId }).then(result => {
          store.dispatch('cashRegisters/setTicket', result.data).then(() => {
            this.ticketLoading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.status === 404) {
            this.showSnackbar({ message: this.$t('чек_не_существует') })
            this.$router.push('/')
          }
        })
      }
    },

    changeTicketLocale() {
      this.setTicketLocale(this.ticketLocale === 'ru' ? 'kk' : 'ru')
    },

    cancel() {
      this.showConfirm({
        title: this.$t('отмена_чека'),
        text: this.$t('вы_действительно_хотите_отменить_чек'),
        resolveText: this.$t('да_отменить'),
        rejectText: this.$t('нет'),
        persistent: false,
      }).then(() => {
        this.cancelLoading = true
        this.cancelTicket({ cashRegister: this.cashRegister, ticket: this.ticket }).then(() => {
          this.fetchTicket({ cashRegisterId: this.cashRegister.id, ticketId: this.ticket.id }).then((result) => {
            this.setTicket(result.data)
            this.showSnackbar({ message: this.$t('чек_успешно_отменен') })
            this.cancelLoading = false
          }).catch(() => {
            this.cancelLoading = false
          })
        }).catch(() => {
          this.cancelLoading = false
        })
      }).catch(() => {})
    },

    downloadReceipt() {
      this.downloadLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: 50,
          scale: 2,
        }).then(canvas => {
          if (this.$isCordova()) {
            window.canvas2ImagePlugin.saveImageDataToLibrary(
              () => {
                this.showSnackbar({ message: this.$t('чек_успешно_сохранен_в_галерее') })
                this.share('DOWNLOAD', null, null).then(() => {}).catch(() => {})
                this.downloadLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.downloadLoading = false
              },
              canvas,
              `${this.$t('смена')} №${this.ticket.shiftNumber} ${this.$t('чек')} №${this.ticket.shiftDocumentNumber}`,
              80,
              `reKassa_${this.cashRegister.serialNumber}`,
            )
          } else {
            download(canvas.toDataURL('image/png'), `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.ticket.shiftNumber} ${this.$t('чек')} №${this.ticket.shiftDocumentNumber}`)
            this.share('DOWNLOAD', null, null).then(() => {}).catch(() => {})
            this.downloadLoading = false
          }
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('не_удалось_cкачать_чек')}: ${error}` })
          this.downloadLoading = false
        })
      }, 0)
    },

    shareSystem() {
      const shareTicketType = localStorage.getItem('rekassa.kz-ui-shareTicketType') || 'IMAGE'
      if (shareTicketType === 'IMAGE') {
        this.shareLoading = true
        setTimeout(() => {
          const receipt = document.getElementsByClassName('receipt')[0]
          html2canvas(receipt, {
            backgroundColor: '#ffffff',
            y: 50,
            scale: 2,
          }).then(canvas => {
            window.canvas2ImagePlugin.shareImageData(
              () => {
                this.share('SYSTEM', null, null).then(() => {}).catch(() => {})
                this.shareLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.shareLoading = false
              },
              canvas,
              80,
            )
          }, 0)
        })
      } else {
        navigator.share({
          title: this.$t('поделиться_чеком'),
          text: this.getMessageText(false),
        }).then(() => {
          this.share('SYSTEM', null, null).then(() => {}).catch(() => {})
        })
      }
    },

    shareWhatsApp() {
      if (this.$refs.mobileNumber.value.length === 18) {
        if (this.$isCordova()) {
          window.cordova.InAppBrowser.open(`https://api.whatsapp.com/send?phone=${this.mobileNumber.replace(/[^\d]/g, '')}&text=${this.getMessageText(true)}`, '_system', '')
        } else {
          window.open(`https://api.whatsapp.com/send?phone=${this.mobileNumber.replace(/[^\d]/g, '')}&text=${this.getMessageText(true)}`, '_black')
        }
        this.share('WHATSAPP', this.mobileNumber.replace(/[^\d]/g, ''), null).then(() => {}).catch(() => {})
        this.shareWhatsAppDialog = false
      } else {
        this.mobileNumberError = this.$t('введите_номер_телефона')
      }
    },

    shareEmail() {
      if (this.$refs.shareEmailForm.validate()) {
        this.share('EMAIL', null, this.email).then(() => {
          this.showSnackbar({ message: this.$t('чек_успешно_отправлен_на', { email: this.email }) })
        }).catch(() => {})
        this.shareEmailDialog = false
      } else {
        this.shareEmailValidateOnBlur = false
      }
    },

    getMessageText(encode) {
      return `${this.$t('отправил_вам_электронный_чек_на_сумму_от', this.ticketLocale, {
        posTitle: this.preferences.businessNameOnReceipt === 'ORGANIZATION' ? this.organization.businessName : this.cashRegister.pos.title, number: (this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber), sum: this.$options.filters.numeral(this.ticketData.amounts.total.value, '0,0.[00]'), date: this.$moment(this.ticketData.dateTime.value).format('DD/MM/YYYY'),
      })
      } ${this.$t('ссылка_на_чек', this.ticketLocale)}: ${encode ? encodeURIComponent(this.ticket.qrCode) : this.ticket.qrCode}`
    },

    share(type, phone, email) {
      const data = {
        type,
      }
      if (phone) {
        data.receiver = { phone }
      } else if (email) {
        data.receiver = { email }
      }
      return this.shareTicket({ cashRegister: this.cashRegister, ticket: this.ticket, data })
    },

    print() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
      this.share('PRINTER', null, null).then(() => {}).catch(() => {})
    },

    printCordova() {
      this.printingLoading = true

      const printCmds = []
      printCmds.push('#align_center#')
      printCmds.push(this.preferences.businessNameOnReceipt === 'ORGANIZATION' ? this.organization.businessName : this.cashRegister.pos.title)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин', this.ticketLocale)} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')
      printCmds.push('#new_line#')

      if (this.ticket.qrCode) {
        printCmds.push('#qr_code#')
      }

      printCmds.push(this.$t(this.cashRegister.status === 'TRIAL' ? 'нефискальный_чек' : 'фискальный_чек', this.ticketLocale).toUpperCase())
      printCmds.push('#new_line#')
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      if (this.ticket.offline) {
        printCmds.push(this.$t('автономный', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
      }

      printCmds.push(`${this.$t(`operationType.${this.ticketData.operation}`, this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.ticket.shiftDocumentNumber}`)
      printCmds.push(`${this.$t('смена', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.ticket.shiftNumber)

      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('дата', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.ticketData.dateTime.value).format('DD-MM-YYYY'))
      printCmds.push(`${this.$t('время', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.ticketData.dateTime.value).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('фп', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber)
      printCmds.push(`${this.$t('кассир', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.ticket.operator ? this.ticket.operator.code : '')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('рнм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('ккм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.model)
      printCmds.push('#new_line#')

      printCmds.push('#line#')

      if (this.ticket.cancelledBy) {
        printCmds.push('#align_center#')
        printCmds.push(this.$t('чек_отменен', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
        printCmds.push('#line#')
        printCmds.push('#align_left#')
      } else if (this.ticket.cancelledBy === null && this.ticket.extra.receipts && this.ticket.extra.receipts.length > 0) {
        printCmds.push('#align_center#')
        printCmds.push(this.$t('дубликат', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
        printCmds.push('#line#')
        printCmds.push('#align_left#')
      }

      if (this.commodityItems) {
        for (let index = 0; index < this.commodityItems.length; index += 1) {
          if (index !== 0) {
            printCmds.push('#new_line#')
          }

          const item = this.commodityItems[index]
          printCmds.push(`${index + 1}. ${item.commodity.name}`)
          printCmds.push('#new_line#')

          if (item.commodity.exciseStamp) {
            printCmds.push(item.commodity.exciseStamp)
            printCmds.push('#new_line#')
          }

          printCmds.push(`${this.$options.filters.numeral(item.commodity.realQuantity, '0,0.0[00]')}${item.commodity.unitType ? this.$t(`unitType.${item.commodity.unitType}`, this.ticketLocale) : ''} x ${this.$options.filters.numeral(item.commodity.price.value, '0,0.00')}₸`)
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.commodity.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (item.commodity.discountType) {
            printCmds.push(`${this.$t('скидка', this.ticketLocale)} ${item.commodity.discountType === 'PERCENTAGE' ? `(${item.commodity.discountPercentage}%)` : ''}`)
            printCmds.push('#full_width#')
            printCmds.push(`-${this.$options.filters.numeral(item.commodity.discountValue, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }

          if (item.commodity.markupType) {
            printCmds.push(`${this.$t('наценка', this.ticketLocale)} ${item.commodity.markupType === 'PERCENTAGE' ? `(${item.commodity.markupPercentage}%)` : ''}`)
            printCmds.push('#full_width#')
            printCmds.push(`+${this.$options.filters.numeral(item.commodity.markupValue, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }

          printCmds.push(this.$t('стоимость', this.ticketLocale))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.commodity.total, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (item.commodity.taxes && item.commodity.taxes[0]) {
            printCmds.push(`${this.$t('в_т_ч_ндс', this.ticketLocale)} ${item.commodity.taxes[0].percent / 1000}%`)
            printCmds.push('#full_width#')
            printCmds.push(`${this.$options.filters.numeral(item.commodity.taxes[0].sum.value, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }
        }
      }

      if (this.commodityItems && this.commodityItems.length > 0) {
        printCmds.push('#line#')
      }

      printCmds.push(this.$t('итого', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.total.value, '0,0.00')}₸`)
      printCmds.push('#new_line#')

      if (this.ticketData.amounts.discount) {
        printCmds.push(`${this.$t('скидка', this.ticketLocale)} ${this.ticketData.amounts.discount.type === 'PERCENTAGE' ? `(${this.ticketData.amounts.discount.percentage}%)` : ''}`)
        printCmds.push('#full_width#')
        printCmds.push(`-${this.$options.filters.numeral(this.ticketData.amounts.discount.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.ticketData.amounts.markup) {
        printCmds.push(`${this.$t('наценка', this.ticketLocale)} ${this.ticketData.amounts.markup.type === 'PERCENTAGE' ? `(${this.ticketData.amounts.markup.percentage}%)` : ''}`)
        printCmds.push('#full_width#')
        printCmds.push(`+${this.$options.filters.numeral(this.ticketData.amounts.markup.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.otherPayments) {
        for (let index = 0; index < this.otherPayments.length; index += 1) {
          const payment = this.otherPayments[index]
          printCmds.push(this.$t(`paymentType.${payment.type}`, this.ticketLocale))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(payment.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (payment.type === 'PAYMENT_CARD' && this.ticket.extra && this.ticket.extra.attributes && this.ticket.extra.attributes.paymentType) {
            printCmds.push(`${this.$t('оплачено_через_name', this.ticketLocale, { name: this.$t(this.ticket.extra.attributes.paymentType, this.ticketLocale) })}`)
            printCmds.push('#new_line#')
          }

          if (payment.type === 'PAYMENT_CARD' && this.ticket.extra && this.ticket.extra.attributes && (this.ticket.extra.attributes.paymentType === 'ALFA_PAY' || this.ticket.extra.attributes.paymentType === 'HALYK_POS')) {
            printCmds.push(this.$t('номер_операции', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(`${this.ticket.extra.attributes.m4bankOperationDay}/${this.ticket.extra.attributes.m4bankTransactionNumber}`)
            printCmds.push('#new_line#')

            printCmds.push(this.$t('дата_оплаты', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.$moment(this.ticket.extra.attributes.m4bankDateTime).format('DD-MM-YYYY HH:mm:ss'))
            printCmds.push('#new_line#')

            printCmds.push(this.$t('реквизиты_карты', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankMaskedPan)
            printCmds.push('#new_line#')

            printCmds.push(this.$t('организация', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(` ${this.ticket.extra.attributes.m4bankMerchantName}`)
            printCmds.push('#new_line#')

            printCmds.push(this.$t('терминал', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankTransactionNumber)
            printCmds.push('#new_line#')

            printCmds.push(this.$t('код_ответа', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push('0')
            printCmds.push('#new_line#')

            printCmds.push(this.$t('код_авторизации', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankAuthorizationCode)
            printCmds.push('#new_line#')

            printCmds.push(this.$t('rrn', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankRRN)
            printCmds.push('#new_line#')
          }
        }
      }

      if (this.ticketData.amounts.taken && this.ticketData.amounts.taken.value > 0) {
        printCmds.push(this.$t('наличные', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.taken.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.ticketData.amounts.change && this.ticketData.amounts.change.value > 0) {
        printCmds.push(this.$t('сдача', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`-${this.$options.filters.numeral(this.ticketData.amounts.change.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.taxes) {
        for (let index = 0; index < this.taxes.length; index += 1) {
          const tax = this.taxes[index]
          printCmds.push(`${this.$t('в_т_ч_ндс', this.ticketLocale)} ${tax.percent}%`)
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(tax.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      if (this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber) {
        printCmds.push('#line#')
        printCmds.push('#align_center#')
        printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.title`, this.ticketLocale))
        printCmds.push('#new_line#')
        printCmds.push(`${this.$t('фиск_признак', this.ticketLocale)}: ${this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber}`)
        printCmds.push('#new_line#')
        printCmds.push(`${this.$t('код_ккм_кгд', this.ticketLocale)}: ${this.cashRegister.registrationNumber}`)
        printCmds.push('#new_line#')
        printCmds.push(`${this.$t('для_проверки_чека_зайдите_на_сайт', this.ticketLocale)}: `)
        printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.url`, this.ticketLocale))
        printCmds.push('#new_line#')
      }

      if (this.preferences.advertisingText) {
        printCmds.push('#line#')
        printCmds.push('#align_left#')
        printCmds.push(this.preferences.advertisingText)
        printCmds.push('#new_line#')
      }

      const payload = {
        cmds: printCmds,
        qrCode: this.ticket.qrCode,
      }

      this.printReceipt(payload).then(() => {
        this.share('PRINTER', null, null).then(() => {}).catch(() => {})
        setTimeout(() => { this.printingLoading = false }, 500)
      }, (error) => {
        if (error && error.message === 'printerNotSelected') {
          this.showConfirm({
            title: this.$t('необходимо_выбрать_принтер'),
            text: this.$t('прежде_чем_напечатать_чек_необходимо_выбрать_принтер_dotdotdot'),
            resolveText: this.$t('перейти'),
            rejectText: this.$t('отменить'),
          }).then(() => {
            this.$router.push('/printer')
          }).catch(() => {})
        }
        this.printingLoading = false
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
