<template lang="pug">
v-bottom-sheet(:value="value" @input="$emit('input', $event)"
  Zinset scrollable content-class="cartBottomDialog"
  :fullscreen="maximize" Zoverlay-color="primary" eager)
  slot
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    maximize: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data: () => ({
  }),

  methods: {

  },
}
</script>
<style lang="stylus">

// .theme--dark
//   .cartBottomDialog.v-dialog > .theme--dark.v-card.v-sheet
//       background: transparent

// .theme--dark .v-dialog > .theme--dark.v-card.v-sheet
//   background-color #33333d

// .v-dialog:not(.v-dialog--fullscreen)
//   max-height 93%

</style>
