<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/kkm/payments')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("KASPI_PAY") }}
      v-spacer
      template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && !kaspiPayConnected")
        v-btn(:disabled="loading" @click="fetchTradepoints()" text) {{ $t("подключить") }}
      v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)

    v-main(flat)
      v-container(style="max-width: 450px")
        v-row.my-4
          v-col(align="center")
            v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="60%" eager)

        v-row
          v-col
            v-list.v-list--fulltext.gray-background-list
              v-list-item
                v-list-item-icon(style="align-self: center; margin-left: 5px; margin-right: 20px")
                  img(src="/static/kaspi-pay-qr.png" height="30" width="30")
                v-list-item-content
                  v-list-item-title {{ $t("вместе_с_kaspi_pay_rekassa_30_позволяет_dotdotdot") }}.
                    a(@click.prevent="$openLink('https://link.rekassa.kz/kaspi-pay-help', '_system')") &nbsp; {{ $t("подробнее") }}
              v-list-item
                v-list-item-content.pt-1
                  v-img.cursor-pointer(src="/static/kaspi-promo.png" @click="$openLink('https://link.rekassa.kz/kaspi-pay-promo', '_system')")
              v-list-item(v-if="cashRegister.status === 'REGISTERED' && !kaspiPayConnected")
                v-list-item-content.pt-1
                  v-list-item-title {{ $t("для_того_чтобы_начать_принимать_платежи_kaspi_qr_dotdotdot", { name: `${organization.businessName} (${organization.businessId})` }) }}.
                    a(@click.prevent="$openLink('https://link.rekassa.kz/kaspi-pay-connection', '_system')") &nbsp; {{ $t("подробнее") }}
              v-list-item(v-if="cashRegister.status === 'TRIAL'")
                v-list-item-icon(style="align-self: center; margin-left: 5px; margin-right: 20px")
                  v-icon(size="30") mdi-information-outline
                v-list-item-content.pt-1
                  v-list-item-title {{ $t("зарегистрируйте_кассу_чтобы_иметь_возможность_принимать_платежи_kaspi_qr") }}.
                    a(@click.prevent="$openLink('https://link.rekassa.kz/ttk-registration-and-activation', '_system')") &nbsp; {{ $t("как_зарегистрировать") }}

        v-row(v-if="cashRegister.status === 'REGISTERED'")
          v-col
            v-list.gray-background-list
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("статус") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ kaspiPayConnected ? $t("подключено") : $t("не_подключено") }}

              div.px-4(v-if="user._links.createCashRegister")
                v-btn.my-3(v-if="!kaspiPayConnected" color="primary" block :disabled="loading" @click="fetchTradepoints()") {{ $t("подключить") }}
                v-btn.my-3(v-else color="error" block :disabled="loading" @click="showDisconnectPinpad()") {{ $t("отключить") }}

    re-pinpad(v-model="connectDialog" :title="$t('подключение_name', { name: 'Kaspi Pay'})" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_name', { name: 'Kaspi Pay'})" :loading="loading" :errorText.sync="connectErrorMessage" @action="connect")
    re-pinpad(v-model="disconnectDialog" :title="$t('отключение_name', { name: 'Kaspi Pay'})" :subtitle="$t('введите_пин-код')" :text="$t('для_отключения_name', { name: 'Kaspi Pay'})" :loading="loading" :errorText.sync="disconnectErrorMessage" @action="disconnect")

    v-dialog(v-model="tradePointDialog" persistent max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_торговую_точку") }}
        v-card-text
          v-container
            v-form.mt-3(ref="tradePointForm" @submit.prevent="selectTradePoint()")
              v-select(:label="$t('торговая_точка')" v-model="tradePointId" :items="tradePointList" item-value="id" item-text="name" :rules="[rules.required]")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="tradePointDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="selectTradePoint()") {{ $t("выбрать") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    imageUrl: {
      dark: '/static/kaspi-pay-white.svg',
      light: '/static/kaspi-pay-black.svg',
    },
    loading: false,
    tradePointId: null,
    tradePointList: [],
    tradePointDialog: false,
    connectDialog: false,
    connectErrorMessage: null,
    disconnectDialog: false,
    disconnectErrorMessage: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    kaspiPayConnected() {
      return this.configuration.kaspi && this.configuration.kaspi.kaspiPayConnected
    },
  },

  methods: {
    ...mapActions({
      tradepoints: 'kaspiPay/tradepoints',
      registerTerminal: 'kaspiPay/registerTerminal',
      deregisterTerminal: 'kaspiPay/deregisterTerminal',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
    }),

    checkShift() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр') })
        return false
      }
      return true
    },

    showConnectPinpad() {
      if (this.checkShift()) {
        this.connectDialog = true
      }
    },

    showDisconnectPinpad() {
      if (this.checkShift()) {
        this.disconnectDialog = true
      }
    },

    fetchTradepoints() {
      this.loading = true
      this.tradePointId = null
      this.tradepoints({ cashRegisterId: this.cashRegister.id }).then((result) => {
        if (result.data && result.data.length > 1) {
          this.loading = false
          this.tradePointList = result.data
          this.tradePointDialog = true
        } else if (result.data && result.data.length === 1) {
          this.loading = false
          this.tradePointId = result.data[0].id
          this.showConnectPinpad()
        } else {
          this.loading = false
          this.showSnackbar({ message: this.$t('перед_подключением_необходимо_убедиться_что_в_приложении_kaspi_pay_уже_присутствует_торговая_точка') })
        }
      }).catch((error) => {
        if (error && error.response && error.response.data && this.$t(`kaspi/${error.response.data.code}`) !== `kaspi/${error.response.data.code}`) {
          this.loading = false
          this.showSnackbar({ message: this.$t(`kaspi/${error.response.data.code}`) })
        } if (error && error.response && error.response.data && error.response.data.message) {
          this.loading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error.response.data.message}` })
        } else {
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    selectTradePoint() {
      if (this.$refs.tradePointForm.validate()) {
        this.tradePointDialog = false
        this.showConnectPinpad()
      }
    },

    connect(pincode) {
      this.loading = true
      this.registerTerminal({
        data: {
          cashRegisterId: this.cashRegister.id,
          tradePointId: this.tradePointId,
        },
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_подключен_name', { name: 'Kaspi Pay' }) })
          this.firebaseAnalyticsLogEvent({ eventName: 're_kaspi_pay_connected' })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.connectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && this.$t(`kaspi/${error.response.data.code}`) !== `kaspi/${error.response.data.code}`) {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t(`kaspi/${error.response.data.code}`) })
        } else {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    disconnect(pincode) {
      this.loading = true
      this.deregisterTerminal({
        data: {
          cashRegisterId: this.cashRegister.id,
        },
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_отключен_name', { name: 'Kaspi Pay' }) })
          this.firebaseAnalyticsLogEvent({ eventName: 're_kaspi_pay_disconnected' })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.disconnectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && this.$t(`kaspi/${error.response.data.code}`) !== `kaspi/${error.response.data.code}`) {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t(`kaspi/${error.response.data.code}`) })
        } else {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
