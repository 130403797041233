import Vue from 'vue'
import numeral from 'numeral'
import moment from 'moment'

// import tjson from 'E:\\Dev\\GitHub\\tjson2html'

// moment.locale('ru')

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '₸',
  },
})

Vue.filter('shortFio', (value) => {
  if (value && value.length) {
    const matches = value.split(' ').map((a, i) => {
      if (i === 0) return a
      if (i === 1) return `${a.substring(0, 1)}.`
      return null
    })
    return (matches.length === 1 ? value.substring(0, 2) : matches.join(' '))
  }
  return null
})

Vue.filter('codify', (value) => {
  if (value) {
    const matches = value.split(' ').map((a) => a.substring(0, 1))
    return (matches.length === 1 ? value.substring(0, 2) : matches.join('')).toUpperCase()
  } return null
})

Vue.filter('firstLine', (value) => {
  if (value) {
    const matches = value.split(' ').map((a) => a.substring(0, 1))
    return (matches.length === 1 ? value.substring(0, 2) : matches.join('')).toUpperCase()
  } return null
})

Vue.filter('mdate', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY')
  } return null
})
Vue.filter('date', (value) => {
  if (!value) return ''
  return value.toLocaleString('ru', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).initCap()
})

Vue.filter('ndate', (value) => {
  if (!value) return ''
  return value.toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).initCap()
})

Vue.filter('strndate', (value) => {
  if (!value) return ''
  return new Date(value).toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).initCap()
})

Vue.filter('time', (value) => {
  if (!value) return ''
  return value.toLocaleString('ru', {
    hour: 'numeric',
    minute: 'numeric',
  })
})
Vue.filter('strtime', (value) => {
  if (!value) return ''
  return new Date(value).toLocaleString('ru', {
    hour: 'numeric',
    minute: 'numeric',
  })
})
Vue.filter('datetime', (value) => {
  if (!value) return ''
  return value.toLocaleString('ru', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
})
Vue.filter('mdatetime', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY hh:mm')
  } return null
})

Vue.filter('name', (value) => {
  if (value) {
    return value.split(' ')[0]
  }
  return null
})
Vue.filter('tel', (value) => {
  if (value) {
    return value
  }
  return null
})

Vue.filter('amount', (value) => Number(value) / 100)
Vue.filter('number', (value) => Number(Number.isNaN(value) ? 0 : value))
Vue.filter('money', (value) => numeral(value).format('0,0'))
Vue.filter('moneyz', (value) => numeral(value).format('0,0.00 ₸'))
Vue.filter('numeral', (value, fmt) => numeral(value).format(fmt))

const PAD_WIDTH_CHARS = 37

Vue.filter('rpad', (value, r, width = PAD_WIDTH_CHARS) => (String(value).padEnd(width - String(r).length) + r).substring(0, width))
Vue.filter('cols', (value, r, col1 = 20, col2 = 17) => String(r).padStart(col1).substring(0, col1) + String(value).padStart(col2).substring(0, col2))
Vue.filter('lpad', (value, r, width = PAD_WIDTH_CHARS) => (String(r).padEnd(width - String(value).length) + value).substring(0, width))

Vue.filter('pad', (value, width = PAD_WIDTH_CHARS) => String(value).padEnd(width).substring(0, width))

Vue.filter('padcenter', (value, width = PAD_WIDTH_CHARS) => {
  const strLen = String(value).length
  let len = width - strLen
  if (len > 0) len = Math.floor(len / 2) + strLen

  return String(value).padStart(len).padEnd(width).substring(0, width)
})

// Vue.filter('json2html', function (value, fmt) {
//   return tjson(value, fmt)
// })

Vue.filter('decode', (key, ...args) => {
  // const args = Array.prototype.slice.call(arguments)
  let iv
  let inv
  for (let i = 1, ii = args.length; i < ii; i += 2) {
    iv = args[i]
    inv = (i + 1 in args) && args[i + 1]
    if (key === iv && inv !== undefined) {
      iv = inv
      break
    } else if ((i + 1) !== ii) {
      iv = key
    }
  }
  return typeof iv === 'function' ? iv(key) : iv
})
