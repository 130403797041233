import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment-timezone'
import store from '../store/index'
import i18n from '../i18n/index'

const $http = axios.create()

$http.interceptors.request.use(async (cfg) => {
  if (!navigator.onLine) {
    store.dispatch('tools/setConnectionWarning', true)
    throw i18n.t('сервер_недоступен')
  }

  if (process.env.VUE_APP_API_ROOT_URL) {
    cfg.url = process.env.VUE_APP_API_ROOT_URL + cfg.url
  }

  // Cordova
  if (window.cordova !== undefined) {
    const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
    if (currentUser) {
      const token = await currentUser.idToken
      cfg.headers.common.Authorization = `Bearer ${token}`
    }
  // Web
  } else {
    const currentUser = await firebase.auth().currentUser
    if (currentUser) {
      const token = await currentUser.getIdToken()
      cfg.headers.common.Authorization = `Bearer ${token}`
    }
  }

  const timezone = moment.tz(moment.tz.guess()).format('Z')
  cfg.headers.common.timezone = timezone

  return cfg
}, (error) => {
  Promise.reject(error)
})

$http.interceptors.response.use((cfg) => cfg, (error) => {
  if (error.response && error.response.status && String(error.response.status).startsWith('5')) {
    store.dispatch('tools/setConnectionWarning', true)
  } else if (error.response && error.response.status === 401) {
    store.dispatch('tools/showSnackbar', { message: i18n.t('к_сожалению_ваш_сеанс_истек') })
    store.dispatch('auth/signOut')
  } else if (error.response) {
    const i18nErrors = {
      WRONG_PASSWORD: i18n.t('backend/WRONG_PASSWORD'),
      DATA_INTEGRITY_ERROR: i18n.t('backend/DATA_INTEGRITY_ERROR'),
      INVALID_TOKEN: i18n.t('backend/INVALID_TOKEN'),
      UNKNOWN_ID: i18n.t('backend/UNKNOWN_ID'),
      CANT_CANCEL_TICKET: i18n.t('backend/CANT_CANCEL_TICKET'),
      NOT_ENOUGH_CASH: i18n.t('backend/NOT_ENOUGH_CASH', { cashSum: error.response.data.meta && error.response.data.meta.CURRENT_CASH_SUM }),
      CASH_REGISTER_SHIFT_PERIOD_EXPIRED: i18n.t('backend/CASH_REGISTER_SHIFT_PERIOD_EXPIRED'),
      CASH_REGISTER_SHOULD_HAVE_SHIFT_CLOSED: i18n.t('backend/CASH_REGISTER_SHIFT_PERIOD_EXPIRED'),
      CASH_REGISTER_OFFLINE_PERIOD_EXPIRED: i18n.t('backend/CASH_REGISTER_OFFLINE_PERIOD_EXPIRED', { hours: error.response.data.meta && Math.floor(error.response.data.meta.ALLOWED_OFFLINE_MINUTES / 60) }),
      CASH_REGISTER_BLOCKED: i18n.t('backend/CASH_REGISTER_BLOCKED'),
      BLOCKED: i18n.t('backend/CASH_REGISTER_BLOCKED'),
      CASH_REGISTER_WRONG_OFD_MODE: i18n.t('backend/CASH_REGISTER_WRONG_OFD_MODE'),
      CASH_REGISTER_ALREADY_ACTIVATED: i18n.t('backend/CASH_REGISTER_ALREADY_ACTIVATED'),
      CASH_REGISTER_ALREADY_REGISTERED: i18n.t('backend/CASH_REGISTER_ALREADY_REGISTERED'),
      CASH_REGISTER_TRIAL_PERIOD_EXPIRED: i18n.t('backend/CASH_REGISTER_TRIAL_PERIOD_EXPIRED'),
      CASH_REGISTER_TRIAL_OPERATIONS_LIMIT_REACHED: i18n.t('backend/CASH_REGISTER_TRIAL_OPERATIONS_LIMIT_REACHED'),
      CASH_REGISTER_CANNOT_RESTORE_ROLE: i18n.t('backend/CASH_REGISTER_CANNOT_RESTORE_ROLE'),
      CASH_REGISTER_ATTEMPT_LIMIT_EXCEEDED: i18n.t('backend/CASH_REGISTER_ATTEMPT_LIMIT_EXCEEDED'),
      SERVICE_TEMPORARILY_UNAVAILABLE: i18n.t('backend/SERVICE_TEMPORARILY_UNAVAILABLE'),
      PROTOCOL_ERROR: i18n.t('backend/PROTOCOL_ERROR'),
    }
    const message = error.response.data && error.response.data.code && i18nErrors[error.response.data.code] ? i18nErrors[error.response.data.code] : error.response.data.message
    if (message) {
      store.dispatch('tools/showSnackbar', { message: `${message}`, url: error.response.data.url })
    }
  } else if (error.request) {
    if (error && error.message === 'Network Error') {
      store.dispatch('tools/setConnectionWarning', true)
    } else {
      store.dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` })
    }
  } else {
    store.dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error}` })
  }
  return Promise.reject(error)
})

export default $http
