<template lang="pug">
  v-app
    v-app-bar(app dark dense flat)
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("активация_кассы") }}
      v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)

    v-main(flat v-if="cashRegister")
      v-container(style="max-width: 450px")
        v-row
          v-col(align="center")
            v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="70%" eager)

        v-row
          v-col
            v-list.v-list--fulltext.gray-background-list
              v-subheader {{ $t("паспорт") }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("знм") }}
                  v-list-item-subtitle {{ $t("заводской_номер") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1
                    v-btn.mr-1(icon @click="copy(cashRegister.serialNumber)")
                      v-icon mdi-content-copy
                    | {{ cashRegister.serialNumber }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("модель") }}
                  v-list-item-subtitle {{ $t("контрольно-кассовой_машины") }}
                v-list-item-action(v-if="cashRegister")
                  v-list-item-action-text.title {{ cashRegister.model }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("год_выпуска") }}
                v-list-item-action(v-if="cashRegister")
                  v-list-item-action-text.title {{ cashRegister.year }}

              v-list-item(@click="openFdoTypeDialog()")
                v-list-item-content
                  v-list-item-title {{ $t("офд") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ $t(`fdo.${fdo}.title`) }}

              div.mt-2.pa-2
                v-alert(color="yellow darken-2" dark icon="mdi-alert" dense text outlined)
                  .subtitle {{ $t("для_активации_кассы_dotdotdot") }}. &nbsp;
                    a.blinker(@click.prevent="$openLink('https://link.rekassa.kz/ttk-registration-and-activation', '_system')") {{ $t("подробнее") }}

              v-subheader.my-8.red--text(v-if="serialNumberError" v-html="serialNumberError")
              v-form.px-4(ref="activationForm" @submit.prevent="activate()")
                v-text-field(:label="$t('инм_id')" v-model="id" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="idError = null" :error-messages="idError" validate-on-blur maxlength="9")
                v-text-field(:label="$t('токен')" v-model="token" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="tokenError = null" :error-messages="tokenError" validate-on-blur maxlength="9")
                v-text-field(:label="$t('пин-код')" v-model="pincode" type="tel" :rules="[pincodeRules.length, rules.numbersOnly]" autocomplete="off" maxlength="4" validate-on-blur)
                v-text-field(:label="$t('повторите_пин-код')" v-model="pincodeRepeat" type="tel" :rules="[pincodeRules.length, rules.numbersOnly, pincodeRules.same(pincodeRepeat, pincode)]" autocomplete="off" maxlength="4" validate-on-blur)
                v-btn.my-3(color="primary" block :disabled="loading" @click="activate()") {{ $t("активировать") }}

        v-dialog(v-model="fdoTypeDialog" max-width="400px")
          v-card
            v-card-title.pb-0
              span.headline {{ $t("выберите_офд") }}
            v-card-text.py-0
              v-container
                v-form.mt-3(ref="fdoTypeForm" @submit.prevent="saveFdoType()")
                  v-radio-group(v-model="fdoType")
                    template(v-for="(item, $index) in fdoProviders")
                      div.fdo-radio.mt-5(@click="selectFdo(item)")
                        v-radio.fdo-radio(:value="item.id" :disabled="!item.enabled")
                        .d-inline(:class="{'fdo-disabled': !item.enabled}")
                          .title.d-inline {{ getLocale() === 'ru' ? item.name.ru : item.name.kk }}
                          br
                          .subtitle-2.d-inline {{ getLocale() === 'ru' ? item.terms.ru : item.terms.kk }}
                          br
                          a(@click="$openLink(`${getLocale() === 'ru' ? item.links.ru : item.links.kk}`, '_system')" disabled).subtitle-2.d-inline Подробнее
            v-divider
            v-card-actions
              v-spacer
              v-btn(text @click="fdoTypeDialog = false") {{ $t("отменить") }}
              v-btn(text color="primary" @click="saveFdoType()") {{ $t("выбрать") }}

</template>
<script>
import { mapActions } from 'vuex'
import i18n, { getLocale } from '../../i18n/index'

export default {
  data: () => ({
    imageUrl: {
      dark: '/static/illustrations/rally/dark/undraw_posting_photo_v65l.svg',
      light: '/static/illustrations/rally/light/undraw_posting_photo_v65l.svg',
    },

    loading: false,

    cashRegister: null,

    fdo: 'TTK',
    id: null,
    token: null,
    pincode: null,
    pincodeRepeat: null,

    idError: null,
    tokenError: null,
    serialNumberError: null,

    fdoProviders: null,
    fdoType: null,
    fdoTypeDialog: false,

    pincodeRules: {
      length: (v) => (!!v && v.length === 4) || i18n.t('требуется_4-х_значный_пин-код'),
      same: (pin, v) => (!!v && v === pin) || i18n.t('пин-код_не_совпадает'),
    },
  }),

  created() {
    this.loading = true
    this.fetchCashRegister(this.$route.params.newCashRegisterId).then((data) => {
      this.cashRegister = data.data.cashRegister
      if (this.cashRegister.status !== 'NEW') this.$router.push('/')
      this.fetchFdoProviders().then((data2) => {
        this.loading = false
        this.fdoProviders = data2.data
      }).catch(() => {
        this.loading = false
      })
    }).catch(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions({
      fetchCashRegister: 'cashRegisters/fetchCashRegister',
      registerNewCashRegister: 'cashRegisters/registerNewCashRegister',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      fetchFdoProviders: 'cashRegisters/fetchFdoProviders',
      firebaseAnalyticsLogEvent: 'tools/firebaseAnalyticsLogEvent',
      showSnackbar: 'tools/showSnackbar',
      copyToClipboard: 'tools/copyToClipboard',
    }),

    getLocale() {
      return getLocale()
    },

    openFdoTypeDialog() {
      this.fdoType = this.fdo
      this.fdoTypeDialog = true
    },
    selectFdo(item) {
      if (item.enabled) {
        this.fdoType = item.id
      }
    },
    saveFdoType() {
      if (this.$refs.fdoTypeForm.validate()) {
        this.fdoTypeDialog = false
        this.fdo = this.fdoType
      }
    },

    activate() {
      this.serialNumberError = null
      if (this.$refs.activationForm.validate()) {
        this.loading = true
        const form = new FormData()
        form.append('fdo', this.fdo)
        form.append('id', this.id)
        form.append('token', this.token)
        form.append('password', this.pincode)
        this.registerNewCashRegister({
          cashRegister: this.cashRegister,
          data: form,
        }).then(() => {
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.setCashRegisterPinChecked(false)
            this.loading = false
            this.$router.push('/kkm')
            this.showSnackbar({ message: this.$t('касса_успешно_активирована') })
            this.firebaseAnalyticsLogEvent({ eventName: 're_cash_register_activated', eventProperties: { type: this.fdo } })
          }).catch(() => {
            this.loading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.data) {
            if (error.response.data.code === 'UNKNOWN_ID') {
              this.idError = this.$t('backend/UNKNOWN_ID')
            } else if (error.response.data.code === 'INVALID_TOKEN') {
              this.tokenError = this.$t('backend/INVALID_TOKEN')
            } else if (error.response.data.code === 'CASH_REGISTER_ALREADY_ACTIVATED') {
              this.idError = this.$t('backend/CASH_REGISTER_ALREADY_ACTIVATED')
            } else if (error.response.data.code === 'CASH_REGISTER_WRONG_SERIAL_NUMBER') {
              this.serialNumberError = this.$t('backend/CASH_REGISTER_WRONG_SERIAL_NUMBER', { serverSerialNumber: error.response.data.meta.SERVER_SERIAL_NUMBER, serialNumber: error.response.data.meta.SERIAL_NUMBER })
            }
          }
          this.loading = false
        })
      }
    },

    copy(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },
  },
}
</script>

<style lang="stylus">
.fdo-radio
  display inherit
  -webkit-box-align flex-start !important
  -ms-flex-align flex-start !important
  align-items flex-start !important
  .v-input--selection-controls__input
    margin-top 5px !important

.fdo-disabled
  div
    color: rgba(255, 255, 255, 0.5)
</style>
