export default {
  methods: {
    limitDecimal($event, model, limit) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || model.toString().indexOf('.') !== -1)) {
        $event.preventDefault()
      }
      if (model != null && model.toString().indexOf('.') > -1 && (model.toString().split('.')[1].length > limit - 1)) {
        $event.preventDefault()
      }
    },
  },
}
