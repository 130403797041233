<template lang="pug">
v-alert.ma-2(border="left" v-bind="$attrs" v-on="$listeners" type="success" color="primary" text rounded dense dismissible)
  span.white--text.body-2: slot
</template>

<script>
export default {
  name: 'TAlert',
  props: {
    // suffix: { type: String, default: '₸' },
    // hide: { type: Boolean, default: true },
    // number: { type: Number, default: 0 },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
}
</script>
