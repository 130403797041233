<template lang="pug">
  v-dialog(v-model="showDialog" scrollable persistent @keydown="handleDialogKeydown($event)" width="500")
    v-card
      v-card-text
        v-form(ref="form")
          v-row
            v-col.py-1(cols="12")
              v-text-field(:label="$t('paymentType.' + payment.type)" v-model.number="payment.sum" type="number" @keypress="limitDecimal($event, payment.sum, 2)" :rules="[rules.numberRange(0, 100000000, payment.sum)]" suffix="₸" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")

          v-row(v-if="payment.type === 'PAYMENT_CASH'")
            v-col.py-1(cols="12")
              v-btn.mr-4.mb-4(v-for="(kzt, $index) in kzts" :key="'k_' + $index" @click="fillWith(kzt)" color="primary") {{ kzt | numeral('0,0.[00]') }} ₸

          v-row(v-if="payment.type === 'PAYMENT_CARD' && takenCash > 0 && (total - takenCash) > 0")
            v-col.py-1(cols="12")
              v-btn.mr-4.mb-4(@click="fillWith(total - takenCash)" color="primary") {{ total - takenCash | numeral('0,0.[00]') }} ₸

      v-divider.item-divider
      v-card-actions
        v-spacer
        v-btn(text @click="showDialog = false") {{ $t('отменить') }}
        v-btn(color="primary" text @click="savePayment()") {{ $t('сохранить') }}

</template>
<script>
import decimalMixin from '../../../mixins/decimalMixin'
import dictionaryMixin from '../../../mixins/dictionaryMixin'

export default {
  mixins: [decimalMixin, dictionaryMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    total: {
      type: Number,
      default: null,
      required: true,
    },
    payment: {
      type: Object,
      default: null,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    takenCash: {
      default: null,
      required: false,
    },
  },

  data: () => ({
    showDialog: false,
    kzts: [
      500,
      1000,
      2000,
      5000,
      10000,
      20000,
    ],
  }),

  watch: {
    value(value) {
      if (value) {
        this.showDialog = true
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  },

  methods: {
    savePayment() {
      if (this.$refs.form.validate()) {
        this.$emit('savePayment', this.payment)
        this.showDialog = false
      }
    },

    fillWith(sum) {
      this.payment.sum = sum
      this.savePayment()
    },

    handleDialogKeydown(event) {
      if (event.keyCode === 13) {
        this.savePayment()
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
