<template lang="pug">
t-anim-num(v-if="isNotSupported" :number="number" :suffix="suffix" :hide="hide")
span.animatedNumber(v-else :style="{'--num':number, '--len': String(number).length}" :class="{'show':!hide}")
  template(v-if="!hide")  {{suffix}}
</template>

<script>
export default {
  name: 'TAnimNumcss',
  props: {
    number: { type: Number, default: 0 },
    hide: { type: Boolean, default: undefined },
    suffix: { type: String, default: '₸' },
  },
  data: () => ({
    isNotSupported: false,
  }),
  mounted() {
    if (!(window.CSS && window.CSS.registerProperty)) {
      // alert('Counter Usupported1')
      this.isNotSupported = true
    }

    // console.log(window.CSS.supports('@property'))

    // if (!window.CSS.supports('@property')) {
    //   alert('Counter Usupported2')
    // }
  },
}
</script>

<style lang="stylus">
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.animatedNumber.show
  text-align: right
  min-width: calc(var(--len)*20px)
  // text-align: left
  display inline-block
  transition: --num 0.5s ease-out;
  counter-reset: num var(--num);

  &::before
    content: counter(num)

</style>
