<template lang="pug">
v-card.t-btncard.pa-1.d-flex.flex-column(dark dense outlined tile :class="[textColor+'--text',flat?color:'glass']" :disabled="disabled" v-on:click="onClick" Zv-bind="$props")
  v-skeleton-loader(v-if="!$scopedSlots.default" tile min-height="100%" type="list-item-two-line" boilerplate)
  template(v-else)
    .col.pa-0
      .bg(v-if="!flat" :class="[outlined?'':selected?'primary':color,outlined?'outlined '+color+'--text':'']")
      slot(name="icon")
        v-card-title.col
          v-icon(v-if="icon" size="md" :color="iconColor?iconColor:(flat?textColor:color)") {{icon}}
    v-spacer
    v-card-actions.pa-1.pl-2.col-auto.font-weight-medium.subtitle-1
      slot
</template>

<script>

export default {
  name: 'TBtncard',
  props: {
    selected: {
      type: Boolean,
      default: undefined,
    },
    color: {
      type: String,
      default: 'grey-7',
    },
    flat: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: 'white',
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    onClick() {
      if (this.to) this.$router.replace(this.to)
      else this.$emit('click')
    },
  },
}
</script>
<style lang="stylus">

.t-btncard
  min-height 8rem
  position relative
  overflow hidden
  line-height 1.2

  &.glass .bg
    opacity 0.2
    position absolute
    height 100%
    width 100%
    display block
    top 0
    left 0

    &.outlined
      border 1px solid currentColor
      opacity 0.2

.q-card__section--vert
  padding 0

</style>
