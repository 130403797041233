import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import i18n from '../../i18n/index'

async function getIdToken() {
  let idToken
  // Cordova
  if (window.cordova !== undefined) {
    const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
    if (currentUser) {
      idToken = await currentUser.idToken
    }
    // Web
  } else {
    const currentUser = await firebase.auth().currentUser
    if (currentUser) {
      idToken = await currentUser.getIdToken()
    }
  }
  return idToken
}

const positions = {
  namespaced: true,

  state: {
    projectId: JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG).projectId,
    list: [],
    loading: false,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    // rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone костыль для работы касс через iframe
    async add({ rootState, state }, payload) {
      const idToken = await getIdToken()
      return axios.post(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}/positions.json?auth=${idToken}${payload.silent ? '&print=silent' : ''}`, payload.data)
    },

    async addAll({ rootState, state }, payload) {
      const idToken = await getIdToken()
      return axios.put(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}.json?auth=${idToken}`, payload.data)
    },

    async update({ rootState, state }, payload) {
      const idToken = await getIdToken()
      return axios.patch(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}/positions/${payload.id}.json?auth=${idToken}`, payload.data)
    },

    async delete({ rootState, state }, payload) {
      const idToken = await getIdToken()
      return axios.delete(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}/positions/${payload.id}.json?auth=${idToken}`)
    },

    async deleteAll({ rootState, state }) {
      const idToken = await getIdToken()
      return axios.delete(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}/positions.json?auth=${idToken}`)
    },

    async fetch({
      rootState, state, commit, dispatch,
    }) {
      commit('set', { type: 'loading', value: true })
      const idToken = await getIdToken()
      return new Promise((resolve, reject) => {
        axios.get(`https://${state.projectId}.firebaseio.com/users/${rootState.auth.user.uid ? rootState.auth.user.uid : rootState.auth.user.phone}/positions.json?auth=${idToken}`).then((result) => {
          if (result.data) {
            const list = Object.entries(result.data).map((item) => ({
              id: item[0], name: item[1].name, price: item[1].price, unitType: item[1].unitType, barcode: item[1].barcode,
            })).sort((a, b) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            })
            commit('set', { type: 'list', value: list })
          } else {
            commit('set', { type: 'list', value: [] })
          }
          commit('set', { type: 'loading', value: false })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
          commit('set', { type: 'loading', value: false })
          reject()
        })
      })
    },

    clear({ commit }) {
      commit('set', { type: 'list', value: [] })
    },
  },
}

export default positions
