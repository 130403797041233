<template lang="pug">
v-card.tile(tile outlined v-on="$listeners" Zv-bind="$attrs" :color="selected?'primary':undefined")
  v-skeleton-loader(v-if="!$scopedSlots.default" tile min-height="100%" type="list-item-two-line" boilerplate)
  slot(v-else)
//- v-card.r-btncard.column.q-pa-md(tile outlined v-on="$listeners"
//-   dark dense v-ripple flat
//-   :class="['text-'+textColor,flat?'bg-'+color:'glass']"
//-   :disabled="disabled"
//-   )
//-   .bg(v-if="!flat" :class="[outlined?'':'bg-'+color,outlined?'outlined '+'text-'+color:'']")
//-   v-card-section.col
//-     q-icon(v-if="icon" :name="icon" size="md" :color="iconColor?iconColor:(flat?textColor:color)")
//-   v-card-section.col-auto.text-weight-medium
//-     slot
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TTile',
  components: {},
  props: {
    selected: {
      type: Boolean,
      default: undefined,
    },
    // color: {
    //   type: String,
    //   default: undefined,
    // },
  },

  data: () => ({
  }),

  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
  },
  methods: {
  },
}
</script>
<style lang="stylus">
.tile
  min-height 120px
  height 100%
  border-width 4px !important

  .headline, .subtitle-1, .title
    // max-width:85px
    text-overflow:ellipsis
    overflow:hidden
    line-height 1em

</style>
